import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mat-select-search',
  templateUrl: './mat-select-search.component.html',
  styleUrls: ['./mat-select-search.component.scss'],
})
export class MatSelectSearchComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  /** list of banks */

  @Input() items: any[] = [];
  @Input() selectedItems: any;
  @Input() isMultiple = false;
  @Input() itemType;
  @Input() forcePlaceHolder = false;
  @Input() showToggleAllCheckBox = false;
  @Input() toggleAllCheckboxChecked = false;
  @Input() alternativeProp = 'name'; // default value
  @Output() openChange = new EventEmitter<any>();
  @Output() selectionChanged = new EventEmitter<any>();
  @Output() toggleAll = new EventEmitter<any>();
  /** control for the selected item for multi-selection */
  public itemMultiCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public itemMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of items filtered by search keyword */
  public filteredItemsMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit() {
    if (this.items) {
      // load the initial item list
      this.filteredItemsMulti.next([...this.items]);
    }
    this.itemMultiCtrl.setValue(this.selectedItems);
    // listen for search field value changes
    this.itemMultiFilterCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.filterBanksMulti();
    });

    this.itemMultiCtrl.valueChanges.subscribe(data => {
      this.selectionChanged.emit(data);
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(changes): void {
    if (changes.items && !changes.items.firstChange) {
      this.items = changes.items.currentValue;
      this.filteredItemsMulti.next(this.items.slice());
    }

    if (changes.selectedItems && !changes.selectedItems.firstChange) {
      this.itemMultiCtrl.setValue(changes.selectedItems.currentValue);
    }
  }

  /**
   * Sets the initial value after the filteredItems are loaded initially
   */
  protected setInitialValue() {
    this.filteredItemsMulti.pipe(take(1), takeUntil(this.onDestroy)).subscribe(() => {
      this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
    });
  }

  clearSelection() {
    this.itemMultiCtrl.setValue(null);
  }

  protected filterBanksMulti() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrl.value;
    if (!search) {
      this.filteredItemsMulti.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsMulti.next(this.items.filter(bank => bank.name.toLowerCase().indexOf(search) > -1 ||
      bank[this.alternativeProp].toLocaleLowerCase().indexOf(search) > -1));
  }
}
