<h1 mat-dialog-title>Data Genie</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="fields-column">
      <mat-form-field class="entity-options">
        <mat-label>Entity field</mat-label>
        <input type="text"
               matInput
               formControlName="field"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="fieldFn">
          <mat-optgroup *ngFor="let entity of filteredEntities | async" [label]="entity.name" class="group">
            <mat-option *ngFor="let field of entity.fields"  [value]="field">
              {{field.name}}
            </mat-option>
          </mat-optgroup>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Example</mat-label>
        <textarea matInput placeholder="Example" formControlName="example" class="text-field">
        </textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="createSample()">Create</button>
  <button mat-raised-button (click)="closeDialog()" cdkFocusInitial>Cancel</button>
</div>
