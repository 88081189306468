import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TopicDiscoveryService {
  constructor(private http: HttpClient) { }

  getTopicDiscoveryData(ontologyId: string): Observable<any> {
    return this.http.post<any>(`api/tcd/${ontologyId}`, { "size": 10000, "from": 0 }, { observe: "response" });
  }

  getInference(request: any): Observable<any> {
    return this.http.post<any>(`api/tcd/inference/single`, request, { observe: 'response' });
  }

  startInference(ontologyId: string): Observable<any> {
    return this.http.post<any>(`api/tcd/${ontologyId}/inference`, null, { observe: 'response' });
  }

  startTraining(ontologyId: string): Observable<any> {
    return this.http.post<any>(`api/tcd/${ontologyId}/train`, null, { observe: 'response' });
  }
}

