import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'label',
    pathMatch: 'full',
  },
  {
    path: 'label',
    data: { title: 'Label' },
    loadChildren: () => import('../paragraph-review/paragraph-review.module').then(mod => mod.ParagraphReviewModule),
  },
  {
    path: 'manage/labels',
    loadChildren: () => import('../label-management/label-management.module').then(mod => mod.LabelManagementModule),
    data: { title: 'Mangage Labels' },
  },
  {
    path: 'manage/ontology',
    loadChildren: () => import('../entity-designer/entity-designer.module').then(mod => mod.EntityDesignerModule),
    data: { title: 'Entity Designer' },
  },
  {
    path: 'search',
    loadChildren: () => import('../elastic-search/elastic-search.module').then(mod => mod.ElasticSearchModule),
    data: { title: 'Elastic Search' },
  },
  { path: '**', redirectTo: '' },
];
