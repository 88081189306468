import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenerateSampleRequest } from '../models/DataGenie';

@Injectable({
  providedIn: 'root'
})
export class DataGenieService {

  constructor(private http: HttpClient) { }

  generateSample(generateSampleRequest: GenerateSampleRequest): Observable<any> {
    return this.http.post('api/datagenie/generateSamples', generateSampleRequest, { observe: 'response' });
  }
}
