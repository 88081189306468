<div fxLayout="row" fxLayoutGap="1em" class="label-example-card">
  <div fxLayout="column" fxFlex fxLayoutGap="1em">
    <div class="search-mat-card">
      <div fxLayout="row">
        <div class="label-example-container">
          <div class="source-container" fxLayoutGap="1em" fxLayout="column">
            <span class="source-title">Source</span>
            <span class="source-content">{{ labelExample?.ontologyDisplayName }}</span>
          </div>
        </div>
        <div class="example-container" fxLayoutGap="1em" fxLayout="column">
          <p class="example-content" [innerHtml]="labelExample?.example | trustHtml"></p>
          <div fxLayout="row" fxLayoutGap="0.5em" fxLayoutAlign="end end">
            <a href="javascript:void(0)" (click)="goToProvision()">
              <strong>Provision Id: </strong>
              <span>{{ labelExample?.provisionId?.toString() }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
