<div fxLayout="column" fxLayoutGap="1em" class="dialog-margin">
  <h2 class="title-margin" mat-dialog-title>Import Sample(s) for {{selectedOntology}} using Active Learning</h2>
  <div [formGroup]="activeLearning" fxLayout="column" fxLayoutGap="1em">
    <div>
      <mat-form-field>
        <mat-label>Strategy</mat-label>
        <mat-select formControlName="strategy" [value]=activeLearningStrategyType.SelectionDifferent>
          <mat-option *ngFor="let field of strategyFields" [value]="field.value">
            {{field.name}}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="!activeLearning.get('strategy').pristine && activeLearning.get('strategy').hasError('required')">
          Strategy is Required
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="activeLearning.get('strategy').value == activeLearningStrategyType.Confused">
      <mat-form-field>
        <mat-label>Label</mat-label>
        <mat-select formControlName="label">
          <mat-option *ngFor="let label of labels" [value]="label.name">
            {{label.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="activeLearning.hasError('emptyLabel')">
        Label Selection is Required
      </mat-error>
    </div>
    <div>
      <mat-form-field>
        <input matInput placeholder="Maximum number of samples (up to 100)" formControlName="count">
      </mat-form-field>
      <mat-error
        *ngIf="activeLearning.get('count').hasError('pattern') && activeLearning.get('count').hasError('required')">
        Please specify a number between 1 and 100
      </mat-error>
    </div>
    <div fxLayout="row">
      <mat-form-field>
        <input matInput placeholder="Tenant" formControlName="tenant">
      </mat-form-field>
    </div>
  </div>
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
      <button mat-raised-button color="primary" [disabled]="activeLearning.status == 'INVALID'"
        (click)="getSamples()">Submit</button>
      <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
