// errors-handler.ts
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';
import { HTTPStatusCode } from '../models/HttpStatusCode';
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler, IErrorService {
  constructor(private injector: Injector) { }

  handleError(error: Error | HttpErrorResponse) {
    const loggingService = this.injector.get(LoggingService);
    // const telemetryService = this.injector.get(ApplicationInsightsService);
    const notificationService = this.injector.get(NotificationService);


    let detail = '';
    if (error instanceof HttpErrorResponse) {
      if (typeof (error.error) !== 'string') {
        if (error.error && !error.error.Detail) {
          error.error.Detail = error.error.detail;
        }

        detail = error.error ? (error.error.Detail ? error.error.Detail : "The server failed to process the request.") : "The server failed to process the request";
      }
      // Server or connection error happened
      if (!navigator.onLine) {
        return notificationService.showError('Internet Offline');
      } else {
        switch (error.status) {
          case HTTPStatusCode.ServerNotReachable:
            return notificationService.showError(
              error.error
                ? error.error.Detail
                  ? error.error.Detail
                  : 'Server not Reachable'
                : 'Server not Reachable',
            );

          case HTTPStatusCode.InternalServerError:
            return notificationService.showError(detail);

          case HTTPStatusCode.NotFound:
            return notificationService.showError("Resource not found");

          case HTTPStatusCode.Unauthorized:
            return notificationService.showError('Unauthorized Request. Please Login again.');

          case HTTPStatusCode.BadRequest:
            const errorDetail = this.getErrorDetails(error);
            if (errorDetail != null)
              detail = errorDetail;
            else if (error.error.message != null)
              detail = error.error.message;
            return notificationService.showError(detail);

          case HTTPStatusCode.Forbidden:
            return notificationService.showError('You are not allowed to do this operation');

          default:
            return notificationService.showError(detail);
        }
      }
    } else {
      loggingService.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  }

  getErrorDetails(error: HttpErrorResponse) {
    if (error && error.error) {
      if (error.error.errors && !this.isObjectEmpty(error.error.errors)) {
        return error.error.errors[Object.keys(error.error.errors)[0]][0];
      }
      else if (error.error && typeof error.error === 'string') {
        return error.error;
      }
    }
    return null;
  }

  isObjectEmpty(obj) {
    return obj // 👈 null and undefined check
      && Object.keys(obj).length === 0
  }
}
