import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartActions, ChartFilters, Filter } from '../charts/ChartOptions';
import { MatIconRegistry } from '@angular/material/icon';
import { ReviewView } from '../../core/models/ReviewView';

@Component({
  selector: 'chart-toolbar',
  templateUrl: './chart-toolbar.component.html',
  styleUrls: ['./chart-toolbar.component.scss']
})
export class ChartToolbarComponent implements OnInit {

  @Output() action: EventEmitter<ChartActions> = new EventEmitter<ChartActions>();
  @Output() filters: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @Output() searchText: EventEmitter<string> = new EventEmitter<string>();
  @Input() reviewView: ReviewView;

  ReviewViewEnum = ReviewView;
  chartActions: typeof ChartActions = ChartActions;
  chartFilters: typeof ChartFilters = ChartFilters;
  selectedFilter: ChartFilters = ChartFilters.ClusterId;
  selectedAction: ChartActions = ChartActions.Panning;
  text: string;

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.iconRegistry
      .addSvgIcon('tcd-select', this.domSanitizer.bypassSecurityTrustResourceUrl(`${document.baseURI}assets/icons/tcd-select.svg`))
      .addSvgIcon('tcd-by-cluster', this.domSanitizer.bypassSecurityTrustResourceUrl(`${document.baseURI}assets/icons/tcd-by-cluster.svg`))
      .addSvgIcon('tcd-by-confirmed', this.domSanitizer.bypassSecurityTrustResourceUrl(`${document.baseURI}assets/icons/tcd-by-confirmed.svg`))
      .addSvgIcon('tcd-reset', this.domSanitizer.bypassSecurityTrustResourceUrl(`${document.baseURI}assets/icons/tcd-reset.svg`))
      .addSvgIcon('tcd-search', this.domSanitizer.bypassSecurityTrustResourceUrl(`${document.baseURI}assets/icons/tcd-search.svg`));
  }

  ngOnInit() {
    this.filters.emit([{ field: this.selectedFilter, values: [] }]);
  }

  onActionChange(event: MatButtonToggleChange) {
    if (event.value === ChartActions.Reset) {
      this.selectedAction = this.chartActions.Panning;
    } else {
      this.selectedAction = event.value;
    }
    this.action.emit(event.value);
  }

  onFilterChange(event: MatButtonToggleChange, filterGroup: MatButtonToggleGroup) {
    if (this.selectedFilter === event.value) {
      filterGroup.value = null;
      this.selectedFilter = null;
    } else {
      this.selectedFilter = event.value;
    }

    switch (this.selectedFilter) {
      case ChartFilters.ClusterId:
        this.filters.emit([{ field: ChartFilters.ClusterId, values: [] }]);
        break;
      case ChartFilters.Confirmed:
        this.filters.emit([{ field: ChartFilters.Confirmed, values: ['true'] }]);
        break;
      default:
        this.filters.emit([]);
        break;
    }

  }

  sendTestData() {
    this.searchText.emit(this.text);
  }

}
