import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../../root-store/state';
import { select, Store } from '@ngrx/store';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { selectRootStateEntities } from '../../../root-store/selectors';
import { Observable, Subject } from 'rxjs';
import { Entity, Field } from '../../../core/models/models';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GenerateSampleRequest } from '../../../core/models/DataGenie';
import { DataGenieService } from '../../../core/services/data-genie.service';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'app-data-genie-dialog',
  templateUrl: './data-genie-dialog.component.html',
  styleUrls: ['./data-genie-dialog.component.scss']
})
export class DataGenieDialogComponent implements OnInit{

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public entities: Entity[] = [];
  public formGroup: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private dataGenieService: DataGenieService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private store: Store<AppState>) {
  }

  fieldControl = new FormControl('', [Validators.required]);
  filteredEntities: Observable<Entity[]>;
  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      field: this.fieldControl,
      example: new FormControl('', [Validators.required]),
    });
    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectRootStateEntities))
      .subscribe(entities => {
        this.entities = this.sortEntities(entities);
        this.fieldControl.clearValidators();
        this.fieldControl.addValidators([this.autoCompleteValidate(), Validators.required]);
        this.filteredEntities = this.fieldControl.valueChanges.pipe(
          startWith(''),
          map(value => this.filter(value || '')),
        );
      });
  }

  private filter(value: string): Entity[] {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      const entities = this.entities
        .filter(entity => entity.fields.some(field => field.name.toLowerCase().includes(filterValue)))
        .map(entity => ({...entity}));
      entities.forEach(entity => {
          entity.fields = entity.fields.filter(field => field.name.toLowerCase().includes(filterValue));
        }
      );
      return entities;
    }
    return this.entities;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  sortEntities(entities: Entity[]): Entity[] {
    return [...entities].sort((a, b) => a.name.localeCompare(b.name));
  }

  autoCompleteValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
      if (typeof control.value === 'object') {
        return null;
      }
      return { invalidAutoComplete: { value: control.value} }
    };
  }

  fieldFn(field: Field): string {
    return field.name;
  }

  getEntityByField(field: Field): Entity {
    return this.entities.find(entity => entity.fields.includes(field));
  }

  createSample() {
    if (this.formGroup.valid) {
      const field = this.formGroup.get('field').value as Field;
      const entity = this.getEntityByField(field);
      const example = this.formGroup.get('example').value as string;
      const generateSampleRequest: GenerateSampleRequest = {
        fields: [
          {
            name: `${entity.name} - ${field.name}`,
            example,
          }
        ],
        jurisdiction: field.name,
        n: 1,
        ontology: entity.ontology,
        tags: [],
      };
      this.dataGenieService.generateSample(generateSampleRequest).subscribe(response => {
        if (response.status === 200) {
          this.notificationService.showSuccess('Sample generation requested successfully');
          this.dialog.closeAll();
        } else {
          this.notificationService.showError('Failed to request sample generation');
        }
      });
    }
  }
}
