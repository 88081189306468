<div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="1em">
  <div class="exemplar-title">
    <span>Exemplar Samples</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="1em">
    <div *ngIf="!samples || samples.length == 0" fxLayout="column" fxLayoutAlign="space-around center">
      <img
        src="https://app.optimizely.com/static/img/p13n/page-list-empty-state.svg"
        class="anchor--middle push--ends"
        width="450"
      />
      <h1 id="no-samples-title">No exemplar paragraphs have been set yet</h1>
    </div>
    <div *ngIf="samples?.length > 0" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="1em">
      <div class="scroll-search" id="scroll-search">
        <div *ngFor="let labelExample of currentPage">
          <app-example-view [labelExample]="labelExample"></app-example-view>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="0.5em" class="search-footer">
        <div fxFlex="50" fxLayoutAlign="start center">
          <p class="font-medium">{{ total | number }} Results Retrieved</p>
        </div>
        <div fxFlex="50" fxLayoutAlign="end center" fxLayoutGap="0.5em">
          <button
            matTooltip="First Page"
            class="footer-button"
            color="primary"
            [disabled]="page <= 1"
            (click)="firstPage()"
            mat-icon-button
          >
            <mat-icon>first_page</mat-icon>
          </button>
          <button
            matTooltip="Previous"
            class="footer-button"
            color="primary"
            [disabled]="page <= 1"
            (click)="loadMore(previous)"
            mat-icon-button
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <p class="font-medium">
            <input
              class="input-page"
              [(ngModel)]="page"
              [disabled]="total < PAGE_SIZE"
              (input)="goToPage()"
            />
            / {{ totalPages }}
          </p>
          <button
            matTooltip="Next"
            class="footer-button"
            color="primary"
            [disabled]="total === 0 || page === totalPages"
            (click)="loadMore(next)"
            mat-icon-button
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <button
            matTooltip="Last Page"
            class="footer-button"
            color="primary"
            [disabled]="page === totalPages"
            (click)="lastPage()"
            mat-icon-button
          >
            <mat-icon>last_page</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
