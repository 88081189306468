<div fxLayout="column" fxLayoutGap="1em" *ngIf="dataSource">
  <div>
    <mat-select-search (click)='getEntities()' [selectedItems]="selectedEntity" itemType='Entity' (selectionChanged)="entitySelected($event)"
      [isMultiple]="false" [items]="entities"></mat-select-search>
  </div>

  <div *ngIf="selectedEntity === null">

    <div fxLayout="row">
      <mat-form-field>
        <input matInput [(ngModel)]="filterText" placeholder="Filter Labels">
      </mat-form-field>
    </div>

    <div fxLayout="row" *ngIf="showSamplesCount">
      <mat-form-field>
        <mat-label>Sort By</mat-label>
        <mat-select (selectionChange)="sortChanged($event.value)" [(value)]="selectedLabelSort">
          <mat-option *ngFor="let label of sortByLabels"
                      [value]="label.value">
            {{label.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-slide-toggle [checked]="showSamplesCount" (change)="toggleChanged()" color="primary">Show samples count</mat-slide-toggle>
    </div>
    <div fxLayout="row">
      <mat-list *ngIf="!isSwitchView" class="list-scroll">
        <mat-list-item class="label" *ngFor="let label of labels | filterLabel:filterText" [attr.data-testid]="'label_list_' + label.type"
                       [ngClass]="{'selected-item': label === selectedLabel}">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <div *ngIf="label.type == 2">
              <mat-icon>person</mat-icon>
            </div>
            <div>
              <span (click)="labelSelected(label)" class="label-text" matTooltipPosition="left"
                matTooltipClass="test" matTooltip="{{getExample(label)}}">
                {{label.name}}
              </span>
            </div>
            <div *ngIf = "showSamplesCount">
              <span class="label-sample-count"> {{labelSampleCount[label.id] || 0}} </span>
            </div>
            <div>
              <mat-icon (click)="removeFromFavorites(label)" matTooltipPosition="above"
                matTooltip="Remove from Favorites" *ngIf="label.isPinned" class="start favourite">star
              </mat-icon>
              <mat-icon *ngIf="!label.isPinned" (click)="addToFavorites(label)" matTooltipPosition="above"
                matTooltip="Add to Favorites" class="star">star_border</mat-icon>
              <mat-icon (click)="getLabelExample(label)" class="star" matTooltipPosition="above"
                matTooltip="View definition and samples">library_books</mat-icon>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div>
    <field-tree-view *ngIf="selectedEntity !== null" [items]="selectedEntity.fields"
      (nodeClicked)="labelSelected($event)"></field-tree-view>
  </div>
</div>
