import { Action } from '@ngrx/store';
import { ConflictFilters, ConflictGroup, ConflictReport } from 'src/app/core/models/Conflict';
import { DatasetDto } from '../../core/models/Dataset';

export enum DatasetReviewActionType {
  GetConflictingReportResults = '[Dataset Review] Get Conflicting Report Results',
  ChangeDatasetSelection = '[Dataset Review] Change DatasetSelection',
  AddTagsToParagraph = '[Dataset Review] Add Tags To Paragraph',
  RemoveTagFromParagraph = '[Dataset Review] Remove Tag From Paragraph',
  BulkTagAdd = '[Dataset Review] Bulk Add Tags',
  BulkTagRemove = '[Dataset Review] Bulk Remove Tags',
  SetConflictGroup = '[Dataset Review] Set Conflict Group',
  SetConflictFilters = '[Dataset Review] Set Conflict Filters',
  SetSortedParentIds = '[Dataset Review] Set Sorted Parent Ids',
  ConflictReportPageChanged = '[Dataset Review] Conflict Report Page Changed',
  CurrentPageSelected = '[Dataset Review] Current Page Selected',
  CurrentPageDeselected = '[Dataset Review] Current Page Deselected',
  ConflictParagraphsSelected = '[Dataset Review] Conflict Paragraphs Selected',
  ConflictParagraphsDeselected = '[Dataset Review] Conflict Paragraphs Deselected',
  SelectAllConflictResults = '[Dataset Review] Select All Conflict Results',
  ClearAllSelectedConflictResults = '[Dataset Review] Clear All Selected Conflict Results',
}

export class GetConflictingReportResults implements Action {
  public readonly type = DatasetReviewActionType.GetConflictingReportResults;
  constructor(public payload: ConflictReport) { }
}


export class ChangeDatasetSelection implements Action {
  public readonly type = DatasetReviewActionType.ChangeDatasetSelection;
  constructor(public payload: DatasetDto) { }
}

export class BulkTagAdd implements Action {
  public readonly type = DatasetReviewActionType.BulkTagAdd;
  constructor(public ontology: string, public paragraphIds: number[], public tags: string[]) { }
}

export class BulkTagRemove implements Action {
  public readonly type = DatasetReviewActionType.BulkTagRemove;
  constructor(public ontology: string, public paragraphIds: number[], public tags: string[]) { }
}

export class AddTagsToParagraph implements Action {
  public readonly type = DatasetReviewActionType.AddTagsToParagraph;
  constructor(public paragraphId: number, public tags: string[], public update?: boolean) { }
}

export class RemoveTagFromParagraph implements Action {
  public readonly type = DatasetReviewActionType.RemoveTagFromParagraph;
  constructor(public paragraphId: number, public tags: string[], public update?: boolean) { }
}

export class SetConflictGroup implements Action {
  public readonly type = DatasetReviewActionType.SetConflictGroup;
  constructor(public payload?: ConflictGroup) { }
}

export class SetConflictFilters implements Action {
  public readonly type = DatasetReviewActionType.SetConflictFilters;
  constructor(public payload?: ConflictFilters) { }
}

export class SetSortedParentIds implements Action {
  public readonly type = DatasetReviewActionType.SetSortedParentIds;
  constructor(public payload?: number[]) { }
}

export class ConflictReportPageChanged implements Action {
  public readonly type = DatasetReviewActionType.ConflictReportPageChanged;
  constructor(public payload: number) { }
}

export class CurrentPageSelected implements Action {
  public readonly type = DatasetReviewActionType.CurrentPageSelected;
  constructor(public conflictParagraphIds: Array<number>) { }
}

export class CurrentPageDeSelected implements Action {
  public readonly type = DatasetReviewActionType.CurrentPageDeselected;
  constructor(public conflictParagraphIds: Array<number>) { }
}

export class ConflictParagraphsSelected implements Action {
  public readonly type = DatasetReviewActionType.ConflictParagraphsSelected;
  constructor(public conflictParagraphIds: Array<string>) { }
}

export class ConflictParagraphsDeselected implements Action {
  public readonly type = DatasetReviewActionType.ConflictParagraphsDeselected;
  constructor(public conflictParagraphIds: Array<string>) { }
}

export class SelectAllConflictResults implements Action {
  public readonly type = DatasetReviewActionType.SelectAllConflictResults;
  constructor() { }
}

export class ClearAllSelectedConflictResults implements Action {
  public readonly type = DatasetReviewActionType.ClearAllSelectedConflictResults;
  constructor() { }
}

export type DatasetReviewActions =
  | GetConflictingReportResults
  | BulkTagAdd
  | BulkTagRemove
  | AddTagsToParagraph
  | SetConflictGroup
  | SetConflictFilters
  | SetSortedParentIds
  | ChangeDatasetSelection
  | RemoveTagFromParagraph
  | ConflictReportPageChanged
  | CurrentPageSelected
  | CurrentPageDeSelected
  | ConflictParagraphsSelected
  | ConflictParagraphsDeselected
  | SelectAllConflictResults
  | ClearAllSelectedConflictResults;
