import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Label, FavouriteLabel, LabelExample } from '../models/Label';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LabelService {
  constructor(private http: HttpClient) { }

  upload(obj) {
    return this.http.post<Label[]>('api/label', obj);
  }

  getLabels(ontology: string) {
    return this.http.get<Label[]>(`api/label/${ontology}`);
  }

  getLabelsExcept(ontology: string) {
    return this.http.get<Label[]>(`api/label/all/except/${ontology}`);
  }

  updateLabel(Id, labelPatches) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });

    return this.http.patch<any>(`api/label/${Id}`, labelPatches, { headers, observe: 'response' });
  }

  mergeLabels(dto) {
    return this.http.post<any>('api/label/mergelabels', dto);
  }

  delete(label: Label) {
    return this.http.delete<any>(`api/label/${label.id}`);
  }

  copyLabelsWithinOntology(dto) {
    return this.http.post<any>('api/label/copylabelswithinontology', dto);
  }

  export() {
    return this.http.get<any>('api/label/export');
  }

  updateSharedLabels(dto) {
    return this.http.post<any>('api/label/updatesharedlabels', dto);
  }

  getFavourite(ontology: string) {
    return this.http.get<FavouriteLabel[]>('api/label/favourite', { params: { ontology } });
  }

  addFavourite(ontology: string, labelId: number) {
    return this.http.post('api/label/favourite', { ontology, labelId });
  }

  deleteFavourite(id: string) {
    return this.http.delete(`api/label/favourite/${id}/`);
  }

  getLabelExemplar(labelIds: number[]) {
    const params = labelIds.reduce((p, id) => p.append('labelIds', id.toString()), new HttpParams());
    return this.http.get<LabelExample[]>(`api/label/example`, { params });
  }

  getSamplesCount(ontology: string): Observable<Map<string, number>> {
    return this.http.get<any>(`api/search/${ontology}/samplescount`);
  }

  getSharedSamplesCount(ontology: string): Observable<Map<string, number>> {
    return this.http.get<any>(`api/label/${ontology}/sharedsamplescount`);
  }
}
