import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class EnumIteratorPipe implements PipeTransform {
  transform(value): any {
    const keys = [];
    for (const enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({ key: parseInt(enumMember, null), value: value[enumMember] });
      }
    }
    return keys;
  }
}

@Pipe({ name: 'sortmap' })
export class SortMapPipe implements PipeTransform {
  transform(value: object): any {
    const labels = Object.entries(value).sort((a, b) => {
      return +a[1].extractorId - +b[1].extractorId;
    });

    const labelMap = [];

    labels.forEach(label => {
      labelMap.push({
        key: label[0],
        value: label[1],
      });
    });

    return labelMap;
  }
}
