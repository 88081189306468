import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { ChartActions, ChartOptions, TooltipInfo } from '../ChartOptions';

@Component({
  selector: 'scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: ['./scatter-plot.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScatterPlotComponent implements OnChanges {

  @Input() options: ChartOptions;
  @Input() onHoverItemId: any;
  @Output() selectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  readonly figureSize: number = 4;
  readonly ticks: number = 10;
  readonly offset: number = 1.1;
  readonly zoomFactor: number = 8;
  private maxX: number;
  private maxY: number;
  private maxCluster: number;
  private minCluster: number;
  private selected: any[] = [];
  private filtered: any[] = [];
  private svg: any;
  private k: number;
  private dots: any;
  private x: any;
  private y: any;
  private xAxis: any;
  private yAxis: any;
  private grid: any;
  private zoom: any;
  private width: number;
  private height: number;
  private colors: Map<number, string> = new Map();
  private readonly clusterClass: string = 'cluster';
  private readonly clusterIdPrefix: string = 'cluster-';
  private readonly clusterDetailClassPrefix: string = 'cluster-detail-';
  private readonly tooltipDetailClassPrefix: string = 'tooltip-detail-'

  ngOnChanges() {
    this.k = this.options.height / this.options.width;
    if (this.options.data && this.options.height && this.options.width && this.options.data.length) {
      this.maxCluster = Math.max(...this.options.data.map(item => item[this.options.clusterField]));
      this.minCluster = Math.min(...this.options.data.map(item => item[this.options.clusterField]));
      if (this.width !== this.options.width || this.height !== this.options.height) {
        this.createGraphic();
      }
      this.drawCustomDots();
      this.applyActions();
      this.getFilteredData();
      this.colorDots();
    }
  }

  private assignColors() {
    for (let index = this.minCluster; index <= this.maxCluster; index++) {
      this.colors.set(index, this.intToColor(index));
    }
  }

  private getZoom() {
    // Zoom
    this.zoom = d3.zoom()
      .scaleExtent([1, 40])
      .extent([[0, 0], [this.options.width, this.options.height]])
      .on('zoom', event => this.zoomed(event));
  }

  private createGraphic() {
    this.width = this.options.width;
    this.height = this.options.height;
    this.cleanSvg();
    this.createSvg();
    this.assignColors();
    this.drawPlot();
    this.drawDots();
    this.createTooltip('tooltip', this.clusterClass);
    this.getZoom();
  }

  private cleanSvg() {
    this.svg = d3.select('div#scatter-plot').selectAll('*').remove();
  }

  private createSvg(): void {
    // Crate svg
    this.svg = d3.select('div#scatter-plot')
      .append('svg')
      .attr('pointer-events', 'all')
      .attr('width', this.options.width)
      .attr('height', this.options.height)
      .attr('viewBox', [0, 0, this.options.width, this.options.height])
  }

  private drawPlot(): void {
    // Add X axis
    this.xAxis = this.svg.append('g');
    this.maxX = this.getMaximumValue(this.options.data, this.options.axis.xAxisField);
    this.x = d3.scaleLinear()
      .domain([-this.maxX, this.maxX])
      .range([0, this.options.width]);
    this.getXAxis(this.xAxis, this.x);

    // Add Y axis
    this.yAxis = this.svg.append('g');
    this.maxY = this.getMaximumValue(this.options.data, this.options.axis.yAxisField);
    this.y = d3.scaleLinear()
      .domain([-this.maxY, this.maxY])
      .range([this.options.height, 0]);
    this.getYAxis(this.yAxis, this.y, this.k);

    // Add grid
    this.grid = this.svg.append('g');
    this.getGrid(this.grid, this.x, this.y, this.k);
  }

  drawDots() {
    // Dots
    this.dots = this.svg.append('g');
    this.dots.selectAll('circle')
      .data(this.options.data)
      .enter()
      .append('circle')
      .attr('class', this.clusterClass)
      .attr('id', (d: any) => this.clusterIdPrefix + d[this.options.clusterField])
      .attr('cx', (d: any) => d[this.options.axis.xAxisField])
      .attr('cy', (d: any) => d[this.options.axis.yAxisField])
      .attr('r', this.figureSize)
      .style('opacity', .5)
      .style('fill', 'gray');
  }

  drawCustomDots() {
    if (this.options.customData.length) {
      this.dots.selectAll('.crossPath').remove();
      this.dots.selectAll('.crossPath')
        .data(this.options.customData)
        .enter()
        .append('path')
        .attr('class', 'crossPath')
        .attr('d', (d: any) => this.crossPath(this.x(d[this.options.axis.xAxisField]), this.y(d[this.options.axis.yAxisField]), this.figureSize))
        .attr('stroke', 'red')
        .attr('stroke-width', this.figureSize);
    }
  }

  onHover(onHoverItemId: any) {
    this.dots.selectAll('circle')
      .filter((d: any) => d[this.options.idField] === onHoverItemId)
      .transition()
      .duration(150)
      .style('fill', 'rgb(255,255,255)')
      .style('stroke', 'black')
      .style('stroke-width', '8')
      .transition()
      .duration(150)
      .style('fill', 'rgb(0,0,0)')
      .style('stroke-width', '4')
      .transition()
      .duration(150)
      .style('fill', 'rgb(255,255,255)')
      .style('stroke-width', '2')
      .transition()
      .duration(150)
      .style('fill', 'rgb(0,0,0)')
      .style('stroke-width', '0');
  }

  createTooltip(className: string, pointClassName: string) {
    // Tooltip
    const tooltip = d3.select('div#scatter-plot')
      .append('div')
      .attr('class', className);
    this.addTooltipEvents(tooltip, pointClassName);
  }
  applyActions(): void {
    switch (this.options.action) {
      case ChartActions.Brushing:
        this.brushingSettings();
        break;
      case ChartActions.Panning:
        this.panningSettings();
        break;
      case ChartActions.Reset:
        this.resetSettings();
        break;
      case ChartActions.ZoomToCustom:
        this.goToCenter(this.options.customData);
        break;
      case ChartActions.ZoomToProvisions:
        this.zoomTo(this.options.zoomToIds);
        break;
      case ChartActions.ZoomToClusters:
        this.zoomToClusters()
        break;
      default:
        break;
    }
  }

  panningSettings() {
    this.svg.select('.overlay').style('pointer-events', 'none');
    this.svg.on('.brush', null);
    this.svg.call(this.zoom);
    this.zoom.scaleBy(this.svg, 1.0001);
  }

  brushingSettings() {
    this.svg.select('.overlay').style('pointer-events', 'all');
    this.svg.call(this.zoom)
      .on('mousedown.zoom', null)
      .on('touchstart.zoom', null)
      .on('touchmove.zoom', null)
      .on('touchend.zoom', null);
    this.zoom.scaleBy(this.svg, 1.0001);
  }

  resetSettings() {
    this.selected = [];
    this.selectionChange.emit(this.selected);
    this.colorDots();
    this.options.action = ChartActions.Panning;
    this.panningSettings();
    this.svg
      .transition()
      .duration(750)
      .call(this.zoom.transform, d3.zoomIdentity);
  }

  zoomTo(ids: any[]) {
    const items =  [].concat
      .apply([], this.options.data.map(item => item[this.options.children]))
      .filter(item => ids.includes(item[this.options.idField]));
    console.log(items);
    if (items.length) {
      this.goToCenter(items);
    }
  }

  goToCenter(data: any[]) {
    const [x0, x1]: number[] = d3.extent(data, d => d[this.options.axis.xAxisField]);
    const [y0, y1]: number[] = d3.extent(data, d => d[this.options.axis.yAxisField]);
    const x = (x0 === x1) ? this.zoomFactor : Math.pow(this.maxX * 2, 2) / (x1 - x0);
    const y = (y0 === y1) ? 1 : Math.pow(this.maxY * 2, 2) / (y1 - y0);

    const [min, max] = d3.extent([Math.abs(x), Math.abs(y)]);
    const k = max / min;
    const tx = this.x(-k * (this.maxX + x0 + Math.abs(x1 - x0) / 2));
    const ty = this.y(-k * (-this.maxY + y0 + Math.abs(y1 - y0) / 2));

    this.svg
      .transition()
      .duration(750)
      .call(this.zoom.transform, d3.zoomIdentity.translate(tx, ty).scale(k));
  }

  zoomed(event: any) {
    const zoomedX = event.transform.rescaleX(this.x).interpolate(d3.interpolateRound);
    const zoomedY = event.transform.rescaleY(this.y).interpolate(d3.interpolateRound);
    this.dots.attr('transform', event.transform)
      .selectAll('circle')
      .attr('cx', (d: any) => this.x(d[this.options.axis.xAxisField]))
      .attr('cy', (d: any) => this.y(d[this.options.axis.yAxisField]))
      .attr('r', this.figureSize / event.transform.k);
    this.dots.attr('transform', event.transform)
      .selectAll('.crossPath')
      .attr('d', (d: any) => this.crossPath(this.x(d[this.options.axis.xAxisField]), this.y(d[this.options.axis.yAxisField]), this.figureSize / event.transform.k))
      .attr('stroke-width', this.figureSize / event.transform.k);
    this.getXAxis(this.xAxis, zoomedX);
    this.getYAxis(this.yAxis, zoomedY, this.k);
    this.getGrid(this.grid, zoomedX, zoomedY, this.k);
    if (this.options.action === ChartActions.Brushing) {
      const brush = d3.brush()
        .keyModifiers(false)
        .on('start brush end', event => this.brushed(event, zoomedX, zoomedY));
      this.svg.call(brush);
    }
  }

  brushed(event: any, x: any, y: any) {
    this.selected = [];
    if (event.selection) {
      const [[x0, y0], [x1, y1]] = event.selection;
      this.selected = this.dots.selectAll('circle')
        .style('fill', 'gray')
        .filter((d: any) => x0 <= x(d[this.options.axis.xAxisField]) && x(d[this.options.axis.xAxisField]) < x1
          && y0 <= y(d[this.options.axis.yAxisField]) && y(d[this.options.axis.yAxisField]) < y1)
        .style('fill', (d: any) => this.colors.get(d[this.options.clusterField]))
        .data();
      if (event.type === 'end') {
        this.selectionChange.emit(this.getChildren(this.selected, this.options.children));
        this.svg.call(d3.brush().clear);
      }
    } else {
      this.dots.selectAll('circle')
        .style('fill', (d: any) => this.colors.get(d[this.options.clusterField]))
    }
  }
  getChildren(items: any[], field: string): any[] {
    let ids: any[] = [];
    if (field) {
      items.forEach(item => {
        if (item[field]) {
          ids = ids.concat(item[field])
        } else {
          ids.push(item);
        }
      });
    } else {
      return items;
    }
    return ids;
  }
  getFilteredData() {
    if (this.options.filters.length) {
      const omit = (prop, { [prop]: _, ...rest }) => rest;
      const data = this.options.data.map(item => {
        return omit(this.options.children, item);
      });
      this.filtered = [].concat
        .apply(data, this.options.data.map(item => item[this.options.children]));
      this.options.filters.forEach(filter => {
        if (filter.values.length) {
          this.filtered = this.filtered.filter(item => filter.values.includes(item[filter.field].toString()));
        }
      });
    } else {
      this.filtered = [];
    }
  }
  colorDots(event?: any) {
    let selectedIds: string[] = [];
    if (this.filtered.length) {
      selectedIds = this.filtered.map(selection => selection[this.options.idField]);
    } else if (this.selected.length) {
      selectedIds = this.selected.map(selection => selection[this.options.idField]);
    }

    if (event) {
      if (selectedIds.length) {
        if (selectedIds.includes(d3.select(event.target).data()[0][this.options.idField])) {
          d3.select(event.target).style('fill', (d: any) => this.colors.get(d[this.options.clusterField]));
        } else {
          d3.select(event.target).style('fill', 'gray');
        }
      } else {
        d3.select(event.target).style('fill', 'gray');
      }
    } else {
      this.dots.selectAll('circle')
        .style('fill', 'gray')
        .filter((d: any) => selectedIds.includes(d[this.options.idField]))
        .style('fill', (d: any) => this.colors.get(d[this.options.clusterField]));
    }

    if (!selectedIds.length) {
      this.dots.selectAll('circle')
        .style('fill', 'gray');
    }
  }

  getXAxis(xAxis: any, x: any) {
    xAxis
      .attr('transform', `translate(0,${this.options.height})`)
      .call(d3.axisTop(x).ticks(this.ticks).tickFormat(() => ''))
      .call(g => g.select('.domain').attr('display', 'none'));
    if (!this.options.showAxis) {
      xAxis.call(g => g.selectAll('.tick').selectAll('line').remove());
    }
  }

  getYAxis(yAxis: any, y: any, k: number) {
    yAxis
      .call(d3.axisRight(y).ticks(this.ticks * k).tickFormat(() => ''))
      .call(g => g.select('.domain').attr('display', 'none'));
    if (!this.options.showAxis) {
      yAxis.call(g => g.selectAll('.tick').selectAll('line').remove());
    }
  }

  getMaximumValue(data: any[], field: string) {
    const max = Math.max(...data.map(register => Math.abs(register[field])));
    return max * this.offset;
  }

  intToColor(clusterId: number) {
    const interval = Math.pow(2, 24) / (this.maxCluster - this.minCluster);
    return '#' + ('000000' + ((clusterId * interval) >>> 0).toString(16)).slice(-6);
  }

  getTooltip(selection: any, tooltipInfo: TooltipInfo[]): string {
    let html = '<table><tr align="center"><th colspan="2">Data</th></tr>';
    tooltipInfo.forEach(tooltipInfo => {
      if ( selection[tooltipInfo.field] !== -selection[this.options.clusterField]) {
      html = html +
        `<tr>
          <td style='white-space: nowrap'><strong>${tooltipInfo.label}</strong></td>
          <td>
          ${(tooltipInfo.maxItems ?
          selection[tooltipInfo.field].split(',').slice(0, tooltipInfo.maxItems).join(', ') :
          selection[tooltipInfo.field])}
          </td>
        </tr>`;
      }
    });
    html = html + '</table>';
    return html;
  }

  addTooltipEvents(tooltip: any, pointClassName: string) {
    this.dots.selectAll('.' + pointClassName)
      .on('mouseover', (event: any) => {
        tooltip.style('opacity', 1);
        d3.select(event.target).style('fill', 'red');

      })
      .on('mouseout', (event: any) => {
        tooltip
          .transition()
          .duration(0)
          .style('opacity', 0);
        this.colorDots(event);
      })
      .on('mousemove', (event: any) => {
        tooltip.html(this.getTooltip(event.target.__data__, this.options.tooltipInfo))
          .style('left', (event.pageX + 25) + 'px')
          .style('top', (event.pageY + 25) + 'px');
      })
      .on('click', (event: any) => {
        if (event.target.__data__[this.options.children]) {
          this.showDetail(event.target.__data__);
        } else {
          this.hideDetail(event.target.__data__[this.options.clusterField]);
        }
      });
  }

  showDetail(data: any) {
    data[this.options.children].forEach(item => {
      item[this.options.clusterField] = data[this.options.clusterField];
      item[this.options.infoField] = data[this.options.infoField];
    })
    this.dots.selectAll('.' + this.clusterDetailClassPrefix + data[this.options.clusterField])
      .data(data[this.options.children])
      .enter()
      .append('circle')
      .attr('class', this.clusterDetailClassPrefix + data[this.options.clusterField])
      .attr('cx', (d: any) => d[this.options.axis.xAxisField])
      .attr('cy', (d: any) => d[this.options.axis.yAxisField])
      .attr('r', this.figureSize)
      .style('opacity', .5)
      .style('fill', (d: any) => this.colors.get(d[this.options.clusterField]));
    this.createTooltip(this.tooltipDetailClassPrefix + data[this.options.clusterField], this.clusterDetailClassPrefix + data[this.options.clusterField]);
    d3.selectAll('#' + this.clusterIdPrefix + data[this.options.clusterField]).style('visibility', 'hidden');
    this.ngOnChanges();
  }

  hideDetail(clusterId: any) {
    d3.selectAll('.' + this.clusterDetailClassPrefix + clusterId).remove();
    d3.selectAll('.' + this.tooltipDetailClassPrefix + clusterId).remove();
    d3.selectAll('#' + this.clusterIdPrefix + clusterId).style('visibility', 'visible');
    this.ngOnChanges();
  }
  getGrid(grid: any, x: any, y: any, k: any) {
    grid
      .attr('stroke', 'currentColor')
      .attr('stroke-opacity', 0.1)
      .call(g => g
        .selectAll('.x')
        .data(x.ticks(this.ticks))
        .join(
          enter => enter.append('line').attr('class', 'x').attr('y2', this.options.height),
          update => update,
          exit => exit.remove()
        )
        .attr('x1', d => 0.5 + x(d))
        .attr('x2', d => 0.5 + x(d)))
      .call(g => g
        .selectAll('.y')
        .data(y.ticks(this.ticks * k))
        .join(
          enter => enter.append('line').attr('class', 'y').attr('x2', this.options.width),
          update => update,
          exit => exit.remove()
        )
        .attr('y1', d => 0.5 + y(d))
        .attr('y2', d => 0.5 + y(d)));
  }

  crossPath(cx: number, cy: number, scale: number): any {
    return `M ${cx},${cy} l ${2 * scale} , ${2 * scale} M ${cx + 2 * scale},${cy} l ${-2 * scale}, ${2 * scale}`;
  }

  private zoomToClusters() {
    const clusterPredicate = item => this.options.zoomToClusterIds.includes(item.clusterId);
    const items = this.options.data.filter(clusterPredicate);
    if (items.length) {
      this.goToCenter(items);
    }
  }
}
