import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';
import { AuthConfig } from '../../auth0.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth0WebAuth = new auth0.WebAuth({
    clientID: AuthConfig.clientID,
    domain: AuthConfig.domain,
    responseType: 'token id_token',
    audience: (window as any).auth0ClientAudience,
    redirectUri: environment.production
      ? `${window.location.origin}${this.location.getBaseHref()}callback`
      : `${window.location.origin}/callback`,
    scope: 'openid profile email',
  });

  constructor(private router: Router, private location: LocationStrategy) {}

  public login() {
    this.auth0WebAuth.authorize();
  }

  public redirectToLogin(redirectUri?: string) {
    localStorage.setItem('redirectUri', redirectUri);
    this.login();
  }

  public isAuthenticated(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const diff = (expiresAt - new Date().getTime()) / 1000;
    return diff > 5;
  }

  public handleAuthentication(): void {
    this.auth0WebAuth.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
        const uri = localStorage.getItem('redirectUri');
        if (uri) {
          window.location.replace(uri);
        } else {
          this.router.navigate(['ontologymanagement']);
        }
      } else if (error) {
        this.login();
      }
    });
  }

  public checkSession(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.auth0WebAuth.checkSession({}, (error, authResult: auth0.Auth0DecodedHash) => {
        if (authResult && authResult.accessToken) {
          this.setSession(authResult);
          resolve(authResult.idToken);
        } else {
          this.login();
          reject(error);
        }
      });
    });
  }

  private setSession(authResult: auth0.Auth0DecodedHash) {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('sub', authResult.idTokenPayload.sub);
    localStorage.setItem('email', authResult.idTokenPayload.email);
    localStorage.setItem('expires_at', JSON.stringify((authResult.expiresIn / 60) * 1000 + new Date().getTime()));
    localStorage.setItem('id_token', authResult.idToken);
  }

  public logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('sub');
    localStorage.removeItem('email');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('id_token');
    localStorage.removeItem('redirectUri');
    this.login();
  }

  public getToken() {
    return new Promise<string>(resolve => {
      if (!this.isAuthenticated()) {
        this.checkSession().then(resolve, this.logout);
      } else {
        const token = localStorage.getItem('id_token');
        resolve(token);
        return;
      }
    });
  }

  isAllowed(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return expiresAt ? true : false;
  }
}
