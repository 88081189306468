<div class="container">
  <div (window:resize)="onResize()">
    <mat-card appearance="outlined">
      <mat-card-header id="size-buttons" *ngIf="!fixed">
        <mat-card-title>
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-button class="custom-size-button" *ngIf="selectedSize == size.Medium" (click)="onClick(size.Small)">
              <mat-icon [inline]="true">unfold_less</mat-icon>
            </button>
            <button mat-button class="custom-size-button" *ngIf="selectedSize == size.Small" (click)="onClick(size.Medium)">
              <mat-icon [inline]="true">unfold_more</mat-icon>
            </button>
        </div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayoutAlign="center stretch" #card>
        <div [ngClass]="selectedSize == size.Small ? 'small-size' : 'medium-size'" #viewport>
          <ng-content></ng-content>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
