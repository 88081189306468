import { Action } from '@ngrx/store';
import { Dictionary, LabelAnnotations } from 'src/app/core/models/Annotations';
import { Label } from 'src/app/core/models/Label';
import { Entity, FeedbackResponseTypeEnum } from 'src/app/core/models/models';
import { Offset } from 'src/app/core/models/Offset';
import {
  DateFilter,
  ParagraphSearchQuery,
  ParagraphSearchResult,
  ParagraphSearchResults,
  SearchTypeEnum,
  SelectedItem,
  SortByFieldEnum,
  SortOrderEnum,
} from './reducer';
import { ReviewView } from '../../core/models/ReviewView';
import { ClusterMetadataResponse } from '../../core/models/ClusterMetadata';
import { SortDirection } from '@angular/material/sort';
import { ChartActions } from '../../topic-coverage-discovery/charts/ChartOptions';

export enum ProvisionSearchActionType {
  ContainsChanged = '[Provision Search] Filter Text Changed',
  TagsChanged = '[Provision Search] Filter Tags Changed',
  UserLabelChanged = '[Provision Search] Paragraph with/without User Labels Changed',
  MachineLabelChanged = '[Provision Search] Paragraph with/without Machine Labels Changed',
  ParagraphIdsFetchSucceeded = '[Provision Search] Paragraph Ids Fetch Succeeded',
  RemoveDashboardFilter = '[Provision Search] Remove Dashboard Filter',
  PageChanged = '[Provision Search] Page changed',
  ClusterPageChanged = '[Provision Search] Cluster Page changed',
  ClusterSortOrderChanged = '[Provision Search] Cluster Sort Order Change',
  ClusterSelected = '[Provision Search] Cluster Selected',
  ClusterDeselected = '[Provision Search] Cluster Deselected',
  ClearSelectedClusters = '[Provision Search] Clear Selected Clusters',
  SearchClusterMetadata = '[Provision Search] Search Cluster Metadata',
  SearchByClusterId = '[Provision Search] Set Search Cluster Id',
  ClearSearchByClusterId = '[Provision Search] Clear Search Cluster Id',
  SearchClusterComplete = '[Provision Search] Load More Cluster Complete',
  ConfirmedTypeChanged = '[Provision Search] Confirmed Type Changed',
  IncludeHiddenParagraphsChanged = '[Provision Search] Include Hidden Paragraphs Changed',
  TagFilterTypeChanged = '[Provision Search] Tag Filter type Changed',
  CountChanged = '[Provision Search] Count Changed',
  SearchOntologyChanged = '[Provision Search] Ontology Changed',
  SearchComplete = '[Provision Search] Search Complete',
  SetAnnotations = '[Provision Search] Set Provision Annotations',
  SetActiveLabel = '[Provision Search] Set Active Label',
  LoadProvision = '[Provision Search] Perform Provision Search',
  AddParagraphAnnotation = '[Provision Search] Add Paragraph Annotation',
  RemoveUserLabelAnnotation = '[Provision Search] Remove User Applied Label Annotation',
  ConfirmParagraph = '[Provision Search] Confirm Paragraph',
  ConfirmParagraphSet = '[Provision Search]  Confirm Paragraph Sample Set',
  UnconfirmParagraph = '[Provision Search]  Un confirm Paragraph',
  HideParagraph = '[Provision Search]  Hide Paragraph',
  UnhideParagraph = '[Provision Search]  Unhide Paragraph',
  HideParagraphSet = '[Provision Search]  Hide Paragraph Sample Set',
  UnhideParagraphSet = '[Provision Search]  Unhide Paragraph Sample Set',
  BulkUnconfirmParagraph = '[Provision Search] Bulk Unconfirm Paragraph',
  ClassifyParagraphSet = '[Provision Search] Classify Paragraph Set',
  SetSelectedEntity = '[Provision Search] Set Selected Entity',
  RejectParagraph = '[Provision Search]  Reject Paragraph',
  CreatedOnChanged = '[Provision Search] Created On Filter Changed',
  ConfirmedOnChanged = '[Provision Search] Confirmed On Filter Changed',
  SortOrderChanged = '[Provision Search] Sort Order Changed',
  SortFieldChanged = '[Provision Search] Sort Field Changed',
  SortLabelChanged = '[Provision Search] Sort Label Changed',
  LoadMoreComplete = '[Provision Search] Load More Complete',
  ClearSearchFilters = '[Provision Search] Clear Search Filters',
  RemoveUserLabel = '[Provision Search] Remove User Label From Paragraph',
  CheckAllChanged = '[Provision Search] Check All Changed',
  CheckPageItemsChanged = '[Provision Search] Check Page Items Changed',
  ParagraphCheckChanged = '[Provision Search] Check change for paragraph',
  UpdateResult = '[Provision Search] Update Result',
  ResultChanged = '[Provision Search] Result Changed',
  ParagraphIdFilterChanged = '[Provision Search] Paragraph Id Filter Changed',
  BulkTagAdd = '[Provision Search] Bulk Add Tags to Paragraph',
  BulkTagRemove = '[Provision Search] Bulk Remove Tags from Paragraph',
  BulkRejectParagraphs = '[Provision Search] Bulk Reject Paragraphs',
  CheckParagraphUpdate = '[Provision Search] Update Checked Paragraph List',
  UnCheckParagraphUpdate = '[Provision Search] Update UnChecked Paragraph List',
  RefreshResults = '[Provision Search] Refresh Results',
  ResetSelection = '[Provision Search] Reset Selection',
  AddTagsToParagraph = '[Provision Search] Add Tags to Paragraph',
  RemoveTagsFromParagraph = '[Provision Search] Remove Tags from Paragraph',
  SetSearchState = '[Provision Search] Set Search Predicate',
  SearchTypeChanged = '[Provision Search] Search Type Changed',
  BulkFindConfoundingScores = '[Provision Search] Bulk Find Confounding Scores',
  UpdateConfoundingScore = '[Provision Search] Update Confounding Score',
  ToggleEasterEgg = '[Provision Search] Toggle Easter Egg',
  AddParagraphSuggestionLabels = '[Provision Search] Add label suggestions to paragraph',
  SetReviewView = '[Provision Search] Set the view that would be shown',
  SearchParagraphIds = '[Provision Search] Set Search Paragraph Ids',
  ToggleMetaData = '[Provision Search] Toggle Meta Data',
  ZoomToParagraphIds = ' [Provision Search] Zoom To Paragraph Ids',
  ZoomToClusterIds = ' [Provision Search] Zoom To Cluster Ids',
  ExpandFilterPanel = ' [Provision Search] Expand Filter Panel',
  ScatterPlotAction = '[Provision Search] Scatter Plot Action',
  RemoveUserLabelFromParagraphs = '[Provision Search] Remove User Label From Paragraphs',
  DocumentIdChanged = '[Provision Search] Document Id Changed'
}

export class ContainsChanged implements Action {
  public readonly type = ProvisionSearchActionType.ContainsChanged;
  constructor(public payload: string) { }
}

export class SetSearchState implements Action {
  public readonly type = ProvisionSearchActionType.SetSearchState;
  constructor(public payload: ParagraphSearchQuery) { }
}

export class TagsChanged implements Action {
  public readonly type = ProvisionSearchActionType.TagsChanged;
  constructor(public payload: SelectedItem[]) { }
}

export class UserLabelChanged implements Action {
  public readonly type = ProvisionSearchActionType.UserLabelChanged;
  constructor(public payload: SelectedItem[]) { }
}

export class MachineLabelChanged implements Action {
  public readonly type = ProvisionSearchActionType.MachineLabelChanged;
  constructor(public payload: SelectedItem[]) { }
}

export class ParagraphIdsFetchSucceeded implements Action {
  public readonly type = ProvisionSearchActionType.ParagraphIdsFetchSucceeded;
  constructor(public payload: number[]) { }
}

export class RemoveDashboardFilter implements Action {
  public readonly type = ProvisionSearchActionType.RemoveDashboardFilter;
  constructor() { }
}

export class CreatedOnChanged implements Action {
  public readonly type = ProvisionSearchActionType.CreatedOnChanged;
  constructor(public payload: DateFilter) { }
}

export class ConfirmedOnChanged implements Action {
  public readonly type = ProvisionSearchActionType.ConfirmedOnChanged;
  constructor(public payload: DateFilter) { }
}

export class SortFieldChanged implements Action {
  public readonly type = ProvisionSearchActionType.SortFieldChanged;
  constructor(public payload: SortByFieldEnum) { }
}

export class SortLabelChanged implements Action {
  public readonly type = ProvisionSearchActionType.SortLabelChanged;
  constructor(public payload: SelectedItem) { }
}

export class SortOrderChanged implements Action {
  public readonly type = ProvisionSearchActionType.SortOrderChanged;
  constructor(public payload: SortOrderEnum) { }
}

export class PageChanged implements Action {
  public readonly type = ProvisionSearchActionType.PageChanged;
  constructor(public payload: number) { }
}

export class CountChanged implements Action {
  public readonly type = ProvisionSearchActionType.CountChanged;
  constructor(public payload: boolean) { }
}

export class ConfirmedTypeChanged implements Action {
  public readonly type = ProvisionSearchActionType.ConfirmedTypeChanged;
  constructor(public payload: number) { }
}

export class IncludeHiddenParagraphsChanged implements Action {
  public readonly type = ProvisionSearchActionType.IncludeHiddenParagraphsChanged;
  constructor(public payload: boolean) { }
}

export class TagFilterTypeChanged implements Action {
  public readonly type = ProvisionSearchActionType.TagFilterTypeChanged;
  constructor(public payload: number) { }
}

export class DocumentIdChanged implements Action {
  public readonly type = ProvisionSearchActionType.DocumentIdChanged;
  constructor(public payload: number) { }
}

export class SearchOntologyChanged implements Action {
  public readonly type = ProvisionSearchActionType.SearchOntologyChanged;
  constructor(public payload: string) { }
}

export class SearchComplete implements Action {
  public readonly type = ProvisionSearchActionType.SearchComplete;
  constructor(public payload: ParagraphSearchResults) { }
}

export class LoadMoreComplete implements Action {
  public readonly type = ProvisionSearchActionType.LoadMoreComplete;
  constructor(public payload: ParagraphSearchResults) { }
}
export class SearchClusterComplete implements Action {
  public readonly type = ProvisionSearchActionType.SearchClusterComplete;
  constructor(public payload: ClusterMetadataResponse) { }
}
export class SearchByClusterIds implements Action {
  public readonly type = ProvisionSearchActionType.SearchByClusterId;
  constructor(public payload: number[]) { }
}
export class ClearSearchByClusterIds implements Action {
  public readonly type = ProvisionSearchActionType.ClearSearchByClusterId;
  constructor() { }
}
export class SearchClusterMetadata implements Action {
  public readonly type = ProvisionSearchActionType.SearchClusterMetadata;
  constructor() { }
}
export class ClusterPageChanged implements Action {
  public readonly type = ProvisionSearchActionType.ClusterPageChanged;
  constructor(public payload: number) { }
}
export class ClusterSortOrderChanged implements Action {
  public readonly type = ProvisionSearchActionType.ClusterSortOrderChanged;
  constructor(public payload: { active: string, direction: SortDirection }) { }
}
export class ClusterSelected implements Action {
  public readonly type = ProvisionSearchActionType.ClusterSelected;
  constructor(public payload: number) { }
}
export class ClusterDeselected implements Action {
  public readonly type = ProvisionSearchActionType.ClusterDeselected;
  constructor(public payload: number) { }
}
export class ClearSelectedCluster implements Action {
  public readonly type = ProvisionSearchActionType.ClearSelectedClusters;
  constructor() { }
}
export class SetAnnotations implements Action {
  public readonly type = ProvisionSearchActionType.SetAnnotations;
  constructor(public payload: Dictionary<LabelAnnotations[]>, public modelDeploymentId: number, public paragraphId: number) { }
}

export class UpdateConfoundingScore implements Action {
  public readonly type = ProvisionSearchActionType.UpdateConfoundingScore;
  constructor(public confoundingScore: number, public paragraphId: number) { }
}

export class SetActiveLabel implements Action {
  public readonly type = ProvisionSearchActionType.SetActiveLabel;
  constructor(public payload: Label) { }
}

export class LoadProvision implements Action {
  public readonly type = ProvisionSearchActionType.LoadProvision;
  constructor() { }
}

export class ClearSearchFilters implements Action {
  public readonly type = ProvisionSearchActionType.ClearSearchFilters;
  constructor() { }
}

export class AddParagraphAnnotation implements Action {
  public readonly type = ProvisionSearchActionType.AddParagraphAnnotation;
  constructor(public payload: Offset[]) { }
}

export class RemoveUserLabelAnnotation implements Action {
  public readonly type = ProvisionSearchActionType.RemoveUserLabelAnnotation;
  constructor(public paragraphId: number, public offset: Offset[]) { }
}

export class UnconfirmParagraph implements Action {
  public readonly type = ProvisionSearchActionType.UnconfirmParagraph;
  constructor(public payload: number) { }
}

export class SetSelectedEntity implements Action {
  public readonly type = ProvisionSearchActionType.SetSelectedEntity;
  constructor(public payload: Entity) { }
}

export class ConfirmParagraph implements Action {
  public readonly type = ProvisionSearchActionType.ConfirmParagraph;
  constructor(public payload: number, public confirmedOn: Date, public confirmedByName: string) { }
}

export class ConfirmParagraphSet implements Action {
  public readonly type = ProvisionSearchActionType.ConfirmParagraphSet;
}

export class HideParagraph implements Action {
  public readonly type = ProvisionSearchActionType.HideParagraph;
  constructor(public payload: number) { }
}

export class UnhideParagraph implements Action {
  public readonly type = ProvisionSearchActionType.UnhideParagraph;
  constructor(public payload: number) { }
}

export class HideParagraphSet implements Action {
  public readonly type = ProvisionSearchActionType.HideParagraphSet;
}

export class UnhideParagraphSet implements Action {
  public readonly type = ProvisionSearchActionType.UnhideParagraphSet;
}

export class BulkUnconfirmParagraph implements Action {
  public readonly type = ProvisionSearchActionType.BulkUnconfirmParagraph;
}

export class ClassifyParagraphSet implements Action {
  public readonly type = ProvisionSearchActionType.ClassifyParagraphSet;
  constructor(public modelDeploymentId: number) { }
}

export class RejectParagraph implements Action {
  public readonly type = ProvisionSearchActionType.RejectParagraph;
  constructor(
    public provisionId: number,
    public rejectionReason: string,
    public responseType: FeedbackResponseTypeEnum,
  ) { }
}

export class RemoveUserLabel implements Action {
  public readonly type = ProvisionSearchActionType.RemoveUserLabel;
}

export class UpdateResult implements Action {
  public readonly type = ProvisionSearchActionType.UpdateResult;
  constructor(public paragraph: ParagraphSearchResult) { }
}

export class CheckAllChanged implements Action {
  public readonly type = ProvisionSearchActionType.CheckAllChanged;
  constructor(public payload: boolean) { }
}

export class CheckPageItemsChanged implements Action {
  public readonly type = ProvisionSearchActionType.CheckPageItemsChanged;
  constructor(public status: boolean, public paragraphIds: number[]) { }
}

export class ParagraphCheckChanged implements Action {
  public readonly type = ProvisionSearchActionType.ParagraphCheckChanged;
  constructor(public paragraphId: number, public isChecked: boolean) { }
}

export class ResultChanged implements Action {
  public readonly type = ProvisionSearchActionType.ResultChanged;
  constructor(public paragraphId: number) { }
}

export class ParagraphIdFilterChanged implements Action {
  public readonly type = ProvisionSearchActionType.ParagraphIdFilterChanged;
  constructor(public payload: number) { }
}

export class BulkTagAdd implements Action {
  public readonly type = ProvisionSearchActionType.BulkTagAdd;
  constructor(public tags: string[]) { }
}

export class BulkTagRemove implements Action {
  public readonly type = ProvisionSearchActionType.BulkTagRemove;
  constructor(public tags: string[]) { }
}

export class BulkFindConfoundingScores implements Action {
  public readonly type = ProvisionSearchActionType.BulkFindConfoundingScores;
}

export class BulkRejectParagraphs implements Action {
  public readonly type = ProvisionSearchActionType.BulkRejectParagraphs;
  constructor(public comment: string, public responseType: FeedbackResponseTypeEnum) { }
}

export class CheckParagraphUpdate implements Action {
  public readonly type = ProvisionSearchActionType.CheckParagraphUpdate;
  constructor(public payload: number[]) { }
}

export class UnCheckParagraphUpdate implements Action {
  public readonly type = ProvisionSearchActionType.UnCheckParagraphUpdate;
  constructor(public payload: number[]) { }
}

export class RefreshResults implements Action {
  public readonly type = ProvisionSearchActionType.RefreshResults;
  constructor() { }
}

export class AddTagsToParagraph implements Action {
  public readonly type = ProvisionSearchActionType.AddTagsToParagraph;
  constructor(public paragraphId: number, public tags: string[]) { }
}

export class RemoveTagsFromParagraph implements Action {
  public readonly type = ProvisionSearchActionType.RemoveTagsFromParagraph;
  constructor(public paragraphId: number, public tags: string[]) { }
}

export class ResetSelection implements Action {
  public readonly type = ProvisionSearchActionType.ResetSelection;
  constructor() { }
}

export class SearchTypeChanged implements Action {
  public readonly type = ProvisionSearchActionType.SearchTypeChanged;
  constructor(public payload: SearchTypeEnum) { }
}

export class ToggleEasterEgg implements Action {
  public readonly type = ProvisionSearchActionType.ToggleEasterEgg;
}

export class SetReviewView implements Action {
  public readonly type = ProvisionSearchActionType.SetReviewView;
  constructor(public payload: ReviewView) { }
}

export class SearchParagraphIds implements Action {
  public readonly type = ProvisionSearchActionType.SearchParagraphIds;
  constructor(public ids: number[], public pagination?: boolean) { }
}

export class ToggleMetaData implements Action {
  public readonly type = ProvisionSearchActionType.ToggleMetaData;
  constructor() { }
}

export class AddParagraphSuggestionLabels implements Action {
  public readonly type = ProvisionSearchActionType.AddParagraphSuggestionLabels;
  constructor(public labels: Label[], public paragraphId: number) { }
}

export class ZoomToParagraphIds implements Action {
  public readonly type = ProvisionSearchActionType.ZoomToParagraphIds;
  constructor(public payload: number[]) { }
}
export class ZoomToClusterIds implements Action {
  public readonly type = ProvisionSearchActionType.ZoomToClusterIds;
  constructor(public payload: number[]) { }
}
export class ExpandScatterPlotPanel implements Action {
  public readonly type = ProvisionSearchActionType.ExpandFilterPanel;
  constructor(public payload: boolean) { }
}

export class ScatterPlotAction implements Action {
  public readonly type = ProvisionSearchActionType.ScatterPlotAction;
  constructor(public payload: ChartActions[]) { }
}


export class RemoveUserLabelFromParagraphs implements Action {
  public readonly type = ProvisionSearchActionType.RemoveUserLabelFromParagraphs;
  constructor(public payload: number) { }
}

export type ProvisionSearchActions =
  | ContainsChanged
  | TagsChanged
  | UserLabelChanged
  | MachineLabelChanged
  | ParagraphIdsFetchSucceeded
  | RemoveDashboardFilter
  | PageChanged
  | CountChanged
  | ConfirmedTypeChanged
  | TagFilterTypeChanged
  | SearchOntologyChanged
  | SearchComplete
  | SetAnnotations
  | SetActiveLabel
  | LoadProvision
  | AddParagraphAnnotation
  | RemoveUserLabelAnnotation
  | ConfirmParagraph
  | ConfirmParagraphSet
  | UnconfirmParagraph
  | HideParagraph
  | HideParagraphSet
  | UnhideParagraph
  | UnhideParagraphSet
  | ClassifyParagraphSet
  | SetSelectedEntity
  | RejectParagraph
  | ConfirmedOnChanged
  | CreatedOnChanged
  | SortOrderChanged
  | SortFieldChanged
  | SortLabelChanged
  | LoadMoreComplete
  | ClearSearchFilters
  | RemoveUserLabel
  | CheckAllChanged
  | CheckPageItemsChanged
  | ParagraphCheckChanged
  | ResultChanged
  | UpdateResult
  | ParagraphIdFilterChanged
  | BulkTagAdd
  | BulkTagRemove
  | BulkUnconfirmParagraph
  | CheckParagraphUpdate
  | UnCheckParagraphUpdate
  | RefreshResults
  | AddTagsToParagraph
  | RemoveTagsFromParagraph
  | ResetSelection
  | SetSearchState
  | SearchTypeChanged
  | UpdateConfoundingScore
  | ToggleEasterEgg
  | SetReviewView
  | SearchParagraphIds
  | ToggleMetaData
  | AddParagraphSuggestionLabels
  | SearchByClusterIds
  | ClearSearchByClusterIds
  | SearchClusterComplete
  | SearchClusterMetadata
  | ClusterPageChanged
  | ClusterSortOrderChanged
  | ClusterSelected
  | ClusterDeselected
  | ClearSelectedCluster
  | IncludeHiddenParagraphsChanged
  | ZoomToParagraphIds
  | ExpandScatterPlotPanel
  | ZoomToClusterIds
  | ScatterPlotAction
  | RemoveUserLabelFromParagraphs
  | DocumentIdChanged;
