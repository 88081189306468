<div>
  <div class="dialog-title">
    <h1 mat-dialog-title>Upload Documents</h1>
    <button mat-button mat-dialog-close>Close
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>Max file size is 1 Gigabyte. Supported file type is .pdf</div>
  <div mat-dialog-content>
    <div liFileDrop (filesDropped)="onFilesSelected($event)">
      <div class="add-area">
        <div class="add-area-content">
          <p>Select “Add file” or drag files into this space.</p>
          <div class="button-row">
            <button mat-raised-button class="add-button"
                    (click)="fileInput.click()"
                    [disabled]="uploads.length > maxQueueLength || uploadIsInProgress"
            >
              <mat-icon>add</mat-icon>
              Add files
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="uploads.length > 0">
        <mat-list>
          <mat-list-item *ngFor="let upload of uploads" class="list-item">
            <div class="file-status-column">
              <div class="file-status-row">
                <div><p class="file-status-text">{{ upload.file.name }}</p></div>&nbsp;
                <div><p class="file-status-text" [ngClass]="getClass(upload)">{{ upload.state }}</p></div>
              </div>
              <div class="file-status-row progress-bar">
                <mat-progress-bar mode="determinate" [value]="getValue(upload)"></mat-progress-bar>
                <button mat-icon-button aria-label="Remove" class="status-icon"
                        (click)="onUploadRemoveClicked(upload, $event)"
                        [disabled]="upload.state === uploadStateEnum.InProgress"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div *ngIf="upload.state === UploadState.InProgress" class="file-status-row-action">
                <mat-icon class="status-icon in-progress">info</mat-icon>
                <div class="file-status-text in-progress">Document uploading...</div>
              </div>
              <div *ngIf="upload.state === UploadState.Completed" class="file-status-row-action">
                <mat-icon class="status-icon completed">check_circle_outline</mat-icon>
                <div class="file-status-text completed">Successfully uploaded</div>
              </div>
              <div *ngIf="upload.state === UploadState.Error" class="file-status-row-action">
                <mat-icon class="status-icon error">cancel</mat-icon>
                <div class="file-status-text error">Upload error</div>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </div>
    <ali-tag-input [enforceSelection]="false" [selectedItems]="selectedTags" placeholder="Add Tags.."
                   [dataSource]="tags$ | async" (itemAdded)="onTagsUpdated($event)"
                   (itemRemoved)="onTagsUpdated($event)">
    </ali-tag-input>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onImportClicked()"
            [disabled]="uploadIsInProgress || !uploadsAreQueued"
    >
      Import Documents
    </button>
  </div>
  <input hidden multiple type="file" #fileInput
         [accept]="allowedFileType"
         (change)="onSelectedFilesChanged($event)"
  />
</div>
