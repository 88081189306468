import { Action } from '@ngrx/store';
import { Entity, Notification } from '../core/models/models';
import { FavouriteLabel, Label, SharedLabel } from '../core/models/Label';
import { Ontology } from '../core/models/Ontology';
import { User } from '../core/models/User';
import { SecurityLabelAuthorization } from '../core/models/security-label-authorization';
import { RemoveUserLabelFromParagraphs } from '../paragraph-review/store/actions';

export enum RootStateActionType {
  Init = '[Root State] Init',
  OntologyChanged = '[Root State] Ontology Changed',
  SetLabels = '[Root State] Set Labels',
  SetTags = '[Root State] Set Tags',
  DeleteTag = '[Root State] Delete Tags,',
  AddTags = '[Root State] Add New Tags',
  SetOntologies = '[Root State] Set Ontologies',
  GetLabels = '[Root State] Get Labels',
  AddLabel = '[Root State] Add New Label',
  RemoveLabel = '[Root State] Remove Label',
  UpdateLabel = '[Root State] Update Label',
  GetEntities = '[Root State] Get Entities',
  SetEntities = '[Root State] Set Entities',
  AddOntology = '[Root State] Add Ontology',
  UpdateOntology = '[Root State] Update Ontology',
  SharedLabelsUpdated = '[Root State] Shared Labels Updated',
  RemoveSharedLabels = '[Root State] Shared Labels Removed',
  RenameSharedLabel = '[Root State] Rename Shared Labels',
  SetAllUsersSimplified = '[Root State] Set All Users Simplified',
  SetNotifications = '[Root State] Set Notifications',
  SetNotificationCount = '[Root State] Set Notification Count',
  SetCurrentUser = '[Root State] Set Current Users',
  AddFavouriteLabels = '[Root State] Add Label to Favorites',
  RemoveFavouriteLabel = '[Root State] Remove Label from Favorites',
  SignalRInitStart = '[Root State] SignalR Init Start',
  SetSignalRInit = '[Root State] Set SignalR Init',
  SetSecurityLabelAuthorizations = '[Root state] Set Security Label Authorization'
}

export class Init implements Action {
  public readonly type = RootStateActionType.Init;
}

export class OntologyChanged implements Action {
  public readonly type = RootStateActionType.OntologyChanged;
  constructor(public payload: string) { }
}

export class SetLabels implements Action {
  public readonly type = RootStateActionType.SetLabels;
  constructor(public payload: Label[]) { }
}

export class SetTags implements Action {
  public readonly type = RootStateActionType.SetTags;
  constructor(public payload: string[]) { }
}

export class DeleteTag implements Action {
  public readonly type = RootStateActionType.DeleteTag;
  constructor(public payload: string) { }
}

export class AddTags implements Action {
  public readonly type = RootStateActionType.AddTags;
  constructor(public payload: string[]) { }
}

export class SetAllUsersSimplified implements Action {
  public readonly type = RootStateActionType.SetAllUsersSimplified;
  constructor(public payload: User[]) { }
}

export class SetCurrentUser implements Action {
  public readonly type = RootStateActionType.SetCurrentUser;
  constructor(public payload: User) { }
}

export class SetOntologies implements Action {
  public readonly type = RootStateActionType.SetOntologies;
  constructor(public payload: Ontology[]) { }
}

export class SetEntities implements Action {
  public readonly type = RootStateActionType.SetEntities;
  constructor(public payload: Entity[]) { }
}

export class GetEntities implements Action {
  public readonly type = RootStateActionType.GetEntities;
}

export class GetLabels implements Action {
  public readonly type = RootStateActionType.GetLabels;
}

export class AddLabel implements Action {
  public readonly type = RootStateActionType.AddLabel;
  constructor(public payload: Label) { }
}

export class RemoveLabel implements Action {
  public readonly type = RootStateActionType.RemoveLabel;
  constructor(public payload: number) { }
}

export class UpdateLabel implements Action {
  public readonly type = RootStateActionType.UpdateLabel;
  constructor(public payload: Label) { }
}

export class AddOntology implements Action {
  public readonly type = RootStateActionType.AddOntology;
  constructor(public payload: Ontology) { }
}

export class UpdateOntology implements Action {
  public readonly type = RootStateActionType.UpdateOntology;
  constructor(public payload: Ontology) { }
}

export class SharedLabelsUpdated implements Action {
  public readonly type = RootStateActionType.SharedLabelsUpdated;
  constructor(public destinationLabelId: number, public sharedLabels: SharedLabel[]) { }
}

export class RemoveSharedLabels implements Action {
  public readonly type = RootStateActionType.RemoveSharedLabels;
  constructor(public payload: number[]) { }
}

export class RenameSharedLabel implements Action {
  public readonly type = RootStateActionType.RenameSharedLabel;
  constructor(public id: number, public newName: string) { }
}

export class SetNotifications implements Action {
  public readonly type = RootStateActionType.SetNotifications;
  constructor(public payload: Notification[]) { }
}

export class SetNotificationCount implements Action {
  public readonly type = RootStateActionType.SetNotificationCount;
  constructor(public payload: number) { }
}

export class AddFavouriteLabels implements Action {
  public readonly type = RootStateActionType.AddFavouriteLabels;
  constructor(public favouriteLabel: FavouriteLabel) { }
}

export class RemoveFavouriteLabel implements Action {
  public readonly type = RootStateActionType.RemoveFavouriteLabel;
  constructor(public favouriteLabel: FavouriteLabel) { }
}

export class SignalRInitStart implements Action {
  public readonly type = RootStateActionType.SignalRInitStart;
}

export class SetSignalRInit implements Action {
  public readonly type = RootStateActionType.SetSignalRInit;
  constructor(public init: boolean) { }
}

export class SetSecurityLabelAuthorizations implements Action {
  public readonly type = RootStateActionType.SetSecurityLabelAuthorizations;
  constructor(public authorizations: Array<SecurityLabelAuthorization>) { }
}

export type RootStateActions =
  | Init
  | OntologyChanged
  | SetLabels
  | SetTags
  | DeleteTag
  | AddTags
  | SetOntologies
  | GetLabels
  | AddLabel
  | RemoveLabel
  | UpdateLabel
  | GetEntities
  | SetEntities
  | AddOntology
  | UpdateOntology
  | SharedLabelsUpdated
  | RemoveSharedLabels
  | RenameSharedLabel
  | SetAllUsersSimplified
  | SetNotifications
  | SetNotificationCount
  | SetCurrentUser
  | AddFavouriteLabels
  | RemoveFavouriteLabel
  | SignalRInitStart
  | SetSignalRInit
  | RemoveUserLabelFromParagraphs
  | SetSecurityLabelAuthorizations;
