import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootState } from './state';

export const selectRootState = createFeatureSelector<RootState>('rootState');

export const selectRootStateOntology = createSelector(
  selectRootState,
  (state: RootState) => state.ontology
);

export const selectRootStateOntologies = createSelector(
  selectRootState,
  (state: RootState) => state.ontologies
);

export const selectRootStateCurrentUser = createSelector(
  selectRootState,
  (state: RootState) => state.currentUser
);

export const selectRootStateTags = createSelector(
  selectRootState,
  (state: RootState) => state.tags
);

export const selectSecurityLabelAuthsCurrentOntology = createSelector(
  selectRootState,
  (state: RootState) => state.securityLabelAuthorizationsWithIds[state.ontology]
);

export const selectRootStateEntities = createSelector(
  selectRootState,
  (state: RootState) => state.entities
);

export const selectRootStateLabels = createSelector(
  selectRootState,
  (state: RootState) => state.labels
);

export const selectAllUsersSimplified = createSelector(
  selectRootState,
  (state: RootState) => state.allUsersSimplified
);
