import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { LoadProvision, ParagraphIdFilterChanged, SetSearchState } from 'src/app/paragraph-review/store/actions';
import { OntologyChanged, SetSecurityLabelAuthorizations } from "src/app/root-store/actions";
import { AppState } from "src/app/root-store/state";
import { AuthenticationService } from "../authentication/authentication.service";
import { LiAccessService } from "../services/li-access.service";
import { ProvisionService } from "../services/provision.service";
import { SecurityLabelAuthorizationsService } from "../services/security-label-authorizations.service";
import { SessionStorageService } from "../services/session-storage.service";


@Injectable()
export class AuthenticationGuard  {
  selectedOntology: string;
  constructor(
    private authService: AuthenticationService,
    private liAccessService: LiAccessService,
    private store: Store<AppState>,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private securityLabelAuthorizationService: SecurityLabelAuthorizationsService,
    private provisionService: ProvisionService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {

    return combineLatest([
      this.authService.isDoneLoading$,
      this.securityLabelAuthorizationService.getAll(),
      this.authService.isAuthenticated$,
      this.provisionService.loadSearch(route.queryParams.search),
      of(route.queryParams.paragraphId),
      of(route.queryParams.ontology),
    ]).pipe(
      map(([_, auth, isAuthenticated, searchResult, paragraphId, ontology]) => {
        if (isAuthenticated && auth && auth != null && auth != undefined) {
          if (!this.liAccessService.isThoughttraceUser()) {
            this.store.dispatch(new OntologyChanged('CT'));
          }
          const ontologies: Array<string> = auth.securityLabels.map(s => s.name);
          this.selectedOntology = this.sessionStorageService.getItem('ontology');

          if(searchResult) {
            this.store.dispatch(new OntologyChanged(searchResult.ontology));
            this.store.dispatch(new SetSearchState(searchResult));
            return true;
          }
          if (ontology && paragraphId) {
            this.store.dispatch(new OntologyChanged(ontology));
            this.store.dispatch(new ParagraphIdFilterChanged(paragraphId));
            this.store.dispatch(new LoadProvision());
            return true
          }
          if (!this.selectedOntology || this.selectedOntology.length === 0 || !ontologies.includes(this.selectedOntology)) {
            this.router.navigate(['/home'], { queryParamsHandling: 'preserve' });
            return false;
          }
          this.store.dispatch(new SetSecurityLabelAuthorizations(auth.securityLabels));
          return true;
        } else {
          this.authService.login(state.url);
          return false;
        }
     })
    );
  }
}
