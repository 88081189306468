<div mat-dialog-content>
  <div>
    <h2 mat-dialog-title>Select Model</h2>
  </div>
  <mat-card appearance="outlined" style="background:#f7f7f7;margin-bottom:5px;">
      <div *ngIf="isValidModel">
        <b>Note: </b> Select the model that you want to use to classify. Only the last published models are shown.
      </div>
      <div *ngIf="!isValidModel">
        <b>Note:</b> There are no available models to classify.
      </div>
  </mat-card>
</div>
<div mat-dialog-actions fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
  <div [formGroup]="selectModel">
    <div *ngIf="isValidModel">
      <mat-form-field>
        <mat-label>Model</mat-label>
        <mat-select formControlName="modelDeploymentId">
          <mat-option *ngFor="let modelDeployment of data" [value]="modelDeployment.id">
            <ng-container *ngIf="modelDeployment.type === 'Standard'">
              {{deploymentEnvironment}} - {{modelDeployment.modelId}} - {{modelDeployment.endpoint}}
            </ng-container>
            <ng-container *ngIf="modelDeployment.type === 'Custom'">
              {{modelDeployment.workspace}} - {{modelDeployment.modelId}}
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <button mat-raised-button color="primary" [disabled]="selectModel.status == 'INVALID'"
      (click)="classify()">Classify Paragraph(s)</button>
      <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
