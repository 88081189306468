export interface DocumentUploadState extends Record<string, DocumentUploadQueue> { }

export interface DocumentUploadQueue {
  // Queue State
  uploads: Upload[];
  queueProcessingState: QueueProcessingState;
  // Options
  ontology: string;
  tags: string[];
}

export class Upload {
  constructor(
    public readonly file: File,
    public readonly state: UploadState = UploadState.Queued,
    public readonly error: any = null,
  ) { }
}

export enum UploadState {
  Queued = 'Queued',
  InProgress = 'In Progress',
  Completed = 'Completed',
  Error = 'Error',
}

export enum QueueProcessingState {
  Full = 'Full',
  Idle = 'Idle',
}

export enum RejectionReason {
  Size = 'Size',
  FileType = 'FileType',
  QueueLength = 'QueueLength'
}

export const initialDocumentUploadState: DocumentUploadState = {}
export const initialUploadQueueState: DocumentUploadQueue = {
  ontology: null,
  queueProcessingState: QueueProcessingState.Idle,
  tags: [],
  uploads: []
}

export interface UploadQueueStatus {
  ontology: string;
  uploadIsInProgress: boolean;
  uploads: {
    queued: Array<Upload>;
  };
  queueProcessingState: QueueProcessingState;
  tags: string[];
}
