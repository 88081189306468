import { Action } from '@ngrx/store';
import { ConflictFilters, ConflictGroup, ConflictReport } from 'src/app/core/models/Conflict';
import { AnalyzeTextResult, ModelDetails } from 'src/app/core/models/models';

export enum ModelReviewActionType {
  SetResults = '[Model Review] Set Results',
  AddResult = '[Model Review] Add Result',
  RemoveResult = '[Model Review] Remove Result',
  GetConflictingReportResults = '[Model Review] Get Conflicting Report Results',
  ChangeModelSelection = '[Model Review] Change ModelSelection',
  AddTagsToParagraph = '[Model Review] Add Tags To Paragraph',
  RemoveTagFromParagraph = '[Model Review] Remove Tag From Paragraph',
  BulkTagAdd = '[Model Review] Bulk Add Tags',
  BulkTagRemove = '[Model Review] Bulk Remove Tags',
  SetConflictGroup = '[Model Review] Set Conflict Group',
  SetConflictFilters = '[Model Review] Set Conflict Filters',
  SetSortedParentIds = '[Model Review] Set Sorted Parent Ids',
  ConflictReportPageChanged = '[Model Review] Conflict Report Page Changed',
  CurrentPageSelected = '[Model Review] Current Page Selected',
  CurrentPageDeselected = '[Model Review] Current Page Deselected',
  ConflictParagraphsSelected = '[Model Review] Conflict Paragraphs Selected',
  ConflictParagraphsDeselected = '[Model Review] Conflict Paragraphs Deselected',
  SelectAllConflictResults = '[Model Review] Select All Conflict Results',
  ClearAllSelectedConflictResults = '[Model Review] Clear All Selected Conflict Results',
}

export class SetResults implements Action {
  public readonly type = ModelReviewActionType.SetResults;
  constructor(public payload: AnalyzeTextResult[]) { }
}

export class AddResult implements Action {
  public readonly type = ModelReviewActionType.AddResult;
  constructor(public payload: AnalyzeTextResult) { }
}

export class RemoveResult implements Action {
  public readonly type = ModelReviewActionType.RemoveResult;
  constructor(public payload: string) { }
}


export class GetConflictingReportResults implements Action {
  public readonly type = ModelReviewActionType.GetConflictingReportResults;
  constructor(public payload: ConflictReport) { }
}


export class ChangeModelSelection implements Action {
  public readonly type = ModelReviewActionType.ChangeModelSelection;
  constructor(public payload: ModelDetails) { }
}

export class BulkTagAdd implements Action {
  public readonly type = ModelReviewActionType.BulkTagAdd;
  constructor(public ontology: string, public paragraphIds: number[], public tags: string[]) { }
}

export class BulkTagRemove implements Action {
  public readonly type = ModelReviewActionType.BulkTagRemove;
  constructor(public ontology: string, public paragraphIds: number[], public tags: string[]) { }
}

export class AddTagsToParagraph implements Action {
  public readonly type = ModelReviewActionType.AddTagsToParagraph;
  constructor(public paragraphId: number, public tags: string[], public update?: boolean) { }
}

export class RemoveTagFromParagraph implements Action {
  public readonly type = ModelReviewActionType.RemoveTagFromParagraph;
  constructor(public paragraphId: number, public tags: string[], public update?: boolean) { }
}

export class SetConflictGroup implements Action {
  public readonly type = ModelReviewActionType.SetConflictGroup;
  constructor(public payload?: ConflictGroup) { }
}

export class SetConflictFilters implements Action {
  public readonly type = ModelReviewActionType.SetConflictFilters;
  constructor(public payload?: ConflictFilters) { }
}

export class SetSortedParentIds implements Action {
  public readonly type = ModelReviewActionType.SetSortedParentIds;
  constructor(public payload?: number[]) { }
}

export class ConflictReportPageChanged implements Action {
  public readonly type = ModelReviewActionType.ConflictReportPageChanged;
  constructor(public payload: number) { }
}

export class CurrentPageSelected implements Action {
  public readonly type = ModelReviewActionType.CurrentPageSelected;
  constructor(public conflictParagraphIds: Array<number>) { }
}

export class CurrentPageDeSelected implements Action {
  public readonly type = ModelReviewActionType.CurrentPageDeselected;
  constructor(public conflictParagraphIds: Array<number>) { }
}

export class ConflictParagraphsSelected implements Action {
  public readonly type = ModelReviewActionType.ConflictParagraphsSelected;
  constructor(public conflictParagraphIds: Array<string>) { }
}

export class ConflictParagraphsDeselected implements Action {
  public readonly type = ModelReviewActionType.ConflictParagraphsDeselected;
  constructor(public conflictParagraphIds: Array<string>) { }
}

export class SelectAllConflictResults implements Action {
  public readonly type = ModelReviewActionType.SelectAllConflictResults;
  constructor() { }
}

export class ClearAllSelectedConflictResults implements Action {
  public readonly type = ModelReviewActionType.ClearAllSelectedConflictResults;
  constructor() { }
}

export type ModelReviewActions =
  | SetResults
  | AddResult
  | RemoveResult
  | GetConflictingReportResults
  | BulkTagAdd
  | BulkTagRemove
  | AddTagsToParagraph
  | SetConflictGroup
  | SetConflictFilters
  | SetSortedParentIds
  | ChangeModelSelection
  | RemoveTagFromParagraph
  | ConflictReportPageChanged
  | CurrentPageSelected
  | CurrentPageDeSelected
  | ConflictParagraphsSelected
  | ConflictParagraphsDeselected
  | SelectAllConflictResults
  | ClearAllSelectedConflictResults;
