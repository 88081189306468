import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OntologyInfo } from '../models/Ontology';

@Injectable({ providedIn: 'root' })
export class OntologyService {
  constructor(private http: HttpClient) { }

  getAllWithInfo() {
    return this.http.get<OntologyInfo[]>('api/ontology/allWithInfo');
  }

  create(dto: any) {
    return this.http.post('api/ontology', dto);
  }

  copy(dto: any) {
    return this.http.post('api/ontology/copy', dto);
  }

  update(ontologyId: string, ontologyPatches: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.patch<any>(`api/ontology/${ontologyId}`, ontologyPatches, { headers });
  }

  getOntologyStatistics(ontologyId: string): Observable<OntologyInfo> {
    return this.http.get<OntologyInfo>(`api/ontology/${ontologyId}/statistics`);
  }

}
