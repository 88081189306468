import { RootState } from 'src/app/root-store/state';
import { arrayToMap } from '../core/functions/array-to-map';
import { SecurityLabelAuthorization } from '../core/models/security-label-authorization';
import { RootStateActions, RootStateActionType } from './actions';
export const initialRootState: RootState = {
  labels: [],
  ontology: '',
  tags: [],
  ontologies: [],
  entities: [],
  allUsersSimplified: [],
  notifications: [],
  notificationCount: null,
  currentUser: null,
  signalRInitialized: false,
  securityLabelAuthorizationsWithIds: null,
};



export enum LoadDirectionEnum {
  next = 0,
  previous = 1,
}

export const rootStateReducer = (state = initialRootState, action: RootStateActions): RootState => {
  switch (action.type) {
    case RootStateActionType.Init: {
      return {
        ...state,
      };
    }

    case RootStateActionType.OntologyChanged: {
      return {
        ...state,
        ontology: action.payload,
      };
    }

    case RootStateActionType.SetLabels: {
      return {
        ...state,
        labels: action.payload,
      };
    }

    case RootStateActionType.SetTags: {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case RootStateActionType.SetAllUsersSimplified: {
      return {
        ...state,
        allUsersSimplified: action.payload,
      };
    }

    case RootStateActionType.SetCurrentUser: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case RootStateActionType.SetOntologies: {
      return {
        ...state,
        ontologies: action.payload,
      };
    }

    case RootStateActionType.AddTags: {
      return {
        ...state,
        tags: state.tags.concat(action.payload.filter(x => !state.tags.includes(x))),
      };
    }

    case RootStateActionType.DeleteTag: {
      return {
        ...state,
        tags: [...state.tags.filter(t => t !== action.payload)],
      };
    }

    case RootStateActionType.AddLabel: {
      const newLabels = [...state.labels.filter(s => s.id !== action.payload.id), action.payload];
      return {
        ...state,
        labels: [...newLabels],
      };
    }

    case RootStateActionType.RemoveLabel: {
      return {
        ...state,
        labels: [...state.labels.filter(s => s.id !== action.payload)],
      };
    }

    case RootStateActionType.UpdateLabel: {
      return {
        ...state,
        labels: [...state.labels.filter(s => s.id !== action.payload.id), action.payload],
      };
    }

    case RootStateActionType.SetEntities: {
      return {
        ...state,
        entities: action.payload,
      };
    }

    case RootStateActionType.AddOntology: {
      return {
        ...state,
        ontologies: [...state.ontologies, action.payload],
      };
    }

    case RootStateActionType.UpdateOntology: {
      return {
        ...state,
        ontologies: [...state.ontologies.filter(o => o.id !== action.payload.id), action.payload],
      };
    }

    case RootStateActionType.SharedLabelsUpdated: {
      const currentLabels = [...state.labels];
      const changedItem = currentLabels.findIndex(s => s.id === action.destinationLabelId);
      if (changedItem === -1) {
        return {
          ...state,
        };
      }
      const newLabel = {...currentLabels[changedItem]};
      newLabel.sharedLabels = action.sharedLabels;
      currentLabels[changedItem] = newLabel
      return {
        ...state,
        labels: [...currentLabels],
      };
    }

    case RootStateActionType.RemoveSharedLabels: {
      const currentLabels = [...state.labels];
      currentLabels
        .filter(c => c.sharedLabels && c.sharedLabels.length > 0)
        .forEach(label => {
          if (label.sharedLabels.some(l => action.payload.includes(l.id))) {
            label.sharedLabels = label.sharedLabels.filter(l => !action.payload.includes(l.id));
          }
        });
      return {
        ...state,
        labels: [...currentLabels],
      };
    }

    case RootStateActionType.RenameSharedLabel: {
      const currentLabels = [...state.labels];
      currentLabels
        .filter(c => c.sharedLabels.length > 0)
        .forEach(label => {
          if (label.sharedLabels.some(l => l.id === action.id)) {
            const toUpdate = label.sharedLabels.find(l => l.id === action.id);
            toUpdate.name = action.newName;
          }
        });
      return {
        ...state,
        labels: [...currentLabels],
      };
    }

    case RootStateActionType.SetNotifications: {
      return {
        ...state,
        notifications: action.payload,
      };
    }

    case RootStateActionType.SetNotificationCount: {
      return {
        ...state,
        notificationCount: action.payload,
      };
    }

    case RootStateActionType.AddFavouriteLabels: {
      const { currentUser, labels } = state;

      if (currentUser.liUserId !== action.favouriteLabel.createdById) {
        return { ...state };
      }

      const removedLabel = labels.find(label => label.id === action.favouriteLabel.labelId);
      if (!removedLabel) {
        return { ...state };
      }

      const newLabels = labels.map(label => {
        if (label === removedLabel) {
          return {
            ...label,
            isPinned: true,
            favouriteId: action.favouriteLabel.id
          };
        }
        return label;
      });

      return {
        ...state,
        labels: newLabels,
      };
    }

    case RootStateActionType.RemoveFavouriteLabel: {
        const { currentUser, labels } = state;

        if (currentUser.liUserId !== action.favouriteLabel.createdById) {
          return { ... state };
        }

        const removedLabel = labels.find(label => label.favouriteId === action.favouriteLabel.id);
        if (!removedLabel) {
          return { ...state };
        }

        const newLabels = labels.map(label => {
          if (label === removedLabel) {
            return {
              ...label,
              isPinned: false,
              favouriteId: ''
            };
          }
          return label;
        });

        return {
          ...state,
          labels: newLabels
        };
      }

    case RootStateActionType.SetSignalRInit: {
      return {
        ...state,
        signalRInitialized: action.init,
      };
    }

    case RootStateActionType.SetSecurityLabelAuthorizations: {
      const securityLabelAuthorizationsWithIds = action.authorizations.filter(sla => sla.id !== null);

      const dictionaryOfSecurityLabelAuthorizationsWithIds = arrayToMap<SecurityLabelAuthorization>(
        securityLabelAuthorizationsWithIds,
        false,
        'name',
      );

      return {
        ...state,
        securityLabelAuthorizationsWithIds: dictionaryOfSecurityLabelAuthorizationsWithIds,
      };
    }

    default:
      return state;
  }
};

export function reducer(state: RootState | undefined, action: RootStateActions) {
  return rootStateReducer(state, action);
}
