import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { AppState } from '../../../root-store/state';
import { FileArraySelected, TagsUpdated, UploadRemoved, ValidUploadFormSubmitted } from '../../store/actions';
import { uploadIsInProgress, queue, uploadsQueued } from '../../store/selectors';
import { selectRootStateTags } from '../../../root-store/selectors';
import { map, takeUntil } from 'rxjs/operators';
import { QueueProcessingState, Upload, UploadState } from '../../models/models';
import { SelectedItem } from '../../../paragraph-review/store/reducer';
import { MaxFileSize, MaxQueueLength } from '../../constants/constants';
import { ContentTypes } from '../../../core/constants/content-types';
import { NotificationService } from '../../../core/services/notification.service';

@Component({
  selector: 'upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  styleUrls: ['./upload-documents-dialog.component.scss']
})
export class UploadDocumentsDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<UploadDocumentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public ontology: string) {
    dialogRef.disableClose = true;
  }
  public uploadStateEnum = UploadState;
  public maxQueueLength = MaxQueueLength;
  public allowedFileType = ContentTypes.Pdf;
  public uploads: Upload[] = [];
  public uploadIsInProgress = false;
  public uploadsAreQueued = false;
  public queueProcessingState: QueueProcessingState = QueueProcessingState.Idle;
  public selectedTags: SelectedItem[] = [];

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  public tags$: Observable<string[]> = this.store.pipe(
    select(selectRootStateTags)
  );

  protected readonly UploadState = UploadState;

  public ngOnInit(): void {
    this.store
      .select(queue(this.ontology))
      .pipe(
        select(queue => queue.uploads),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(uploads => {
        this.uploads = uploads;
      });

    this.store
      .select(queue(this.ontology))
      .pipe(
        select(queue => queue.tags),
        map(tags => [...tags.map(tag => ({ name: tag }))]),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(tags => {
        this.selectedTags = tags
      });

    this.store
      .select(queue(this.ontology))
      .pipe(
        select(queue => queue.queueProcessingState),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(queueProcessingState => {
        this.queueProcessingState = queueProcessingState
      });

    this.store
      .select(uploadIsInProgress(this.ontology))
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(uploadIsInProgress => {
        this.uploadIsInProgress = uploadIsInProgress;
      });

    this.store
      .select(uploadsQueued(this.ontology))
      .pipe(
        map(uploads => uploads.length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(areUploadsQueued => {
        this.uploadsAreQueued = areUploadsQueued
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSelectedFilesChanged($event: Event): void {

    const input = $event.target as HTMLInputElement;
    const validFiles: File[] = [];
    let invalidFileTypeFlag = false;
    let invalidFileSizeFlag = false;
    for (const file of Array.from(input.files)) {
      if (file.type === this.allowedFileType && file.size <= MaxFileSize) {
        validFiles.push(file);
      } else {
        if (file.type !== this.allowedFileType) {
          invalidFileTypeFlag = true;
          break;
        }
        if (file.size > MaxFileSize) {
          invalidFileSizeFlag = true;
          break;
        }
      }
    }
    if (invalidFileTypeFlag) {
      this.notificationService.showError(`Only .pdf files are allowed.`);
      return;
    }
    if (invalidFileSizeFlag) {
      this.notificationService.showError(`Max file size allowed is 1 GB.`);
      return;
    }
    if (this.uploads.length + validFiles.length > this.maxQueueLength) {
      this.notificationService.showError('You can only upload 100 files at a time.');
      return;
    }
    if (validFiles.length) {
      this.onFilesSelected(validFiles);
    }
    input.value = null;
  }

  public onFilesSelected(files: Array<File>): void {
    this.store.dispatch(new FileArraySelected(this.ontology, files));
  }

  public onUploadRemoveClicked(upload: Upload, event: Event): void {
    /* We don't want this click to bubble up and trigger the
    file selection click. */
    event.stopPropagation();
    this.store.dispatch(new UploadRemoved(this.ontology, upload));
  }

  public onTagsUpdated(event: { selectedItems: SelectedItem[]; }) {
    this.store.dispatch(new TagsUpdated(this.ontology, event.selectedItems.map(t => t.name)));
  }

  public onImportClicked(): void {
    this.store.dispatch(new ValidUploadFormSubmitted(this.ontology));
  }

  getClass(upload: Upload): string {
    switch (upload.state) {
      case UploadState.Queued:
        return 'queued';
      case UploadState.InProgress:
        return 'in-progress';
      case UploadState.Completed:
        return 'completed';
      case UploadState.Error:
        return 'error';
      default:
        return '';
    }
  }
  getValue(upload: Upload): number {
    switch (upload.state) {
      case UploadState.Queued:
        return 0;
      case UploadState.InProgress:
        return 50;
      case UploadState.Completed:
        return 100;
      default:
        return 0;
    }
  }
}
