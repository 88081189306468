import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LabelExample } from 'src/app/core/models/Label';
import { SecurityLabelAuthorization } from 'src/app/core/models/security-label-authorization';
import { User } from 'src/app/core/models/User';
import { SecurityLabelAuthorizationsService } from 'src/app/core/services/security-label-authorizations.service';
import { AppState } from 'src/app/root-store/state';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
  selector: 'app-example-view',
  templateUrl: './example-view.component.html',
  styleUrls: ['./example-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExampleViewComponent {
  @Input() labelExample: LabelExample;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  currentUser: User;
  constructor(
    public dialog: MatDialog,
    public authorizationService: SecurityLabelAuthorizationsService,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.store.select(s => s.rootState.currentUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      currentUser => { this.currentUser = currentUser; }
    );
  }

  goToProvision() {
    this.authorizationService.get(this.currentUser.id).subscribe(response => {
      if (response.status == 200) {
        const securityLabels: SecurityLabelAuthorization[] = response.body.securityLabels;
        if (securityLabels.filter(item => item.name == this.labelExample.ontologyId).length) {
          const link = window.location.href.replace("mappings", "labelsamples") +
            `?ontology=${this.labelExample.ontologyId}&paragraphId=${this.labelExample.provisionId}`;
          window.open(link, '_blank');
        }
        else {
          this.dialog.open(MessageDialogComponent, { data: { title: "Message", message: `You do not have access to view this ontology (${this.labelExample.ontologyId}).`, icon: "warning" } });
        }
      }
    });

  }
}
