<div fxLayout="column" fxLayoutGap="15px">
  <div>
    <h2 mat-dialog-title>Requirement Suggestions</h2>
  </div>
  <div>
    <mat-card appearance="outlined" style="background:#f7f7f7;margin-bottom:5px;">
      <div>
        <b>Note: </b>This paragraph sample does not satisfy all the requirements for the labels added to it.
        Below is the list of labels which are missing its minimum requirement. Adding any one of the suggested labels
        will fulfill
        the requirement for that particular label.
      </div>
    </mat-card>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-evenly strech" fxLayoutGap="7px">
    <div *ngFor="let req of suggestions | keyvalue">
      <p><b>{{req.key}}: </b><span>({{req.value}})</span><p>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button mat-raised-button color="primary" [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  </div>
</div>
