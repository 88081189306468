import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TrainingType } from '../../core/models/models';
import { ReviewView } from '../../core/models/ReviewView';
import {
  BulkFindConfoundingScores,
  BulkRejectParagraphs,
  BulkTagAdd,
  BulkTagRemove,
  BulkUnconfirmParagraph,
  CheckAllChanged,
  CheckPageItemsChanged,
  ClassifyParagraphSet,
  ClearSelectedCluster,
  ConfirmParagraphSet,
  HideParagraphSet,
  SearchByClusterIds,
  SetActiveLabel,
  SetReviewView,
  ToggleMetaData,
  UnhideParagraphSet,
  ZoomToClusterIds,
  ZoomToParagraphIds,
} from '../store/actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../root-store/state';
import { UpdateTagsDialogComponent } from '../../shared/dialogs/update-tags-dialog/update-tags-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SelectModelDialogComponent } from '../select-model-dialog/select-model-dialog.component';
import { ModelDeployment } from '../../core/models/ModelDeployment';
import { CommentDialogComponent } from '../../shared/dialogs/comment-dialog/comment-dialog.component';
import { Router } from '@angular/router';
import { ProvisionService } from '../../core/services/provision.service';
import {
  ClusterSelection,
  ParagraphSearchQuery,
} from '../store/reducer';
import { LabelDefinitionAndSamples } from '../../core/models/Label';
import { LabelService } from '../../core/services/label.service';
import { ModelService } from '../../core/services/model.service';
import { NotificationService } from '../../core/services/notification.service';
import {
  CreateTextSampleDialogComponent,
} from '../../shared/dialogs/create-text-sample-dialog/create-text-sample-dialog.component';
import {
  ActiveLearningDialogComponent,
} from '../../shared/dialogs/active-learning-dialog/active-learning-dialog.component';
import { ParagraphsPageSize } from '../../core/constants/li-constants';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FeatureFlagsService } from '../../core/services/feature-flags.service';
import { FeatureFlags } from '../../core/models/feature-flags';
import { getClusterSelection, getParagraphReviewState, getParagraphSearchQuery, getReviewView } from '../store/selectors';
import {
  UploadDocumentsDialogComponent
} from '../../document-upload/components/upload-documents-dialog/upload-documents-dialog.component';
import { DataGenieDialogComponent } from '../../shared/dialogs/data-genie-dialog/data-genie-dialog.component';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit, OnDestroy {

  @Input() isParent = true;
  @Input() ontology: string;
  @Input() paragraphIds: number[] = [];
  @Input() tags: string[];
  @Input() modelDeployments: ModelDeployment[] = [];
  @Input() active: any;

  @Output() showDefinitionAndExamplesEvent = new EventEmitter<LabelDefinitionAndSamples>();

  @Input() reviewView: ReviewView;
  @Input() unCheckedParagraphIds: number[] = [];
  @Input() checkedParagraphCount: number;
  @Input() checkedClusterCount: number;
  @Input() checkPageItemsState = false;
  @Input() checkedParagraphIds: number[] = [];
  @Input() checkAllState = false;
  public ReviewViewEnum = ReviewView;
  public total: number;
  public totalPages: number;
  public shareLink = 'Loading...';
  public searchPredicate: ParagraphSearchQuery;
  public clusterSelection: ClusterSelection;

  protected readonly TrainingType = TrainingType;
  protected readonly ReviewView = ReviewView;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private readonly MAX_TEXT_SIZE = 10;
  public featureFlags: Observable<FeatureFlags>;

  constructor(
    private provisionService: ProvisionService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    public router: Router,
    private labelService: LabelService,
    private modelService: ModelService,
    private notificationService: NotificationService,
    private featureFlagsService: FeatureFlagsService,
  ) { }

  ngOnInit() {
    this.featureFlags = this.featureFlagsService.featureFlags$;
    this.store
      .pipe(select(getParagraphSearchQuery))
      .subscribe(search => {
        this.searchPredicate = search;
      });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.result.total),
      )
      .subscribe(total => {
        this.total = total;
        this.totalPages = Math.ceil(total / ParagraphsPageSize);
      });

    this.store
      .select(getReviewView)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(reviewView => {
        this.reviewView = reviewView;
      });

    this.store
      .select(getClusterSelection)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(clusterSelection => {
        this.clusterSelection = clusterSelection;
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkAllChange(status) {
    this.store.dispatch(new CheckAllChanged(status));
  }

  bulkAddTags() {
    let message;
    if (this.reviewView === ReviewView.ParagraphSearch) {
      message = `Add tags to ${this.checkedParagraphCount} paragraph(s)`;
    }
    if (this.reviewView === ReviewView.ClusterView) {
      message = `Add tags to ${this.checkedClusterCount} cluster(s)`;
    }
    const bulkAddTagDialog = this.dialog.open(UpdateTagsDialogComponent, {
      width: '350px',
      data: { tags: this.tags, message },
    });
    bulkAddTagDialog.afterClosed().subscribe(result => {
      if (result && result.tags.length > 0) {
        this.store.dispatch(new BulkTagAdd(result.tags));
      }
    });
  }

  bulkRemoveTags() {
    let message;
    if (this.reviewView === ReviewView.ParagraphSearch) {
      message = `Remove tags from ${this.checkedParagraphCount} paragraph(s)`;
    }
    if (this.reviewView === ReviewView.ClusterView) {
      message = `Remove tags from ${this.checkedClusterCount} cluster(s)`;
    }
    const bulkRemoveTagDialog = this.dialog.open(UpdateTagsDialogComponent, {
      width: '500px',
      data: { tags: this.tags, message },
    });
    bulkRemoveTagDialog.afterClosed().subscribe(result => {
      if (result && result.tags.length > 0) {
        this.store.dispatch(new BulkTagRemove(result.tags));
      }
    });
  }

  bulkFindConfoundingScores() {
    const bulkFindConfoundingScoresDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: `Find Confounding Scores for ${this.checkedParagraphCount} paragraph(s)`,
    });
    bulkFindConfoundingScoresDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new BulkFindConfoundingScores());
      }
    });
  }

  bulkUnconfirmParagraphs() {
    const unconfirmParagraphSetDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `You are about to unconfirm ${this.checkedParagraphCount} paragraph(s).`,
    });
    unconfirmParagraphSetDialog.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new BulkUnconfirmParagraph());
      }
    });
  }

  checkPageItemsChange(status) {
    this.store.dispatch(new CheckPageItemsChanged(status.checked, this.paragraphIds));
  }

  classifySampleSet() {
    this.dialog
      .open(SelectModelDialogComponent, {
        width: '500px',
        data: this.modelDeployments.filter(m => m.current)
      })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.store.dispatch(new ClassifyParagraphSet(data.modelDeploymentId));
        }
      });
  }

  hideParagraphSet() {
    const hideParagraphSetDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `You are about to hide ${this.checkedParagraphCount} paragraph(s).`,
    });
    hideParagraphSetDialog.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new HideParagraphSet());
      }
    });
  }

  unhideParagraphSet() {
    const unhideParagraphSetDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `You are about to unhide ${this.checkedParagraphCount} paragraph(s).`,
    });
    unhideParagraphSetDialog.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new UnhideParagraphSet());
      }
    });
  }

  rejectParagraphSet() {
    this.dialog
      .open(CommentDialogComponent, {
        width: '450px',
        data: { isRequired: true, dialogTitle: 'Reject Paragraph' },
      })
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new BulkRejectParagraphs(response.comment, response.responseType));
        }
      });
  }

  confirmParagraphSet() {
    const confirmParagraphSetDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `You are about to confirm ${this.checkedParagraphCount} paragraph(s).`,
    });

    confirmParagraphSetDialog.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new ConfirmParagraphSet());
      }
    });
  }

  navigateToPreviousView() {
    switch (this.reviewView) {
      case ReviewView.SideBySideConflict:
        this.router.navigate(['main/models']);
        break;
      case ReviewView.DatasetSideBySideConflict:
        this.router.navigate(['main/datasets']);
        break;
      case ReviewView.ClusterView:
        this.store.dispatch(new SearchByClusterIds([]));
        this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch));
        break;
      default:
        this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch));
        break;
    }
  }

  getShareableLink(): void {
    this.shareLink = 'Loading...';
    this.provisionService.shareSearch(this.searchPredicate).subscribe(shareId => {
      this.shareLink = `${window.location.href.split('?')[0]}?search=${shareId.id}`;
    });
  }

  preventClose(event: MouseEvent): void {
    event.stopImmediatePropagation();
  }

  copyToClipBoard(event, shareLinkInput: HTMLInputElement) {
    event.stopPropagation();
    shareLinkInput.select();
    document.execCommand('copy');
    shareLinkInput.setSelectionRange(0, 0);
  }

  toggleMetaData() {
    this.store.dispatch(new ToggleMetaData());
  }

  toggleGroupByCluster() {
    this.store.dispatch(new SetReviewView(ReviewView.ClusterView));
  }

  showDefinitionAndExamples(active: any) {
    this.labelService.getLabelExemplar([active.id]).subscribe(labelExamples => {

      const labelDefinitionAndSamples: LabelDefinitionAndSamples = {
        definition: active.definition,
        samples: labelExamples || [],
        labelText: active.name,
        previousView: ReviewView.ParagraphSearch,
      }
      this.showDefinitionAndExamplesEvent.emit(labelDefinitionAndSamples);
    })
  }

  removeActiveLabel() {
    this.store.dispatch(new SetActiveLabel(null));
  }

  trainModel(trainingType: TrainingType) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '350px',
        data: `Do you really want to initiate training process for ${this.ontology} - ${TrainingType[trainingType]}?`,
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.modelService.initiateTraining(this.ontology, trainingType).subscribe(() => {
            this.notificationService.showInfo('Training process has been initiated.');
          });
        }
      });
  }

  generateMapping() {
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '350px',
        data: `Do you really want to generate mapping for ${this.ontology}?`,
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.modelService.generateMapping(this.ontology).subscribe(() => {
            this.notificationService.showInfo('Generation of mapping has been initiated');
          });
        }
      });
  }

  createText() {
    this.dialog.open(CreateTextSampleDialogComponent, {
      width: '700px',
    });
  }

  navigateToSearch() {
    this.router.navigate(['main/tenantsearch']);
  }

  getSamples() {
    this.dialog.open(ActiveLearningDialogComponent, {
      width: '700px',
    });
  }

  zoomTo() {
    switch (this.reviewView) {
      case ReviewView.ParagraphSearch:
        this.store.dispatch(new ZoomToParagraphIds(this.checkedParagraphIds));
        break;
      case ReviewView.ClusterView:
        this.store.dispatch(new ZoomToClusterIds(this.clusterSelection.selectedClusters))
    }
  }

  uploadDocuments() {
    this.dialog.open(UploadDocumentsDialogComponent, {
      width: '700px',
      data: this.ontology
    });
  }

  dataGenie() {
    this.dialog.open(DataGenieDialogComponent, {
      width: '700px',
      data: this.ontology
    });
  }

  showAllSamples() {
    this.store.dispatch(new SearchByClusterIds([]));
  }

  clearSelectedItems() {

    if (this.reviewView === ReviewView.ParagraphSearch) {
      this.checkAllChange(false);
    }
    if (this.reviewView === ReviewView.ClusterView) {
      this.store.dispatch(new ClearSelectedCluster())
    }
  }

  addEllipsisToLabel(text: string) {
    return text.length >= this.MAX_TEXT_SIZE;
  }

}
