<div [ngClass]="{'search-mat-card': reviewView === ReviewViewEnum.ParagraphSearch}">
  <app-action-bar
    [reviewView]='reviewView'
    [ontology]='ontology'
    [isParent]='isParent'
    [paragraphIds]='paragraphIds'
    [tags]='tags'
    [modelDeployments]='modelDeployments'
    [checkedParagraphIds]='checkedParagraphIds'
    [unCheckedParagraphIds]='unCheckedParagraphIds'
    [checkedParagraphCount]='checkedParagraphCount'
    [checkPageItemsState]='checkPageItemsState'
    [active]='active'
    [checkAllState]='checkAllState'
    (showDefinitionAndExamplesEvent)='showDefinitionAndExamples(active)'
  ></app-action-bar>
  <button *ngIf='searchPredicate?.clusterIds?.length > 0' mat-button
          (click)="navigateToClusterView()" class="return-button" color="primary">
    <mat-icon>arrow_back</mat-icon>
    <span>Back to all clusters</span>
  </button>
  <div
    [ngClass]='{"scroll-paragraph-search": reviewView === ReviewViewEnum.ParagraphSearch
                 && searchPredicate?.clusterIds?.length !== 1,
                "scroll-side-by-side-conflict": (reviewView === ReviewViewEnum.SideBySideConflict || reviewView === ReviewViewEnum.DatasetSideBySideConflict),
                "scroll-paragraph-search-cluster": reviewView === ReviewViewEnum.ParagraphSearch
                 && searchPredicate?.clusterIds?.length === 1,
                "scroll-search": true}'
    id="scroll-search">
      <div class='cluster-title' *ngIf='searchPredicate?.clusterIds?.length === 1'>
        Showing Cluster ID {{searchPredicate.clusterIds[0]}}
      </div>
      <div *ngFor="let paragraphId of paragraphIds; trackBy: trackByFn"
        class="paragraph-container">
        <app-paragraph-view *ngIf="modelDeployments" style="width: 100%;"
          [ontology]="ontology"
          [tags]="tags"
          [activeLabel]="active"
          [paragraphId]="paragraphId"
          [showMetaData]="showMetaData"
          [checkAllState]="checkAllState"
          [unCheckedParagraphIds]="unCheckedParagraphIds"
          [checkedParagraphIds]="checkedParagraphIds"
          [modelDeployments]="modelDeployments"
          (classifyProvision)="classifyParagraph($event)"></app-paragraph-view>
      </div>
  </div>
  <div *ngIf='reviewView === ReviewViewEnum.ParagraphSearch'
    fxLayout="row"
    fxLayoutGap="0.5em"
    class="search-footer">
    <div fxFlex="50"
      fxLayoutAlign="start center">
      <p style="font-weight: 500;">{{total | number}} Results Retrieved | </p>
      <button matTooltip="Refresh"
        class="footer-button"
        color="primary"
        [disabled]="!paragraphIds"
        (click)="refreshResults()"
        mat-icon-button>
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div fxFlex="50"
      fxLayoutAlign="end center"
      fxLayoutGap="0.5em">
      <button matTooltip="First Page"
        class="footer-button"
        color="primary"
        [disabled]="page === 1"
        (click)="firstPage()"
        mat-icon-button>
        <mat-icon>first_page</mat-icon>
      </button>
      <button matTooltip="Previous"
        class="footer-button"
        color="primary"
        [disabled]="page === 1"
        (click)="loadMore(loadDirectionEnum.previous)"
        mat-icon-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <p style="font-weight: 500;">
        <input style="width: 35px;text-align: center;"
          [(ngModel)]="page"
          [disabled]="isPageNumberInputDisabled()"
          (input)="goToPage()">
        / {{calculateNumberOfPages()}}</p>
      <button matTooltip="Next"
        class="footer-button"
        color="primary"
        [disabled]="page === totalPages"
        (click)="loadMore(loadDirectionEnum.next)"
        mat-icon-button>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <button matTooltip="Last Page"
        class="footer-button"
        color="primary"
        [disabled]="page === totalPages"
        (click)="lastPage()"
        mat-icon-button>
        <mat-icon>last_page</mat-icon>
      </button>
    </div>
  </div>
</div>
