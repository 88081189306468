import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LabelDefinitionAndSamples } from 'src/app/core/models/Label';
import { LabelExample } from 'src/app/core/models/Label';
import { LoadDirectionEnum } from 'src/app/root-store/reducer';
import { ReviewView } from '../../core/models/ReviewView';

@Component({
  selector: 'app-label-definition',
  templateUrl: './label-definition.component.html',
  styleUrls: ['./label-definition.component.scss']
})
export class LabelDefinitionComponent implements OnInit, OnChanges {

  @Input() labelExampleInfo: LabelDefinitionAndSamples;
  @Input() labelTitleText: string;
  @Output() backFn = new EventEmitter<ReviewView>();
  public totalPages: number;
  public total: number;
  public page: number;
  public currentPage: LabelExample[];
  PAGE_SIZE: number = 10;
  ReviewView = ReviewView;
  public next: LoadDirectionEnum = LoadDirectionEnum.next
  public previous: LoadDirectionEnum = LoadDirectionEnum.previous
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.total = this.labelExampleInfo.samples.length;
    this.totalPages = this.total > 0 ? Math.ceil(this.total / this.PAGE_SIZE) : 0;
    if (this.totalPages > 0) {
      this.firstPage()
    } else {
      this.page = 0;
    }

  }

  scrollToTop() {
    const myDiv = document.getElementById('scroll-search');
    myDiv.scrollTop = 0;
  }

  return() {
    this.backFn.emit(this.labelExampleInfo.previousView);
  }


  firstPage() {
    this.page = 1;
    this.currentPage = this.labelExampleInfo.samples.slice(0, this.PAGE_SIZE);
  }

  loadMore(action: LoadDirectionEnum) {
    if (action == LoadDirectionEnum.next) {
      this.page++;
    } else {
      this.page--;
    }
    this.goToPage()
  }

  goToPage() {
    if (this.total < this.PAGE_SIZE || !(this.page > 0)) {
      this.firstPage()
    }
    if (this.page >= this.totalPages) {
      this.lastPage()
    }
    if (this.page < this.totalPages) {
      const firstPositionInPage = this.page * this.PAGE_SIZE - (this.PAGE_SIZE - 1)
      const lastPositionInPage = firstPositionInPage + this.PAGE_SIZE;
      this.currentPage = this.labelExampleInfo.samples.slice(firstPositionInPage, lastPositionInPage);
    }

    this.scrollToTop();

  }

  lastPage() {
    this.page = this.totalPages
    const firstPositionInPage = this.total - (this.total % this.PAGE_SIZE) - 1
    const lastPositionInPage = this.total - 1;
    this.currentPage = this.labelExampleInfo.samples.slice(firstPositionInPage, lastPositionInPage);
  }




}
