import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClusterMetadata } from '../../core/models/ClusterMetadata';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../../root-store/state';
import {
  ClusterDeselected,
  ClusterPageChanged,
  ClusterSelected,
  ClusterSortOrderChanged,
  ExpandScatterPlotPanel,
  ProvisionSearchActionType,
  SearchByClusterIds,
  SearchClusterMetadata,
  SetReviewView,
} from '../store/actions';
import { ReviewView } from '../../core/models/ReviewView';
import { getClusterSelection, getClusterState } from '../store/selectors';
import { debounceTime } from 'rxjs/operators';
import { ClustersPageSize } from '../../core/constants/li-constants';
import { LoadDirectionEnum } from '../../root-store/reducer';
import { Actions } from '@ngrx/effects';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-cluster-view',
  templateUrl: './cluster-view.component.html',
  styleUrls: ['./cluster-view.component.scss']
})
export class ClusterViewComponent implements OnInit {
  readonly lowScoreLimit = 0.5;
  readonly mediumScoreLimit = 0.8;

  readonly ClustersPageSize = ClustersPageSize;
  @Input() ontology: string;
  @Input() tags: string[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public pageChangedCluster: EventEmitter<number> = new EventEmitter<number>();


  page: number;
  totalPages: number;
  totalClusters: number;
  clustersMetadata: ClusterMetadata[];
  checkedClusterCount: number;

  dataSource: MatTableDataSource<ClusterMetadata> = new MatTableDataSource();

  displayedColumns: string[] = [
    'selected',
    'clusterId',
    'samplesNumber',
    'keywords',
    'score',
  ];
  public loadDirectionEnum = LoadDirectionEnum;
  constructor(
    private store: Store<AppState>,
    private updates$: Actions,
  ) { }

  ngOnInit() {
    this.store.dispatch(new SearchClusterMetadata());
    this.store.dispatch(new ExpandScatterPlotPanel(true));
    this.pageChangedCluster.pipe(debounceTime(600)).subscribe(page => {
      this.store.dispatch(new ClusterPageChanged(page));
    });

    this.updates$.subscribe((action: Action) => {
      if (action.type === ProvisionSearchActionType.SearchClusterComplete) {
        this.store.pipe(select(getClusterState))
          .subscribe(state => {
            this.page = state.clusterSearch.page;
            this.clustersMetadata = state.clustersMetadata.map(cm =>
              ({...cm, selected: state.clusterSelection.selectedClusters.includes(cm.clusterId)}));
            this.totalClusters = state.totalClusters;
            this.totalPages = Math.ceil(this.totalClusters / ClustersPageSize);
            this.dataSource = new MatTableDataSource(this.clustersMetadata);
            this.dataSource.data = this.clustersMetadata
              .sort((a, b) => a.score > b.score ? -1 : 1);
            this.sort.disableClear = true;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          });
      }
    });

    this.store.pipe(select(getClusterSelection))
      .subscribe(clusterSelection => {
        this.checkedClusterCount = clusterSelection.selectedClusters.length;
    });
  }

  getScoreColor(score) {
    if (score < this.lowScoreLimit) {
      return 'red-dot';
    } else if (score >= this.lowScoreLimit && score < this.mediumScoreLimit) {
      return 'yellow-dot';
    } else {
      return 'green-dot';
    }
  }

  navigateToLabelSample(clusterId: number) {
    this.store.dispatch(new SearchByClusterIds([clusterId]));
    this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch));
  }
  refreshResults() {
    this.store.dispatch(new SearchClusterMetadata())
  }

  goToPage() {
    if (this.totalClusters < ClustersPageSize || !(this.page > 0)) {
      this.pageChangedCluster.emit(1);
    }
    if (this.page >= this.totalPages) {
      this.page = this.totalPages;
      this.pageChangedCluster.emit(this.totalPages);
    }
    if (this.page < this.totalPages) {
      this.pageChangedCluster.emit(this.page);
    }
    this.scrollToTop();
  }

  lastPage() {
    this.pageChangedCluster.emit(this.totalPages);
    this.scrollToTop();
  }

  firstPage() {
    this.pageChangedCluster.emit(1);
    this.scrollToTop();
  }
  loadMore(action: LoadDirectionEnum) {
    if (action === this.loadDirectionEnum.next) {
      this.page++;
      this.store.dispatch(new ClusterPageChanged(this.page));
    } else {
      this.page--;
      this.store.dispatch(new ClusterPageChanged(this.page));
    }
    this.scrollToTop();
  }

  scrollToTop() {
    const myDiv = document.getElementById('scroll-search');
    myDiv.scrollTop = 0;
  }
  onSelectionChange($event: MatCheckboxChange, row) {
    console.log($event, row);
    if ($event.checked) {
      this.store.dispatch(new ClusterSelected(row.clusterId));
    } else {
      this.store.dispatch(new ClusterDeselected(row.clusterId));
    }
  }

  onClusterSortChange() {
    const sortOrder = { active: this.dataSource.sort.active, direction: this.dataSource.sort.direction }
    this.store.dispatch(new ClusterSortOrderChanged(sortOrder));
  }

  addSpacesAfterComma(keywords: string) {
    return keywords.replace(/,/gm, ', ');
  }

  percentageParse(score: number) {
    return parseFloat((100 * score).toFixed(2));
  }
}
