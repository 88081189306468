import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-notification-action',
  templateUrl: './notification-action.component.html',
  styleUrls: ['./notification-action.component.scss'],
})
export class NotificationActionComponent implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<NotificationActionComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {}

  name: string;
  cancel(): void {
    this.data.message = 'Cancel';
    this.bottomSheetRef.dismiss(this.data);
    // tslint:disable-next-line: deprecation
    event.preventDefault();
  }

  accept() {
    this.data.message = 'Accept';
    this.bottomSheetRef.dismiss(this.data);
  }

  ngOnInit() {}
}
