import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Field } from 'src/app/core/models/models';
import { AppState } from 'src/app/root-store/state';
import { LabelService } from 'src/app/core/services/label.service';
import { NotificationService } from 'src/app/core/services/notification.service';

class TreeNode {
  name: string;
  id: number;
  parentId?: number;
  children?: TreeNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  id: number;
  level: number;
}

@Component({
  selector: 'field-tree-view',
  templateUrl: 'field-tree-view.component.html',
  styleUrls: ['field-tree-view.component.scss'],
})

export class FieldTreeViewComponent implements OnInit, OnChanges {
  @Input() items: Field[] = [];

  @Output() nodeClicked = new EventEmitter<any>();

  dataChange = new BehaviorSubject<TreeNode[]>([]);
  treeData: any[];
  filterText = '';
  ontology: string = '';

  constructor(
    private store: Store<AppState>,
    private labelService: LabelService,
    private notificationService: NotificationService
  ) { }

  get data(): TreeNode[] {
    return this.dataChange.value;
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  private transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level,
      parentId: node.parentId,
    };
  };

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  // tslint:disable-next-line: member-ordering
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
    this.treeData = this.items;
    const data = this.buildTree(this.items);
    this.dataChange.next(data);

    this.dataChange.subscribe(d => {
      this.dataSource.data = d;
    });

    this.store.select(s => s.rootState.ontology).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      ontology => { this.ontology = ontology; }
    )
  }

  ngOnChanges() {
    this.treeData = this.items;
    const data = this.buildTree(this.items);
    this.filterText !== '' ? this.filterChanged(this.filterText) : this.dataChange.next(data);
  }

  getName(name) {
    if (name.toLowerCase().includes(this.filterText.toLowerCase()) && this.filterText !== '') {
      const index = name.toLowerCase().indexOf(this.filterText.toLowerCase());
      return (
        name.substr(0, index) +
        `<span class='machine-highlight'>${name.substr(index, this.filterText.length)}</span>` +
        name.substr(index + this.filterText.length, name.length)
      );
    } else {
      return name;
    }
  }

  addToFavorites(label) {
    this.labelService.addFavourite(this.ontology, label.id).subscribe(() => {
      this.notificationService.showSuccess('Label added to favourite: ' + label.displayName);
    })
  }

  removeFromFavorites(label) {
    this.labelService.deleteFavourite(label.favouriteId).subscribe(() => {
      this.notificationService.showSuccess('Label removed from favourite: ' + label.displayName);
    })
  }

  filterChanged(term: string): void {
    this.filterText = term;
    let filteredTreeData;
    if (term) {
      filteredTreeData = this.treeData.filter(s => this.checkIfContained(s, term));
    } else {
      filteredTreeData = this.treeData;
    }
    const data = this.buildTree(filteredTreeData);
    this.dataChange.next(data);

    if (term) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  checkIfContained(item: any, term: string): boolean {
    if (item.name.toLowerCase().indexOf(term.toLowerCase().trim()) > -1) {
      return true;
    } else if (item.labels.some(s => s.name.toLowerCase().trim().indexOf(term.toLowerCase()) > -1)) {
      return true;
    } else {
      return false;
    }
  }

  buildTree(data: any[]): TreeNode[] {
    return data.map(s => {
      const node = new TreeNode();
      node.name = s.name;
      node.id = s.id;
      if (s.fieldId) {
        node.parentId = s.entityId;
      }
      if (s.labels && s.labels.length > 0) {
        node.children = this.buildTree(s.labels);
      }
      return node;
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.level === 0;

  nodeChanged(data) {
    this.nodeClicked.emit({ id: data.id, name: data.name });
  }
}
