import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) { }

  public upload(file: File, ontology: string, tags: string[]) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('ontology', ontology);
    tags.forEach(tag => formData.append('tags', tag));

    return this.http.post<any>('api/document', formData);
  }
}
