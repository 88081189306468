import { IndexableMap } from '../models/indexable-map';

export function arrayToMap<DictionaryType>(
  arrayInput: Array<any>,
  removeIdFromElements = true,
  idKey: string = 'id',
): IndexableMap<DictionaryType> {
  return arrayInput
    .filter(element => element[idKey] != null)
    .reduce((dictionaryThusFar: IndexableMap<DictionaryType>, currentElement) => {
      const { [idKey]: idOfCurrentElement, ...currentElementWithoutId } = currentElement;
      return {
        ...dictionaryThusFar,
        [idOfCurrentElement]: removeIdFromElements ? currentElementWithoutId : currentElement,
      };
    }, {});
}