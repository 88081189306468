import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Range } from "../../../paragraph-review/store/reducer";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-select-confidence-dialog',
  templateUrl: './select-confidence-dialog.component.html',
  styleUrls: ['./select-confidence-dialog.component.scss']
})
export class SelectConfidenceDialogComponent implements OnInit, OnChanges{

  @Input('inputValue') inputValue: Range;
  @Output("change") selectedRange: EventEmitter<Range> = new EventEmitter();
  customValue: Range = { minValue: 0, maxValue: 1 };
  selectedValue = new FormControl<Range>({});
  readonly minValue: number = 0;
  readonly maxValue: number = 1;
  readonly step: number = .1;
  @Input("appearance") appearance: string;
  readonly ranges: Range[] = [{ minValue: 0, maxValue: .5 }, { minValue: 0.5, maxValue: .65 }, { minValue: .65, maxValue: .85 }, { minValue: .85, maxValue: 1 }];
  constructor(
    public dialogRef: MatDialogRef<SelectConfidenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { value: Range, name: string },
  ) { }

  ngOnInit() {
    this.selectedValue.setValue(this.data.value);
  }

  ngOnChanges() {
    if (this.inputValue) {
      this.customValue = this.inputValue;
      this.onCustomOptionClick();
    }
  }

  onSelectionChange(val: { value: Range; }) {
    this.selectedValue.setValue(val.value);
    this.selectedRange.emit(this.selectedValue.value);
  }

  onCustomClick(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  onCustomOptionClick() {
    setTimeout(() => {
      this.selectedValue.setValue(this.customValue);
      this.onSelectionChange({ value: this.selectedValue.value });
    }, 1000);
  }

  getNewCustomValue(event: Event, minValue: boolean) {
    const target = event.target as HTMLInputElement;
    let floatValue = parseFloat(target.value);
    if (isNaN(floatValue) || floatValue < this.minValue || floatValue > this.maxValue) {
      floatValue = minValue ? 0 : 1;
    }
    this.customValue = minValue ? { minValue: floatValue, maxValue: this.customValue.maxValue } : { minValue: this.customValue.minValue, maxValue: floatValue };
    this.onCustomOptionClick();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
