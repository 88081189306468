import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConflictFilters } from '../models/Conflict';
import {
  AnalyazeModelText,
  DeploymentModels,
  ModelDeployActionEnum,
  TrainingType,
} from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  constructor(private http: HttpClient) { }

  getModels(ontology: string) {
    return this.http.get<any>(`api/models/${ontology}`);
  }

  getModelDetails(ontology: string) {
    return this.http.get<any>(`api/models/${ontology}/details`);
  }

  getDeploymentModels(ontology: string) {
    return this.http.get<DeploymentModels>(`api/models/deploymentmodels/${ontology}`);
  }

  initiateTraining(ontology: string, trainingType: TrainingType) {
    return this.http.post<any>(`api/models/${ontology}/${trainingType}`, null);
  }

  initiateTrainingV2(ontology: string, trainingType: TrainingType, datasetId: number) {
    return this.http.post<any>(`api/models/v2/train`, { ontology, trainingType, datasetId }, { observe: 'response' });
  }

  generateMapping(ontology: string) {
    return this.http.post<any>(`api/models/${ontology}/generateMapping`, null);
  }

  analyzeText(dto: AnalyazeModelText) {
    return this.http.post<any>(`api/models/analyze`, dto);
  }

  promoteModel(modelId: string) {
    return this.http.post<any>(`api/models/${modelId}/promote`, null);
  }

  deployModelToDevelopment(modelId: string, tenant: string) {
    return this.http.post<any>(`api/models/${modelId}/deploydevelopment/${tenant}`, null);
  }

  deployModelToProduction(modelId: string) {
    return this.http.post<any>(`api/models/${modelId}/deployproduction`, null);
  }

  RequestModelToDeploy(modelId: string) {
    return this.http.post<any>(`api/models/${modelId}/deployrequest`, null);
  }

  cancelRequestedModel(modelId: string, modelDeployActionEnum: ModelDeployActionEnum) {
    return this.http.post<any>(`api/models/${modelId}/${modelDeployActionEnum}/cancelrequest`, null);
  }

  generateReport(modelId: string) {
    return this.http.post(`api/models/${modelId}/report`, null, { responseType: 'arraybuffer' });
  }

  updateModel(modelId, comment) {
    return this.http.post<any>(`api/models/${modelId}/updateModel`, { comment });
  }

  getModelDiff(modelId) {
    return this.http.get<any>(`api/models/${modelId}/diff`);
  }

  getDeployableModels() {
    return this.http.get<any>(`api/models/available`);
  }

  generateConflictingReport(conflictFilters: ConflictFilters) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(`api/models/conflicting-report`, conflictFilters, { headers, observe: 'response' });
  }

  bulkTagUpdate(obj) {
    return this.http.post(`api/models/conflicting-report/bulk/tag`, obj);
  }




}
