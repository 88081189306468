import { Conflict, ConflictFilters, ConflictGroup, ConflictReport } from 'src/app/core/models/Conflict';
import { AnalyzeTextResult, ConflictReportStatus, ModelDetails } from 'src/app/core/models/models';
import {AddTagsToParagraph, ModelReviewActions, ModelReviewActionType, RemoveTagFromParagraph} from './actions';

export interface ModelReviewState {
  results: AnalyzeTextResult[];
  conflictReport: ConflictReport;
  selectedConflictGroup: ConflictGroup;
  selectedConflictFilters: ConflictFilters;
  sortedParentIds: number[];
  currentModel: ModelDetails;
  includeAllSearchResults: boolean;
  includeAllSearchResultsExcludedParagraphIds: Array<string>;
  explicitlySelectedParagraphIds: Array<string>;
}

export const initialSelectedConflictFilters: ConflictFilters = {
  top: 10,
  page: 1,
  paragraphId: null,
  ontologies: [],
  userLabels: [],
  conflictLabels: [],
  conflictingScore: null,
  tags: [],
  sort: {active: "ConflictingScore", direction: "desc"},
  modelId: null,
}

export const initialModelReviewState: ModelReviewState = {
  results: [],
  conflictReport: { status: ConflictReportStatus.Processing },
  selectedConflictGroup: null,
  selectedConflictFilters: initialSelectedConflictFilters,
  sortedParentIds: [],
  currentModel: null,
  includeAllSearchResults: false,
  includeAllSearchResultsExcludedParagraphIds: [],
  explicitlySelectedParagraphIds: [],
};

function getChildConflictsWithNewTags(conflictGroup: ConflictGroup, action: AddTagsToParagraph | RemoveTagFromParagraph) {
  return conflictGroup.childConflicts.map(childConflict => {
    if (childConflict.paragraphId === action.paragraphId) {
      if (action instanceof AddTagsToParagraph) {
        return { ...childConflict, tags: [...new Set(childConflict.tags.concat(action.tags))] };
      } else if (action instanceof RemoveTagFromParagraph) {
        return { ...childConflict, tags: childConflict.tags.filter(tag => !action.tags.includes(tag))};
      }
    } else {
      return { ...childConflict };
    }
  });
}

function getConflictGroupsWithNewTags(conflictReport: ConflictReport, action: AddTagsToParagraph | RemoveTagFromParagraph) {
  return conflictReport.conflictGroups?.map(conflictGroup => {
    let newParent: Conflict;
    if (conflictGroup.parent.paragraphId === action.paragraphId) {
      if (action instanceof AddTagsToParagraph) {
        newParent = { ...conflictGroup.parent, tags: [...new Set(conflictGroup.parent.tags.concat(action.tags))] };
      } else if (action instanceof RemoveTagFromParagraph) {
        newParent = { ...conflictGroup.parent, tags: conflictGroup.parent.tags.filter(tag => !action.tags.includes(tag)) };
      }
    } else {
      newParent = { ...conflictGroup.parent };
    }
    const newChildConflicts = getChildConflictsWithNewTags(conflictGroup, action);
    return { ...conflictGroup, parent: newParent, childConflicts: newChildConflicts };
  });
}

export const modelReviewReducer = (
  state = initialModelReviewState,
  action: ModelReviewActions
): ModelReviewState => {
  switch (action.type) {
    case ModelReviewActionType.SetResults: {
      return {
        ...state,
        results: action.payload,
      };
    }
    case ModelReviewActionType.AddResult: {
      const newResults = [...state.results, action.payload];
      return {
        ...state,
        results: newResults,
      };
    }
    case ModelReviewActionType.RemoveResult: {
      const newResults = [...state.results.filter(s => s.id !== action.payload)];
      return {
        ...state,
        results: newResults,
      };
    }
    case ModelReviewActionType.GetConflictingReportResults: {
      const model = state.currentModel;
      if (action.payload && model && action.payload.modelId != model.id) {
        return {
          ...state
        };
      } else {
        const conflictGroups = action.payload.conflictGroups?.map(conflictGroup => {
          const parent = {...conflictGroup.parent, selected: false};
          const childConflicts = conflictGroup.childConflicts?.map(childConflict => {
            return { ...childConflict, selected: false };
          });
          return { parent, childConflicts };
        })
        return {
          ...state,
          conflictReport: {...action.payload, conflictGroups},
        };
      }
    }
    case ModelReviewActionType.ChangeModelSelection: {
      return {
        ...state,
        currentModel: action.payload,
      };
    }
    case ModelReviewActionType.AddTagsToParagraph: {
      if (action.update) {
        const { conflictReport } = state;
        const conflictGroups = getConflictGroupsWithNewTags(conflictReport, action);
        return {
          ...state,
          conflictReport: {...conflictReport, conflictGroups}
        };
      }
      return {
        ...state,
      };
    }
    case ModelReviewActionType.RemoveTagFromParagraph: {
      if (action.update) {
        const { conflictReport } = state;
        const conflictGroups = getConflictGroupsWithNewTags(conflictReport, action);
        return {
          ...state,
          conflictReport: {...conflictReport, conflictGroups}
        };
      }
      return {
        ...state
      };
    }
    case ModelReviewActionType.BulkTagAdd: {
      return {
        ...state
      };
    }
    case ModelReviewActionType.BulkTagRemove: {
      return {
        ...state
      };
    }
    case ModelReviewActionType.SetConflictGroup: {
      return {
        ...state,
        selectedConflictGroup: action.payload,
      };
    }
    case ModelReviewActionType.SetConflictFilters: {
      return {
        ...state,
        selectedConflictFilters: {
          ...action.payload,
        }
      };
    }
    case ModelReviewActionType.SetSortedParentIds: {
      return {
        ...state,
        sortedParentIds: action.payload,
      };
    }
    case ModelReviewActionType.ConflictReportPageChanged: {
      return {
        ...state,
        selectedConflictFilters: {
          ...state.selectedConflictFilters,
          page: action.payload
        }
      }
    }
    case ModelReviewActionType.ConflictParagraphsSelected: {
      return {
        ...state,
        explicitlySelectedParagraphIds: state.includeAllSearchResults
        ? state.explicitlySelectedParagraphIds
        : [...new Set(state.explicitlySelectedParagraphIds.concat(action.conflictParagraphIds))],
      includeAllSearchResultsExcludedParagraphIds: state.includeAllSearchResults
        ? state.includeAllSearchResultsExcludedParagraphIds.filter(id => !action.conflictParagraphIds.includes(id))
        : state.includeAllSearchResultsExcludedParagraphIds,
      }
    }
    case ModelReviewActionType.ConflictParagraphsDeselected: {
      return {
        ...state,
        explicitlySelectedParagraphIds: state.includeAllSearchResults
        ? state.explicitlySelectedParagraphIds
        : state.explicitlySelectedParagraphIds.filter(docId => !action.conflictParagraphIds.includes(docId)),
      includeAllSearchResultsExcludedParagraphIds: state.includeAllSearchResults
        ? [...new Set(state.includeAllSearchResultsExcludedParagraphIds.concat(action.conflictParagraphIds))]
        : state.includeAllSearchResultsExcludedParagraphIds,
      }
    }
    case ModelReviewActionType.SelectAllConflictResults: {
      return {
        ...state,
        includeAllSearchResults: true,
        explicitlySelectedParagraphIds: [],
        includeAllSearchResultsExcludedParagraphIds: []
      }
    }
    case ModelReviewActionType.ClearAllSelectedConflictResults: {
      return {
        ...state,
        includeAllSearchResults: false,
        explicitlySelectedParagraphIds: [],
        includeAllSearchResultsExcludedParagraphIds: []
      }
    }
    default:
      return state;
  }
};

export function modelReducer(state: ModelReviewState | undefined, action: ModelReviewActions) {
  return modelReviewReducer(state, action);
}
