<div fxLayout="column" fxLayoutGap="15px" class="dialog-margin">
  <div>
    <h2 mat-dialog-title>{{data.dialogTitle}}</h2>
  </div>
  <div [formGroup]="commentForm">
    <div>
      <mat-form-field>
        <mat-label>Select Feedback Response</mat-label>
        <mat-select formControlName="responseType">
          <mat-option *ngFor="let x of responses | keys" [value]="x.key">{{ x.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <textarea matInput [rows]="5" [(ngModel)]="commentInput" matInput placeholder="Comment..."
          formControlName="comment"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button [disabled]="commentForm.status == 'INVALID'" color="primary"
        (click)="onSubmit()">Submit</button>
      <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
    </div>
  </div>
</div>
