import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelDeployment } from 'src/app/core/models/ModelDeployment';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-select-model-dialog',
  templateUrl: './select-model-dialog.component.html',
  styleUrls: ['./select-model-dialog.component.scss']
})
export class SelectModelDialogComponent implements OnInit {
  public selectModel: UntypedFormGroup;
  public isValidModel: boolean;
  public deploymentEnvironment: string;
  constructor(
    public dialogRef: MatDialogRef<SelectModelDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.deploymentEnvironment = environment.deploymentEnvironment;
    this.isValidModel = this.data.length > 0;
    this.selectModel = this.fb.group({
      modelDeploymentId: this.fb.control('', [Validators.required]),
    });
    const currentModelDeployment = this.data.find((md: ModelDeployment) => md.type === 'Standard');
    if (currentModelDeployment) {
      this.selectModel.get('modelDeploymentId').setValue(currentModelDeployment.id);
    }
  }

  classify() {
    this.dialogRef.close({
      modelDeploymentId: this.selectModel.get('modelDeploymentId').value,
    });
  }
}
