export interface ChartOptions {
  height?: number;
  width?: number;
  margin: number;
  idField?: string;
  clusterField?: string;
  infoField?: string;
  data?: any[];
  customData?: any[];
  axis?: Axis;
  tooltipInfo?: TooltipInfo[];
  children?: string;
  filters?: Filter[];
  action?: ChartActions;
  showAxis: boolean;
  zoomToIds?: any[];
  zoomToClusterIds?: number[];
}

export interface Filter {
  field: string,
  values?: any[]
}

export interface Axis {
  xAxisField: string;
  yAxisField: string;
}

export interface TooltipInfo {
  field: string;
  label: string;
  maxItems?: number;
}

export enum ChartActions {
  Panning,
  Brushing,
  Reset,
  ZoomToCustom,
  ZoomToProvisions,
  ZoomToClusters
}

export enum ChartFilters {
  Confirmed = 'confirmed',
  ClusterId = 'clusterId'
}
