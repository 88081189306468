import { Injectable, NgZone } from '@angular/core';
import { take, skip, map, filter, distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { AuthenticatedUser, PendoOptions } from '../models/Pendo';
import jwtDecode from 'jwt-decode';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({ providedIn: 'root' })
export class PendoService {
  private pendoAgent = (window as any).pendo;
  constructor(private ngZone: NgZone, private authService: AuthenticationService) {
    this.pendoOptions$.pipe(take(1)).subscribe(options => this.initializePendoAgent(options));
    this.pendoOptions$.pipe(skip(1)).subscribe(options => this.updatePendoAgentUserInfo(options));
  }
  private pendoOptions$ = this.authService.isDoneLoading$.pipe(
    filter(done => done),
    map(_ => this.tokenToPendoOptions(this.authService.idToken)),
    distinctUntilChanged((x, y) => isEqual(x, y)),
  );

  // Init Pendo outside the zone so that its infinite setTimeouts don't trigger infinite useless CD cycles
  private initializePendoAgent = (options: PendoOptions) => this.ngZone.runOutsideAngular(() => this.pendoAgent.initialize(options));
  private updatePendoAgentUserInfo = (options: PendoOptions) => this.pendoAgent.updateOptions(options);
  private tokenToPendoOptions = (token: string): PendoOptions => {
    const user: AuthenticatedUser = jwtDecode(token);
    return {
      visitor: {
        id: user.email,
        email: user.email,
        accessLevel: 'user',
        full_name: user.name,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
      },
      account: {
        id: 'li-internal',
      },
    };
  };
  public trackEvent = (name: string, data: { [key: string]: string }) => this.pendoAgent.track(name, data);
}
