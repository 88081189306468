import { Action } from '@ngrx/store';
import { RejectionReason, Upload } from '../models/models';

export enum DocumentUploadActionType {
  FileArraySelected = '[Document Upload] File array selected',
  FileSelected = '[Document Upload] File selected',
  UploadAppendedToQueue = '[Document Upload] Upload appended to queue',
  FileRejected = '[Document Upload] File rejected',
  FileUploadStarted = '[Document Upload] File upload started',
  FileUploadSucceeded = '[Document Upload] File upload succeeded',
  FileUploadFinalized = '[Document Upload] File upload finalized',
  FileUploadFailed = '[Document Upload] File upload failed',
  UploadRemoved = '[Document Upload] Upload removed',
  AllUploadsCleared = '[Document Upload] All uploads cleared',
  TagsUpdated = '[Document Upload] Tags updated',
  ValidUploadFormSubmitted = '[Document Upload] Valid upload form submitted',
  FullQueueStarted = '[Document Upload] Full queue started',
  FullQueueCompleted = '[Document Upload] Full queue completed'
}

export class FileArraySelected implements Action {
  public readonly type = DocumentUploadActionType.FileArraySelected;
  constructor(public ontology: string, public files: Array<File>) { }
}

export class FileSelected implements Action {
  public readonly type = DocumentUploadActionType.FileSelected;
  constructor(public ontology: string, public file: File) { }
}

export class UploadAppendedToQueue implements Action {
  public readonly type = DocumentUploadActionType.UploadAppendedToQueue;
  constructor(public ontology: string, public upload: Upload) { }
}

export class FileRejected implements Action {
  public readonly type = DocumentUploadActionType.FileRejected;
  constructor(public ontology: string, public file: File, public reason: RejectionReason) { }
}

export class FileUploadStarted implements Action {
  public readonly type = DocumentUploadActionType.FileUploadStarted;
  constructor(public ontology: string, public upload: Upload, public tags: string[]) { }
}

export class FileUploadSucceeded implements Action {
  public readonly type = DocumentUploadActionType.FileUploadSucceeded;
  constructor(public ontology: string, public upload: Upload) { }
}

export class FileUploadFailed implements Action {
  public readonly type = DocumentUploadActionType.FileUploadFailed;
  constructor(public ontology: string, public upload: Upload, public error: any) { }
}

export class FileUploadFinalized implements Action {
  public readonly type = DocumentUploadActionType.FileUploadFinalized;
  constructor(public ontology: string, public upload: Upload, public error: any) { }
}

export class UploadRemoved implements Action {
  public readonly type = DocumentUploadActionType.UploadRemoved;
  constructor(public ontology: string, public upload: Upload) { }
}

export class TagsUpdated implements Action {
  public readonly type = DocumentUploadActionType.TagsUpdated;
  constructor(public ontology: string, public tags: string[]) { }
}

export class ValidUploadFormSubmitted implements Action {
  public readonly type = DocumentUploadActionType.ValidUploadFormSubmitted;
  constructor(public ontology: string) { }
}

export class FullQueueStarted implements Action {
  public readonly type = DocumentUploadActionType.FullQueueStarted;
  constructor(public ontology: string) { }
}

export class FullQueueCompleted implements Action {
  public readonly type = DocumentUploadActionType.FullQueueCompleted;
  constructor(public ontology: string) { }
}

export type DocumentUploadActions =
  | FileArraySelected
  | FileSelected
  | UploadAppendedToQueue
  | FileRejected
  | FileUploadStarted
  | FileUploadSucceeded
  | FileUploadFailed
  | FileUploadFinalized
  | UploadRemoved
  | TagsUpdated
  | ValidUploadFormSubmitted
  | FullQueueStarted
  | FullQueueCompleted;
