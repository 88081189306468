<form>
  <mat-form-field class="example-chip-list">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Item selection">
      <mat-chip-row [removable]="removable" *ngFor="let item of selectedItems" (removed)="removed(item)">
        <div class="chip-row">
          <mat-slide-toggle *ngIf="canToggle" color="primary" (change)="toggle($event.checked, item.id)"
                            [checked]="item.exist">
          </mat-slide-toggle>
          <div class="toggle-chip" id="chip-name">
            {{item.name}}
          </div>
          <span *ngIf="item?.range?.minValue || item?.range?.maxValue">
            ({{ item?.range?.minValue }} - {{ item?.range?.maxValue }})
          </span>
          <div>
            <mat-icon class="icon-size-transform" *ngIf="canFilterByConfidence && item.exist" (click)="openSelectConfidenceDialog(item.id)">
              filter_list
            </mat-icon>
          </div>
          <button matChipRemove [attr.aria-label]="'remove ' + item" (click)="clickRemove(item)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </mat-chip-row>
    </mat-chip-grid>
    <input [placeholder]="placeholder" #itemInput [formControl]="itemControl"
           [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="selected($event)"
           (keydown)="backSpacePropagation($event)"/>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{item.name ? item.name : item}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
