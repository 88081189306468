import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Dictionary, LabelAnnotations } from 'src/app/core/models/Annotations';
import { Label } from 'src/app/core/models/Label';
import { ModelDeployment } from 'src/app/core/models/ModelDeployment';
import { Highlight, RangeSelected } from 'src/app/core/models/models';
import { Offset } from 'src/app/core/models/Offset';
import { SecurityLabelAuthorization } from 'src/app/core/models/security-label-authorization';
import { AuthorizedPermission } from 'src/app/core/models/security-permissions';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ParagraphReviewService } from 'src/app/core/services/paragraph-review.service';
import { ProvisionService } from 'src/app/core/services/provision.service';
import { AppState } from 'src/app/root-store/state';
import { CommentDialogComponent } from 'src/app/shared/dialogs/comment-dialog/comment-dialog.component';
import { environment } from 'src/environments/environment';
import {
  LabelRequirementSuggestionDialogComponent
} from '../label-requirement-suggestion-dialog/label-requirement-suggestion-dialog.component';
import { SelectModelDialogComponent } from '../select-model-dialog/select-model-dialog.component';
import {
  AddParagraphSuggestionLabels,
  CheckParagraphUpdate, DocumentIdChanged,
  HideParagraph,
  ParagraphCheckChanged,
  RejectParagraph,
  SetActiveLabel,
  UnCheckParagraphUpdate,
  UnhideParagraph,
  UpdateResult,
  ZoomToParagraphIds,
} from '../store/actions';
import { ParagraphSearchResult, SearchTypeEnum, SelectedItem } from '../store/reducer';
import { ReviewView } from '../../core/models/ReviewView';
import { Router } from '@angular/router';
import { Conflict, ConflictGroup } from '../../core/models/Conflict';
import { getSelectedConflictGroup } from '../../model-review/store/selectors';
import { getSelectedConflictGroup as getDatasetSelectedConflictGroup } from '../../dataset/store/selectors';
import { MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';
import { SecurityLabelAuthorizationsService } from 'src/app/core/services/security-label-authorizations.service';
import { User } from 'src/app/core/models/User';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { FeatureFlags } from 'src/app/core/models/feature-flags';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { getParagraphById, getReviewView, getSearchText, getSearchType } from '../store/selectors';
import { selectRootStateLabels } from '../../root-store/selectors';

@Component({
  selector: 'app-paragraph-view',
  templateUrl: './paragraph-view.component.html',
  styleUrls: ['./paragraph-view.component.scss'],
})
export class ParagraphViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() paragraphId: number;
  @Input() tags: string[];
  @Input() activeLabel: Label;
  @Input() showMetaData: boolean;
  @Input() checkAllState: boolean;
  @Input() checkedParagraphIds: number[] = [];
  @Input() unCheckedParagraphIds: number[] = [];
  @Input() modelDeployments: ModelDeployment[];
  @Output() classifyProvision = new EventEmitter<[number, number]>();
  @Input() ontology: string;
  paragraph: ParagraphSearchResult;
  allLabels: Label[];
  highlightText: Subject<Highlight> = new Subject();
  searchText: string;
  searchType: SearchTypeEnum;
  modelDeployment: ModelDeployment;
  deploymentEnvironment: string;
  selectedConflict: Conflict;
  currentUser: User;
  disableConfirmButton = false;
  removedLabelId: number = null;

  public permissions = AuthorizedPermission;
  public annotations: Dictionary<LabelAnnotations[]>;
  public paragraphLabels: LabelAnnotations[] = [];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public selectedItems: SelectedItem[] = [];
  public securityLabelAuthorization: SecurityLabelAuthorization = null;
  public selected = false;
  public paragraphLoaded = false;
  public reviewView: ReviewView;
  public ReviewViewEnum = ReviewView;
  public sharedLabelNames: { [key: number]: string[] } = {};
  public featureFlags: Observable<FeatureFlags>;

  trackByFn: TrackByFunction<LabelAnnotations> = (_, item) => item.labelId;

  constructor(
    public dialog: MatDialog,
    private provisionService: ProvisionService,
    private notificationService: NotificationService,
    private paragraphReviewService: ParagraphReviewService,
    private authorizationService: SecurityLabelAuthorizationsService,
    private featureFlagsService: FeatureFlagsService,
    private clipboard: Clipboard,
    private store: Store<AppState>,
    public router: Router,
  ) { }

  ngOnInit() {
    this.featureFlags = this.featureFlagsService.featureFlags$;
    this.deploymentEnvironment = environment.deploymentEnvironment;
    combineLatest([this.store.select(getParagraphById(this.paragraphId)), this.store.select(selectRootStateLabels)])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([paragraph, labels]) => {
        if (paragraph) {
          this.allLabels = labels;
          this.paragraph = paragraph;
          this.paragraphLabels = labels.length && this.computeParagraphLabels(paragraph.labels);
          this.mapTags(paragraph.tags);
          if (this.activeLabel && paragraph.labels[this.activeLabel.id] !== undefined) {
            this.highlightText.next({
              annotations: paragraph.labels[this.activeLabel.id],
              text: this.paragraph.text,
              uniqueId: this.paragraph.id,
            });
          } else {
            this.highlightText.next({
              annotations: [],
              text: this.paragraph.text,
              uniqueId: this.paragraph.id,
            });
          }
          if (this.modelDeployments) {
            this.modelDeployment = this.modelDeployments.find(md => md.id === this.paragraph.modelDeploymentId);
          }
        }
        setTimeout(() => (this.paragraphLoaded = true));
      });

    this.paragraphReviewService.userAnnotationRemoved$.subscribe(ranges => {
      if (ranges.uniqueId !== this.paragraph.id || this.paragraph.isConfirmed) {
        return;
      }
      this.removeAnnotations(ranges.offsets);
    });

    this.store.pipe(select(getSearchText), takeUntil(this.ngUnsubscribe)).subscribe(text => {
      this.searchText = text;
    });

    this.store.pipe(select(getSearchType), takeUntil(this.ngUnsubscribe)).subscribe(type => {
      this.searchType = type;
    });

    this.store.pipe(select(getReviewView), takeUntil(this.ngUnsubscribe)).subscribe(reviewView => {
      this.reviewView = reviewView;
      let selectedConflictGroup$: Observable<ConflictGroup>;
      if (this.reviewView === ReviewView.DatasetSideBySideConflict) {
        selectedConflictGroup$ = this.store.pipe(select(getDatasetSelectedConflictGroup));
      } else {
        selectedConflictGroup$ = this.store.pipe(select(getSelectedConflictGroup));
      }

      selectedConflictGroup$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(conflictGroup => {
        if (conflictGroup) {
          if (this.paragraphId === conflictGroup.parent.paragraphId) {
            this.selectedConflict = conflictGroup.parent;
          } else {
            this.selectedConflict = conflictGroup.childConflicts.find(
              conflict => conflict.paragraphId === this.paragraphId,
            );
          }
        }
      });
    });

    this.store
      .select(s => s.rootState.currentUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
      });
  }

  /**
   * Shared samples only need to display labels that are shared
   * This method filters and updates paragraph labels base on the ontology's labels, it also
   * store the original name of the label and update paragraph.labels for highlighting.
   */
  updateSharedSampleLabels(paragraphLabels: LabelAnnotations[]): LabelAnnotations[] {
    if (!this.allLabels.length) {
      return;
    }
    const paragraphLabelDict = {};
    const filteredParagraphLabels = paragraphLabels.filter(pl => {
      // if it is a machine suggested label, we don't want to show it, only user labels are shown in the
      // side by side view
      if (pl.isSuggestion) {
        return false;
      }
      // filter all the labels to know which ones have a shared label
      const allLabelsFiltered = this.allLabels.filter(l => {
        const sharedLabelsFiltered = l.sharedLabels.filter(sl => {
          if (sl.id === pl.labelId) {
            const plComplete = l && l.sharedLabels.find(sl => sl.id === pl.labelId);
            const plName = plComplete && plComplete.name;
            if (this.sharedLabelNames[l.id]) {
              this.sharedLabelNames[l.id].push(plName);
            } else {
              this.sharedLabelNames[l.id] = [plName];
            }
            // replace the source label with the label that belongs to the current ontology
            pl.name = l.name;
            pl.labelId = l.id;
            if (paragraphLabelDict[pl.labelId]) {
              paragraphLabelDict[pl.labelId].push(pl);
              return false;
            } else {
              paragraphLabelDict[pl.labelId] = [pl];
            }
            return true;
          } else {
            return false;
          }
        });
        // filter the labels that have shared labels
        return sharedLabelsFiltered.length;
      });
      // filter the paragraph labels that have labels filtered
      return allLabelsFiltered.length;
    });
    this.paragraph = { ...this.paragraph, labels: { ...this.paragraph.labels, ...paragraphLabelDict } };
    return filteredParagraphLabels;
  }

  ngOnChanges(changes) {
    if (changes.activeLabel && this.paragraph) {
      if (changes.activeLabel.currentValue == null) {
        this.activeLabel = null;
        this.highlightText.next({
          annotations: [],
          text: this.paragraph.text,
          uniqueId: this.paragraph.id,
        });
      } else {
        this.activeLabel = changes.activeLabel.currentValue;
        if (
          this.paragraph &&
          this.paragraph.labels &&
          this.paragraph.labels[changes.activeLabel.currentValue.id] != null
        ) {
          this.highlightText.next({
            annotations: this.paragraph.labels[changes.activeLabel.currentValue.id],
            text: this.paragraph.text,
            uniqueId: this.paragraph.id,
          });
        } else {
          this.highlightText.next({
            annotations: [],
            text: this.paragraph.text,
            uniqueId: this.paragraph.id,
          });
        }
      }
    }

    if (changes.modelDeployments) {
      this.modelDeployments = changes.modelDeployments.currentValue;
      if (this.modelDeployments && this.paragraph) {
        this.modelDeployment = this.modelDeployments.find(md => md.id === this.paragraph.modelDeploymentId);
      }
    }
  }

  public classify() {
    const classifyParagraphsDialog = this.dialog.open(SelectModelDialogComponent, {
      width: '500px',
      data: this.modelDeployments.filter(m => m.current),
    });
    classifyParagraphsDialog.afterClosed().subscribe(data => {
      if (data) {
        this.classifyProvision.emit([this.paragraph.id, data.modelDeploymentId]);
        this.notificationService.showInfo('Paragraph has been queued for classification');
      }
    });
  }

  public removeSuggestions() {
    this.store.dispatch(new AddParagraphSuggestionLabels([], this.paragraph.id));
  }

  public addAnnotation(rangeSelected: RangeSelected) {
    if (rangeSelected.uniqueId !== this.paragraph.id) {
      return;
    }
    if (this.paragraph.isConfirmed) {
      // this.notificationService.showError('Please unconfirm paragraph to change annotations.');
      return;
    }

    const end = rangeSelected.offset.end;
    const length = this.paragraph.text.length;
    const offsets: Offset[] = [{ ...rangeSelected.offset, end: end >= length ? length - 1 : end }];

    this.provisionService
      .addParagraphAnnotation({ provisionId: this.paragraph.id, labelId: this.activeLabel.id, annotations: offsets })
      .subscribe(() => { });
  }

  public timePassed(date: any) {
    return moment(date).format('dddd, MMMM Do YYYY - hh:mm a');
  }

  paragraphTagAdded(event) {
    this.provisionService.addProvisionTags(this.paragraph.id, event.addedItem.name).subscribe(() => {
      this.notificationService.showSuccess('Tag Added');
    });
  }

  paragraphTagRemoved(event) {
    this.provisionService.removeProvisionTags(this.paragraph.id, event.removedItem.name).subscribe(() => {
      this.notificationService.showSuccess('Tag Removed');
    });
  }

  toggleParagraphVisibility(hide: boolean) {
    if (hide) {
      this.hideParagraph();
    } else {
      this.unhideParagraph();
    }
  }

  hideParagraph() {
    this.provisionService.hideParagraph(this.paragraphId).subscribe(() => {
      this.store.dispatch(new HideParagraph(this.paragraphId));
      this.notificationService.showSuccess('Paragraph has been hidden');
    });
  }

  unhideParagraph() {
    this.provisionService.unhideParagraph(this.paragraphId).subscribe(() => {
      this.store.dispatch(new UnhideParagraph(this.paragraphId));
      this.notificationService.showSuccess('Paragraph has been unhidden');
    });
  }

  rejectParagraph() {
    this.dialog
      .open(CommentDialogComponent, {
        width: '450px',
        data: { isRequired: true, dialogTitle: 'Reject Paragraph' },
      })
      .afterClosed()
      .subscribe(response => {
        if (response) {
          this.store.dispatch(new RejectParagraph(this.paragraph.id, response.comment, response.responseType));
        }
      });
  }

  showHighlight(label: LabelAnnotations) {
    if (this.activeLabel == null) {
      this.highlightText.next({
        annotations: this.paragraph.labels ? this.paragraph.labels[label.labelId] : [],
        text: this.paragraph.text,
        uniqueId: this.paragraph.id,
      });
    }
  }

  unHighlight() {
    if (this.activeLabel == null) {
      this.highlightText.next({
        annotations: [],
        text: this.paragraph.text,
        uniqueId: this.paragraph.id,
      });
    }
  }

  selectionChanged(label: LabelAnnotations) {
    const labelObj = this.allLabels.find(l => l.id === label.labelId);
    this.setActiveLabel(labelObj);
  }

  removeAnnotations(offsets: Offset[]) {
    if (!this.paragraph.isConfirmed) {
      this.provisionService
        .removeUserAnnotation({ provisionId: this.paragraph.id, labelId: this.activeLabel.id, annotations: offsets })
        .subscribe(() => { });
    }
  }

  addLabel(label) {
    if (this.paragraph.isConfirmed) {
      this.notificationService.showError('Please unconfirm paragraph to change annotations.');
      return;
    }
    if (!this.paragraph.labels[label.labelId] || this.paragraph.labels[label.labelId].length === 0) {
      return;
    }
    const offsets = this.paragraph.labels[label.labelId]
      .filter(s => s.isSuggestion)
      .map(s => {
        const length = this.paragraph.text.length;
        const end = s.end >= length ? length - 1 : s.end;
        return { start: s.start, end, confidence: 1 }
      });

    this.provisionService
      .addParagraphAnnotation({ provisionId: this.paragraph.id, labelId: this.activeLabel.id, annotations: offsets })
      .subscribe(() => { });
  }

  getLatestData() {
    this.provisionService.searchParagraph(this.paragraph.id).subscribe(paragraph => {
      this.store.dispatch(new UpdateResult(paragraph));
    });
  }

  removeLabel() {
    if (this.paragraph.isConfirmed) {
      this.notificationService.showError('Please unconfirm paragraph to change annotations.');
      return;
    }
    this.removedLabelId = this.activeLabel.id;
    this.provisionService
      .removeUserAnnotation({ provisionId: this.paragraph.id, labelId: this.activeLabel.id, annotations: [] })
      .pipe(finalize(() => (this.removedLabelId = null)))
      .subscribe();
  }

  computeParagraphLabels(annotations: Dictionary<LabelAnnotations[]>) {
    const labelsMap = new Map<string, LabelAnnotations>();
    for (const annotationsList of Object.values(annotations) as LabelAnnotations[][]) {
      for (const annotation of annotationsList as LabelAnnotations[]) {
        const key = `${annotation.labelId}-${annotation.isSuggestion}`;
        if (!labelsMap.has(key)) {
          let newAnnotation = { ...annotation };
          if (this.allLabels.length) {
            const label = this.allLabels.find(l => l.id === annotation.labelId);
            newAnnotation.name = label?.name;
          }
          labelsMap.set(key, newAnnotation);
        }
      }
    }
    const labels = Array.from(labelsMap.values());
    labels.sort((a, b) => a.extractorId - b.extractorId);
    if (this.showAsSharedSample()) {
      return this.updateSharedSampleLabels(labels);
    } else {
      return labels;
    }
  }

  public mapTags(paragraphTags: string[]) {
    this.selectedItems = [...paragraphTags.map(tag => ({ name: tag }))];
  }

  private mapLabelIdsToName(labelIdMap: any, labels: Label[]) {
    const labelNameMap = new Map<string, string>();
    Object.keys(labelIdMap).forEach(labelId => {
      const paragraphLabel = labels.find(s => s.id === parseInt(labelId, null));
      const labelReq = labelIdMap[labelId];
      const labelNames = labelReq.map(s => labels.find(q => q.id === s).name);
      labelNameMap.set(paragraphLabel.name, labelNames.join(', '));
    });
    return labelNameMap;
  }

  confirmClick() {
    this.disableConfirmButton = true;
    if (this.paragraph.isConfirmed) {
      if (this.paragraph.isExemplar) {
        this.unconfirmExemplar();
      } else {
        this.callUnconfirm();
      }
    } else {
      this.provisionService.confirmParagraph(this.paragraph.id).subscribe(message => {
        const suggestion = this.mapLabelIdsToName(message.item2, this.allLabels);
        if (!message.item1) {
          this.dialog
            .open(LabelRequirementSuggestionDialogComponent, {
              width: '500px',
              data: { suggestion },
            })
            .afterClosed()
            .subscribe(() => {});
        } else {
          this.notificationService.showSuccess('Paragraph Confirmed Successfully');
        }
        this.disableConfirmButton = false;
      });
    }
  }

  private unconfirmExemplar() {
    const unconfirmExemplarDialog = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: `You are about to unconfirm an <b>Exemplar Paragraph</b>. Unconfirming this paragraph will remove its status as an exemplar. <br><br> Are you sure you want to proceed?`,
    });

    unconfirmExemplarDialog.afterClosed().subscribe(result => {
      if (result) {
        this.callUnconfirm();
      } else {
        this.disableConfirmButton = false;
      }
    });
  }

  private callUnconfirm() {
    this.provisionService.unconfirmParagraph(this.paragraph.id).subscribe(() => {
      this.notificationService.showSuccess('Paragraph Unconfirmed Successfully');
      this.paragraph.isExemplar = false;
      this.disableConfirmButton = false;
    });
  }

  zoomTo(paragraphId) {
    this.store.dispatch(new ZoomToParagraphIds([paragraphId]));
  }

  checkChange(event: MatCheckboxChange) {
    this.paragraph = {...this.paragraph, isChecked: event.checked};
    this.store.dispatch(new ParagraphCheckChanged(this.paragraph.id, event.checked));
    if (this.checkAllState) {
      if (event.checked) {
        this.unCheckedParagraphIds = this.unCheckedParagraphIds.filter(s => s !== this.paragraph.id);
      } else {
        this.unCheckedParagraphIds = [...this.unCheckedParagraphIds, this.paragraph.id];
      }
      this.store.dispatch(new UnCheckParagraphUpdate(this.unCheckedParagraphIds));
    } else {
      if (event.checked) {
        this.checkedParagraphIds = [...this.checkedParagraphIds, this.paragraph.id];
      } else {
        this.checkedParagraphIds = this.checkedParagraphIds.filter(s => s !== this.paragraph.id);
      }
      this.store.dispatch(new CheckParagraphUpdate(this.checkedParagraphIds));
    }
  }

  setActiveLabel(label: Label) {
    this.store.dispatch(new SetActiveLabel(label));
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkSetExemplar(event) {
    if (event.checked) {
      this.provisionService.addExemplar(this.paragraphId).subscribe(response => {
        if (response.status === 200) {
          this.notificationService.showSuccess('Successfully added paragraph as exemplar');
        } else {
          this.notificationService.showError('Error occurred while attempting to add paragraph as an exemplar');
        }
      });
    } else {
      this.provisionService.deleteExemplar(this.paragraphId).subscribe(response => {
        if (response.status === 200) {
          this.notificationService.showSuccess('Successfully removed paragraph as exemplar');
        } else {
          this.notificationService.showError('Error occurred while attempting to remove paragraph as an exemplar');
        }
      });
    }
  }

  getMatCardClass() {
    const cssClasses = {};
    switch (this.reviewView) {
      case ReviewView.ParagraphSearch:
        cssClasses['paragraph-card'] = true;
        break;
      case ReviewView.SideBySideConflict:
      case ReviewView.DatasetSideBySideConflict:
        cssClasses['paragraph-card'] = true;
        if (this.selectedConflict && this.selectedConflict.similarityScore) {
          cssClasses['paragraph-card-child'] = true;
        } else if (this.selectedConflict && this.selectedConflict.conflictingScore) {
          cssClasses['paragraph-card-parent'] = true;
        }
        break;
    }
    return cssClasses;
  }

  navigateToOntology() {
    this.authorizationService.get(this.currentUser.id).subscribe(response => {
      if (response.status === 200) {
        const securityLabels: SecurityLabelAuthorization[] = response.body.securityLabels;
        if (securityLabels.filter(item => item.name === this.selectedConflict.ontology).length) {
          const link =
            window.location.href + `?ontology=${this.selectedConflict.ontology}&paragraphId=${this.paragraphId}`;
          window.open(link, '_blank');
        } else {
          this.dialog.open(MessageDialogComponent, {
            data: {
              title: 'Message',
              message: `You do not have access to view this ontology (${this.selectedConflict.ontology}).`,
              icon: 'warning',
            },
          });
        }
      }
    });
  }

  getMachineLabelsClasses(label) {
    const cssClasses = {};
    if (this.activeLabel && this.activeLabel.id === label.labelId) {
      cssClasses['active-label'] = true;
      if (label.extractorId) {
        cssClasses['machine-extractor-label'] = true;
      } else {
        cssClasses['machine-label'] = true;
      }
    } else {
      if (label.extractorId) {
        cssClasses['machine-extractor-inactive-label'] = true;
      } else {
        cssClasses['machine-inactive-label'] = true;
      }
    }
    return cssClasses;
  }

  showAsSharedSample() {
    return (
      (this.reviewView === ReviewView.SideBySideConflict || this.reviewView === ReviewView.DatasetSideBySideConflict) &&
      this.selectedConflict &&
      this.selectedConflict.ontology !== this.ontology
    );
  }

  getSourceLabelName(labelId: number) {
    if (!this.showAsSharedSample()) {
      return;
    }
    if (this.sharedLabelNames[labelId]) {
      return `Source Label Name(s): ${this.sharedLabelNames[labelId]}`;
    }
  }

  searchByDocumentId(documentId: number) {
    this.store.dispatch(new DocumentIdChanged(documentId));
  }

  copyToClipboard(value: string) {
    this.clipboard.copy(value);
    this.notificationService.showInfo('Document id copied to clipboard');
  }
}
