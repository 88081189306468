export enum AuthorizedPermission {
    Create = "Create",
    Edit = "Edit",
    Archive = "Archive",
    Delete = "Delete",
    TenantSearch = "TenantSearch",
    Dashboard = "Dashboard",
    Extractor = "Extractor",
    View = "View",
    NotDefined = 99
  }