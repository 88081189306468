<div *ngIf="selectedConflictGroup" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="1em">
  <div fxFlex="50" fxLayout="column" fxLayoutGap="1em" class="scroll-search">
    <div>
      <app-paragraph-search
        [paragraphIds]="[selectedConflictGroup.parent.paragraphId]"
        [ontology]="ontology"
        (showDefinitionAndExamplesEvent)="showLabelDefinitionAndSamples($event)"
      ></app-paragraph-search>
    </div>
    <div fxLayoutAlign="space-between center">
      <button
        [disabled]="sortedParentIds.indexOf(selectedConflictGroup.parent.paragraphId) === 0 && conflictFilters.page === 1"
        (click)="changeConflict(-1)"
        mat-stroked-button
      >
        View Previous Conflicting Sample
      </button>
      <button
        [disabled]="isNextDisabled()"
        (click)="changeConflict(1)"
        mat-stroked-button
      >
        View Next Conflicting Sample
      </button>
    </div>
  </div>
  <div fxFlex="50" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="space-around start" class="paragraph-container" fxLayoutGap="1em">
      <span class="conflict">{{ selectedConflictGroup.childConflicts.length }} conflicts with paragraph</span>
      <span class="ontologies-used">
        {{ ontologiesUsed }} {{ ontologiesUsed !== 1 ? 'Ontologies' : 'Ontology' }} Used
      </span>
    </div>
    <div class="paragraph-container">
      <app-paragraph-search
        [isParent]="false"
        [ontology]="ontology"
        [paragraphIds]="childIds"
      ></app-paragraph-search>
    </div>
  </div>
</div>
