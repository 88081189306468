<p>
  <mat-form-field style="width:100%;" floatLabel="{{forcePlaceHolder?'never':'auto'}}">
    <mat-select [formControl]="itemMultiCtrl" placeholder="Select {{itemType}}" [multiple]="isMultiple" #multiSelect
      disableOptionCentering="true" (openedChange)="openChange.emit($event)">
      <mat-option>
        <ngx-mat-select-search [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'no matching value found!'"
          [formControl]="itemMultiFilterCtrl" [showToggleAllCheckbox]="showToggleAllCheckBox"
          (toggleAll)="toggleAll.emit($event)" [toggleAllCheckboxChecked]="toggleAllCheckboxChecked">
        </ngx-mat-select-search>
      </mat-option>
      <mat-select-trigger *ngIf="forcePlaceHolder">Select {{itemType}}</mat-select-trigger>
      <mat-option *ngFor="let bank of filteredItemsMulti | async" [value]="bank">
        {{bank.displayName ? bank.displayName: bank.name}}{{bank.email? ' (' + bank.email + ')': ''}}
      </mat-option>
    </mat-select>
    <button *ngIf="isMultiple ? selectedItems?.length > 0 : itemMultiCtrl.value" matTooltip="Clear Selection"
      matTooltipPosition="above" matSuffix mat-icon-button aria-label="Clear"
      (click)="clearSelection(); $event.stopPropagation()">
      <mat-icon style="font-size:20px!important;">close</mat-icon>
    </button>
  </mat-form-field>
</p>
