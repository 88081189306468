import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateOperators } from 'src/app/core/models/models';
import { DateFilter, DateFilterOperatorEnum } from 'src/app/paragraph-review/store/reducer';
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  @Input() placeholder: string;
  @Input() selected: DateFilter;

  @Output() dateFilterChanged = new EventEmitter<DateFilter>();

  public dateOperators = DateOperators;
  constructor() {}

  ngOnInit() { }

  onChangeDate(event: MatDatepickerInputEvent<any, any>) {
    if (event?.value) {
      this.selected = { ...this.selected, date: event.value };
    } else {
      this.selected = { ...this.selected, date: new Date() };
    }
    this.dateFilterChanged.emit(this.selected);
  }

  onChangeOperator(event: MatSelectChange) {
    if (event?.value in DateFilterOperatorEnum) {
      this.selected = {...this.selected, operator: event.value}
    }
    this.dateFilterChanged.emit(this.selected);
  }
}
