import { AdminStateActions, AdminStateActionType } from "./actions";
import { AdminState } from "./state";

export const initAdminState: AdminState = {
  identityUsers: [],
  securityGroups: [],
  securityLabels: []
};

export const adminReducer = (state = initAdminState, action: AdminStateActions): AdminState => {
  switch (action.type) {
    case AdminStateActionType.SetSecurityGroups: {
      return {
        ...state,
        securityGroups: action.payload
      };
    }
    case AdminStateActionType.SetIdentityUsers: {
      return {
        ...state,
        identityUsers: action.payload
      };
    }
    case AdminStateActionType.SetSecurityLabels: {
      return {
        ...state,
        securityLabels: action.payload
      };
    }
    case AdminStateActionType.AddSecurityGroup: {
      return {
        ...state,
        securityGroups: [...state.securityGroups, action.payload]
      };
    }
    case AdminStateActionType.UpdateSecurityGroup: {
      return {
        ...state,
        securityGroups: [...state.securityGroups.filter(s => s.id != action.payload.id), action.payload]
      };
    }
    case AdminStateActionType.DeleteSecurityGroup: {
      return {
        ...state,
        securityGroups: [...state.securityGroups.filter(s => s.id != action.payload.id)]
      };
    }
    default:
      return state;
  }
}

export function adminStateReducer(state: AdminState | undefined, action: AdminStateActions) {
  return adminReducer(state, action);
}
