import { Injectable, NgZone } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationActionComponent } from 'src/app/shared/components/notification-action/notification-action.component';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar, private bottomSheet: MatBottomSheet, private zone: NgZone) { }

  showSuccess(message) {
    this.openSnackBar(message, 'green-snackbar');
  }

  showError(message) {
    this.openSnackBar(message, 'red-snackbar');
  }

  showInfo(message) {
    this.openSnackBar(message, 'blue-snackbar');
  }

  showAction(data, message) {
    return this.openSnackBarWithAction(data, message, 'pizza-party');
  }

  openSnackBar(message: string, type: string) {
    this.zone.run(() => {
      this.snackBar.open(message, null, {
        duration: 4000,
        panelClass: type,
      });
    });
  }

  openSnackBarWithAction(data: any, message: string, type: string) {
    data.message = message;
    data.action = 'Refresh';
    return this.snackBar.openFromComponent(NotificationActionComponent, {
      duration: 10000,
      panelClass: type,
      data,
    });
  }

  openActionNotification(data: any, message: string) {
    const sheetRef = this.bottomSheet.open(NotificationActionComponent, {
      data: { ...data, message },
    });
    return sheetRef.afterDismissed();
  }
}
