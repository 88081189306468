<h2 mat-dialog-title>{{ data.name }}</h2>
<mat-dialog-content>
  <mat-form-field class="option-field">
  <mat-label>Select confidence</mat-label>
  <mat-select (selectionChange)="onSelectionChange($event)" [formControl]="selectedValue">
    <mat-select-trigger>
      <span>
        {{ !selectedValue?.value?.minValue && !selectedValue?.value?.maxValue ? 'None' : selectedValue?.value.minValue + ' - ' + selectedValue?.value.maxValue }}
      </span>
    </mat-select-trigger>
    <mat-option [value]="null"> None </mat-option>
    <mat-option *ngFor="let range of ranges" [value]="range"> {{ range.minValue }} - {{ range.maxValue }} </mat-option>
    <mat-option [value]="customValue" (click)="onCustomOptionClick()">
      <span>Custom</span>
      <div class="custom-inputs">
        <mat-form-field class="custom-input">
          <mat-label>From</mat-label>
          <input
            matInput
            type="number"
            (click)="onCustomClick($event)"
            (input)="getNewCustomValue($event, true)"
            [value]="customValue.minValue"
            [step]="step"
            [max]="maxValue"
            [min]="minValue"
            (keyup)="onCustomOptionClick()"
          />
        </mat-form-field>
        <mat-form-field class="custom-input">
          <mat-label>To</mat-label>
          <input
            matInput
            type="number"
            (click)="onCustomClick($event)"
            (input)="getNewCustomValue($event, false)"
            [value]="customValue.maxValue"
            [step]="step"
            [max]="maxValue"
            [min]="minValue"
            (keyup)="onCustomOptionClick()"
          />
        </mat-form-field>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="selectedValue?.value" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>
