import { AbstractControl } from '@angular/forms';

export function checkForEmptyStrings(control: AbstractControl) {
  if (control.value) {
    if (!control.value.replace(/\s/g, '').length) {
      return { isSpaces: true };
    }
  }
  return null;
}

export function checkForMinLength() {
  return null;
}
