<div *ngIf="!paragraph && paragraphLoaded && (this.reviewView === ReviewViewEnum.SideBySideConflict || this.reviewView == ReviewViewEnum.DatasetSideBySideConflict)"
     fxLayout="column" fxLayoutAlign="space-around center">
  <img
    alt='Not found'
    class='anchor--middle push--ends'
    src='https://app.optimizely.com/static/img/p13n/page-list-empty-state.svg'
    width='450' />
  <h1 id="no-samples-title">Sample {{paragraphId}} was not found</h1>
</div>
<mat-card appearance="outlined" *ngIf="paragraph" [fxLayout]="reviewView === ReviewViewEnum.ParagraphSearch ? 'row' : 'column'"
          fxLayoutGap="1em" class="paragraph-card"
          [ngClass]='getMatCardClass()'>
  <div fxFlex="65" fxLayout="column" fxLayoutGap="1em">
    <div *ngIf='(reviewView === ReviewViewEnum.SideBySideConflict || reviewView === ReviewViewEnum.DatasetSideBySideConflict)
                && selectedConflict && selectedConflict.ontology !== ontology'>
      <button mat-button (click)="navigateToOntology()" class="return-button" color="primary">
        <span>{{selectedConflict.ontologyName}}</span>
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
    <div fxLayout="column" fxLayoutGap="1em" [ngClass]='{"external-samples": showAsSharedSample()}'
         id='card-content-para-{{paragraphId}}'>
      <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;" fxLayoutGap="1em">
        <ng-container *ngIf='reviewView === ReviewViewEnum.ParagraphSearch'>
          <mat-checkbox [checked]="paragraph?.isChecked" (change)="checkChange($event)" color="primary">
          </mat-checkbox>
        </ng-container>
        <p>
          <span class="mat-h3">Paragraph Id: </span>{{paragraph.id}}
        </p>
        <div *ngIf="paragraph.documentId">
          <a class="copy-id" matTooltip="Copy document id to clipboard" (click)="copyToClipboard(paragraph.documentId.toString(10))">
            <mat-icon>file_copy</mat-icon>
          </a>
          <span class="mat-h3">Document Id:&nbsp;</span>
          <a [matTooltip]="'Filter to document ' + paragraph.documentId" class="mat-h3 link-id" (click)="searchByDocumentId(paragraph.documentId)">{{paragraph.documentId}}</a>

        </div>
        <p *ngIf="reviewView === ReviewViewEnum.ParagraphSearch && paragraph.confoundingScore">
          <span class="mat-h3">Confounding Score:</span>
          {{paragraph.confoundingScore.toFixed(2)}}
        </p>
        <p *ngIf="selectedConflict?.similarityScore">
          <span class="mat-h3">Similarity Score:</span>
          {{selectedConflict.similarityScore.toFixed(2)}}
        </p>
        <p *ngIf="selectedConflict?.conflictingScore">
          <span class="mat-h3">Conflicting Score:</span>
          {{selectedConflict.conflictingScore.toFixed(2)}}
        </p>
        <p *ngIf="searchType == '2' && searchText">
          <span class="mat-h3">Similarity Score:</span>
          {{paragraph.score.toFixed(2)}}
        </p>
        <mat-chip-listbox>
          <mat-chip-option *ngIf="paragraph.isExemplar" class='exemplar-pill' data-testid='exemplar-chip'>
            Exemplar
            <mat-chip-trailing-icon matTooltip='This sample was defined as an example for labelling by a Subject Matter Expert. To remove it from the exemplars, go to the 3 dots menu on the right of the sample.' class='info-icon'>
              <mat-icon>info</mat-icon>
            </mat-chip-trailing-icon>
          </mat-chip-option>
          <mat-chip-option *ngIf="paragraph.isHidden" class='hidden-pill' disabled data-testid='hidden-chip'>Hidden</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div *ngIf="paragraph.isModified" fxFlex="50" fxLayoutAlign="start center">
        <p style="font-weight: 500;">This result has been changed since last retrieved! | </p>
        <mat-icon style="line-height: 25px;" (click)="getLatestData()" color="primary">refresh</mat-icon>
      </div>
      <div fxLayout="row" style="width: 100%; word-break: break-word">
        <ali-highlight [uniqueId]="paragraph.id" [provisionText]="paragraph.text" [showHighlight]="highlightText"
          (rangeSelected)="addAnnotation($event)"></ali-highlight>
      </div>
      <div *ngIf="paragraph.description && showMetaData" data-testid='description'>
        <p><span class="mat-h3">Description: </span>{{paragraph.description}}</p>
      </div>
      <div *ngIf="paragraph.linkUrl && showMetaData" data-testid='linkURL'>
        <p><span class="mat-h3">LinkURL: </span><a target="_blank" href="{{paragraph.linkUrl}}">View in
            ThoughtTrace</a></p>
      </div>
      <div *ngIf="paragraph.createdOn && showMetaData" data-testid='uploaded-on'>
        <p><span class="mat-h3">Uploaded On: </span>{{timePassed(paragraph.createdOn)}}</p>
      </div>
      <div *ngIf="paragraph.modelDeploymentId && showMetaData && modelDeployment" data-testid='model'>
        <p><span class="mat-h3">Model Used For Prediction: </span>
          <ng-container *ngIf="modelDeployment.type === 'Standard'">
            {{deploymentEnvironment}} - {{modelDeployment.modelId}} - {{modelDeployment.endpoint}}
          </ng-container>
          <ng-container *ngIf="modelDeployment.type === 'Custom'">
            {{modelDeployment.workspace}} - {{modelDeployment.modelId}}
          </ng-container>
        </p>
      </div>
      <div *ngIf="paragraph.isConfirmed && showMetaData" data-testid='confirmed-on'>
        <p><span class="mat-h3">Confirmed On: </span>{{timePassed(paragraph.confirmedOn)}} by
          {{paragraph.confirmedByName}}</p>
      </div>
      <div *ngIf="reviewView === ReviewViewEnum.ParagraphSearch && showMetaData" data-testid='tags'>
        <ali-tag-input
          [enforceSelection]="false" [selectedItems]="selectedItems" placeholder="Add Tags.."
          [dataSource]="tags" (itemAdded)="paragraphTagAdded($event)" (itemRemoved)="paragraphTagRemoved($event)">
        </ali-tag-input>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="1em">
    <ng-container *ngIf='reviewView === ReviewViewEnum.ParagraphSearch'>
      <h3>Machine Suggested Labels</h3>
      <mat-chip-listbox focused="false" #chipList>
        <mat-chip-option
          *ngFor="let label of paragraphLabels | machineLabel; trackBy: trackByFn"
          [ngClass]="getMachineLabelsClasses(label)"
          (click)="selectionChanged(label)"
          disableRipple="true"
          (mouseover)="showHighlight(label)"
          (mouseout)="unHighlight()"
        >
          <div class="mat-caption">{{label | labelValue}}</div>
          <mat-icon *ngIf="activeLabel != null && label.labelId === activeLabel.id && !showAsSharedSample()"
                    (click)="addLabel(label)" matChipRemove>
            add_circle
          </mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </ng-container>
    <div class="mt-15">
      <h3>User Applied Labels</h3>
      <mat-chip-listbox selectable="false">
        <mat-chip-option
          [ngClass]="
            activeLabel && label.labelId == activeLabel.id
              ? ['user-label', 'active-label']
              : 'user-inactive-label'
          "
          *ngFor="let label of (paragraphLabels | userLabel); trackBy: trackByFn"
          (click)="selectionChanged(label)" (mouseover)="showHighlight(label)" (mouseout)="unHighlight()"
          [matTooltip]="getSourceLabelName(label.labelId)" id="user-label-chip-{{label.labelId}}"
          disableRipple="true">
          {{label | labelValue}}
          <mat-icon *ngIf="activeLabel != null && label.labelId === activeLabel.id && !showAsSharedSample() && activeLabel.id!==removedLabelId"
                    (click)="removeLabel()" matChipRemove>
            cancel
          </mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <div *ngIf="paragraph?.suggestionLabels?.length > 0" class="mt-15">
      <div fxLayout="row" fxLayoutGap="0.7em">
        <div fxLayoutAlign="start center">
          <h3>You may want to apply these?</h3>
        </div>
        <div fxLayoutAlign="start center">
          <mat-icon (click)="removeSuggestions()" style="cursor: pointer;">
            clear
          </mat-icon>
        </div>
      </div>
      <mat-chip-listbox selectable="false">
        <mat-chip-option (click)="setActiveLabel(label)" *ngFor="let label of (paragraph.suggestionLabels | userLabel)"
          class='user-label' disableRipple="true">
          {{label | labelValue}}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
  <div fxFlex="7" fxLayout="column" [ngClass]='{"external-samples": showAsSharedSample()}'>
    <div *ngIf='reviewView === ReviewViewEnum.ParagraphSearch' fxFlex='80' fxLayoutAlign="end start">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon class="action-icon">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="classify()">
          <mat-icon>cloud_queue</mat-icon>
          <span>Classify</span>
        </button>
        <div
          matTooltip="Confirmed samples cannot be hidden"
          [matTooltipDisabled]="!paragraph.isConfirmed || paragraph.isHidden"
          matTooltipPosition="left"
        >
          <button mat-menu-item
            (click)="toggleParagraphVisibility(!paragraph.isHidden)"
            [disabled]="paragraph.isConfirmed && !paragraph.isHidden"
          >
            <mat-icon>{{paragraph.isHidden ? 'visibility' : 'visibility_off'}}</mat-icon>
			      <span>{{paragraph.isHidden ? 'Unhide' : 'Hide'}}</span>
		      </button>
        </div>
        <div
          matTooltip="Confirmed samples cannot be rejected"
          [matTooltipDisabled]="!paragraph.isConfirmed"
          matTooltipPosition="left"
        >
          <button mat-menu-item
            (click)="rejectParagraph()"
            [disabled]="paragraph.isConfirmed"
          >
            <mat-icon>thumb_down</mat-icon>
            <span>Reject</span>
          </button>
        </div>
        <mat-divider></mat-divider>
        <div
          mat-menu-item
          (click)="$event.stopPropagation()"
          [disabled]="!paragraph.isConfirmed"
          #tooltip="matTooltip"
          [matTooltipDisabled]="paragraph.isConfirmed"
          matTooltipPosition="left"
          matTooltip="Exemplars can only be set for confirmed samples"
        >
          <mat-checkbox
            [(ngModel)]="paragraph.isExemplar"
            [disabled]="!paragraph.isConfirmed"
            (change)= "checkSetExemplar($event)"
            color="primary"
          >
            <span>Set as Exemplar</span>
          </mat-checkbox>
        </div>
        <button mat-menu-item (click)="zoomTo(paragraph.id)" *ngIf="(featureFlags | async)?.TopicDiscovery">
          <mat-icon>zoom_in</mat-icon>
          <span>Zoom To</span>
        </button>
      </mat-menu>
    </div>
    <div fxFlex fxLayoutAlign="end end"
      matTooltip="Hidden samples cannot be confirmed"
      [matTooltipDisabled]="paragraph.isConfirmed || !paragraph.isHidden"
    >
      <button (click)="confirmClick()" mat-raised-button
        [disabled]="(!paragraph.isConfirmed && paragraph.isHidden) || disableConfirmButton"
        [color]="!paragraph.isConfirmed ? 'primary' : ''">{{paragraph.isConfirmed ? 'Unconfirm' : 'Confirm'}}
      </button>
    </div>
  </div>
</mat-card>
