import { ActionReducerMap } from '@ngrx/store';
import { Label } from '../core/models/Label';
import { Entity, Notification } from '../core/models/models';
import { Ontology } from '../core/models/Ontology';
import { User } from '../core/models/User';
import { paragraphReviewReducer, ParagraphReviewState } from '../paragraph-review/store/reducer';
import { reducer } from './reducer';
import { AdminState } from '../admin-features/store/state';
import { adminStateReducer } from '../admin-features/store/reducer';
import { IndexableMap } from '../core/models/indexable-map';
import { SecurityLabelAuthorization } from '../core/models/security-label-authorization';
import { modelReducer, ModelReviewState } from '../model-review/store/reducer';
import { documentUploadReducer } from '../document-upload/store/reducer';
import { DocumentUploadState } from '../document-upload/models/models';
import { datasetReducer, DatasetReviewState } from '../dataset/store/reducer';

export interface RootState {
  tags: string[];
  labels: Label[];
  ontology: string;
  ontologies: Ontology[];
  entities: Entity[];
  notifications: Notification[];
  notificationCount: number;
  allUsersSimplified: User[];
  currentUser: User;
  signalRInitialized: boolean;
  securityLabelAuthorizationsWithIds: IndexableMap<SecurityLabelAuthorization>
}

export interface AppState {
  rootState: RootState;
  paragraphReviewState: ParagraphReviewState;
  adminState: AdminState;
  modelReviewState: ModelReviewState;
  datasetReviewState: DatasetReviewState;
  documentUploadState: DocumentUploadState;
}

export const reducers: ActionReducerMap<AppState> = {
  rootState: reducer,
  paragraphReviewState: paragraphReviewReducer,
  adminState: adminStateReducer,
  modelReviewState: modelReducer,
  datasetReviewState: datasetReducer,
  documentUploadState: documentUploadReducer,
};
