import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SelectedItem } from 'src/app/paragraph-review/store/reducer';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import {
  SelectConfidenceDialogComponent
} from "../../dialogs/select-confidence-dialog/select-confidence-dialog.component";
import { Label } from "../../../core/models/Label";

@Component({
  selector: 'ali-tag-input',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit, OnChanges {
  @Input() dataSource: string[] | Label[] = [];
  @Input() selectedItems: SelectedItem[] = [];
  @Input() enforceSelection: boolean;
  @Input() placeholder: string;
  @Input() canToggle: boolean;
  @Input() canFilterByConfidence: boolean;
  @Input() removable = true;

  @Output() itemAdded = new EventEmitter<any>();
  @Output() itemRemoved = new EventEmitter<any>();
  @Output() itemToggle = new EventEmitter<any>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemControl = new FormControl('');
  filteredItems: Observable<SelectedItem[]>;
  readonly MAX_ITEMS = 20;
  ignoreBackspace = false;
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null | SelectedItem) => {
        let itemsNumber = 0;
        let itemString = '';
        if (typeof item !== 'string' && item?.name){
          itemString = item.name;
        } else if (typeof item === 'string') {
          itemString = item;
        }

        if (this.dataSource.length === 0) {
          return [];
        }

        return itemString
          ? this._filter(itemString)
          : (typeof this.dataSource[0] === 'string'
              ? (this.dataSource as string[]).filter((item) => {
                if (itemsNumber < this.MAX_ITEMS && !this.selectedItems.find(i => i.name === item)) {
                  itemsNumber++;
                  return true;
                }
                return false;
              }).map(i => ({ name: i, id: i, exist: false }))
              : (this.dataSource as Label[]).filter((item) => {
                if (itemsNumber < this.MAX_ITEMS && !this.selectedItems.find(i => i.name === item.name)) {
                  itemsNumber++;
                  return true;
                }
                return false;
              }).map(i => ({ ...i, exist: false }))
          );
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource) {
      this.itemControl.setValue(this.itemControl.value);
    }
  }

  openSelectConfidenceDialog(id: number): void {
    const selectedItem = this.selectedItems.find(i => i.id === id);
    this.dialog.open(SelectConfidenceDialogComponent, {
      width: '350px',
      data: { value: selectedItem.range || {}, name: selectedItem.name },
    })
      .afterClosed()
      .subscribe((result: Range) => {
        this.rangeSelected(result, id);
      });
  }
  removed(item: SelectedItem) {
    if (this.ignoreBackspace) {
      return;
    }
    this.selectedItems = this.selectedItems.filter(i => i.id !== item.id);
    this.itemRemoved.emit({ selectedItems: this.selectedItems, removedItem: item });
  }

  toggle(checked: boolean, id: number) {
    this.selectedItems = this.selectedItems.map(i => {
      if (i.id === id) {
        const item = { ...i, exist: checked };
        if (!checked && item.range) {
          item.range = null;
        }
        return item;
      }
      return i;
    });
    this.itemRemoved.emit({ selectedItems: this.selectedItems });
  }

  rangeSelected(event: Range, id: number) {
    this.selectedItems = this.selectedItems.map(i => {
      if (i.id === id) {
        return { ...i, range: event } as SelectedItem;
      }
      return i;
    });
    this.itemAdded.emit({ selectedItems: this.selectedItems });
  }

  backSpacePropagation($event: KeyboardEvent) {
    const input = $event.target as HTMLInputElement;

    if ($event.key === 'Backspace' && input.value === '') {
      this.ignoreBackspace = true;
      $event.stopPropagation();
      $event.preventDefault();
    } else {
      this.ignoreBackspace = false;
    }
  }


  selected(event: MatAutocompleteSelectedEvent | MatChipInputEvent): void {
    const value = event instanceof MatAutocompleteSelectedEvent ? event.option.viewValue : (event.value || '').trim();
    if (!value || this.dataSource.length === 0) {
      return;
    }
    let item: string | Label;
    if (typeof this.dataSource[0] === 'string') {
      const stringDataSource = this.dataSource as string[];
      item = stringDataSource.find(i => i === value);
    } else {
      const labelDataSource = this.dataSource as Label[];
      item = labelDataSource.find(i => i.name === value);
    }
    let newItem: SelectedItem;
    if (!item && this.enforceSelection) {
      this.itemInput.nativeElement.value = '';
      this.itemControl.setValue(null);
      return;
    }
    if (!item) {
      newItem = {name: value, id: value, exist: true};
    }
    else if (typeof item === 'string') {
      newItem = {name: item, id: item, exist: true};
    }
    else {
      newItem = {...item, exist: true};
    }
    this.selectedItems = [...this.selectedItems, newItem];
    this.itemAdded.emit({ selectedItems: this.selectedItems, addedItem: newItem});
    this.itemInput.nativeElement.value = '';
    this.itemControl.setValue(null);
  }

  private _filter(value: string): SelectedItem[] {
    const filterValue = value.toLowerCase();
    if (typeof this.dataSource[0] === 'string') {
      const stringDataSource = this.dataSource as string[];
      return stringDataSource.filter(item => item.toLowerCase().includes(filterValue)
        && !this.selectedItems.find(i => i.name === item))
        .map(i => ({ name: i, id: i, exist: false }));
    } else {
      const labelDataSource = this.dataSource as Label[];
      return labelDataSource.filter(item => item.name.toLowerCase().includes(filterValue)
        && !this.selectedItems.find(i => i.name === item.name));
    }
  }

  clickRemove(item: SelectedItem) {
    this.ignoreBackspace = false;
    this.removed(item);
  }
}
