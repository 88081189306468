<div fxLayout="column" fxLayoutGap="15px" class="dialog-margin">
  <div *ngIf="!isBulkUpload">
    <div>
      <h2 class="title-margin" mat-dialog-title>Create new sample for {{selectedOntology}}</h2>
    </div>
    <div [formGroup]="simpleUpload" fxLayout="column" fxLayoutAlign="space-evenly strech" fxLayoutGap="7px">
      <div>
        <mat-form-field>
          <textarea matInput [rows]="5" placeholder="Paragraph Text*" formControlName="text"></textarea>
        </mat-form-field>
        <mat-error *ngIf="!simpleUpload.get('text').pristine && simpleUpload.get('text').hasError('required')">
          Text is Required
        </mat-error>
        <mat-error *ngIf="simpleUpload.get('text').hasError('isSpaces')">
          Text contains only spaces
        </mat-error>
      </div>
      <div>
        <ali-tag-input [enforceSelection]="false" [selectedItems]="selectedTags" placeholder="Add Tags.."
                       [dataSource]="tags" (itemAdded)="tagsUpdated($event)" (itemRemoved)="tagsUpdated($event)">
        </ali-tag-input>
      </div>
      <div>
        <mat-form-field>
          <input matInput placeholder="Link URL" formControlName="linkUrl">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <input matInput placeholder="Description" formControlName="description">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field [matTooltip]="'LLM sample source, ' +  nonLLMGenerated  + ' for non-synthetically generated samples'">
          <mat-label>Sample Source</mat-label>
          <mat-select formControlName="source">
            <mat-option [value]="nonLLMGenerated">
              {{ nonLLMGenerated }}
            </mat-option>
            <mat-option *ngFor="let source of validSources$ | async" [value]="source">
              {{source}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="isBulkUpload">
    <div>
      <h2 mat-dialog-title>Create New Text Sample</h2>
    </div>
    <div fxLayout="column">
      <div fxLayout="column" fxLayoutGap="10px">
        <div>
          <div>
            <h3>Choose '.xlsx' or '.csv' file containing the following headers:</h3>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="2px">
          <div>
            <h4>-<b>Type: </b>numeric value (1 for Paragraph)</h4>
          </div>
          <div>
            <h4>-<b>Text: </b>the text to be loaded into the system</h4>
          </div>
          <div>
            <h4>-<b>Tags: </b>optional (comma delimited)</h4>
          </div>
          <div>
            <h4>-<b>Description: </b>optional text sample description</h4>
          </div>
          <div>
            <h4>-<b>Link URL: </b>optional ThoughtTrace Suite deep link</h4>
          </div>
          <div>
            <h4>-<b>Ontology: </b>Ontology the text belongs to. (For eg: ) <br />
              <ul>
                <li><b>MSA</b>: Master Service Agreement</li>
                <li><b>OGL</b>: Oil and Gas
                  Lease</li>
                <li><b>ROW</b>: Right of Way Agreement</li>
                <li><b>G&P</b>: Gathering & Processing Agreement</li>
                <li><b>JOA</b>: Joint Operating Agreement</li>
                <li><b>SCA</b>: Solar
                  Commercial Agreement</li>
                <li><b>TA</b>: Technology Agreement</li>
                <li><b>WL</b>: Wind Lease</li>
              </ul>
            </h4>
          </div>
          <div>
            <h4>-<b>Source</b>: optional (For eg: {{ (validSources$ | async)?.join(", ") }}, blank if not an LLM sample generated)</h4>
          </div>
        </div>
        <div>
          <form [formGroup]="bulkUpload">
            {{fileName}} <br>
            <button type="button" mat-raised-button (click)="fileInput.click()">
              Choose File <mat-icon color="primary" matSuffix>attach_file</mat-icon>
            </button>
            <input hidden formControlName="requiredFile" (change)="onFileSelected($event)" #fileInput type="file" id="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
            <mat-error *ngIf="bulkUpload.get('requiredFile').hasError('required') && bulkUpload.get('requiredFile').dirty">
              Please select a file
            </mat-error>
            <mat-error *ngIf="bulkUpload.get('requiredFile').hasError('incorrectFile')">
              Please select a file with the correct extension
            </mat-error>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-raised-button color="primary"
              [disabled]="isBulkUpload ? bulkUpload.status == 'INVALID' : simpleUpload.status == 'INVALID'"
              (click)="upload()">{{isBulkUpload ? 'Export' : 'Create'}}</button>
      <button mat-raised-button [mat-dialog-close]="">Cancel</button>
      <mat-slide-toggle [(ngModel)]="isBulkUpload" color="primary">Bulk Upload
      </mat-slide-toggle>
    </div>
  </div>
</div>
