<div class='review-main'>
  <div class='review-left-card'>
    <mat-card *ngIf="isFilterVisible">
      <mat-tab-group dynamicHeight
        animationDuration="2"
        disableRipple="true"
        mat-stretch-tabs
        (selectedIndexChange)='tabChange($event)'>
        <mat-tab label="Paragraphs">
          <div class="scroll-side-panel flex-column">
            <ng-container *ngIf='reviewView !== ReviewViewEnum.ClusterView'>
              <div class='flex-row'>
                <mat-chip class='dashboard-filter-chip'
                  *ngIf="isDashboardFilterApplied">
                  Dashboard Filter <mat-icon matTooltip="Remove dashboard filter"
                    (click)="removeDashboardFilter()"
                    matChipRemove>cancel</mat-icon>
                </mat-chip>
              </div>
              <button mat-raised-button
                      class="button-reset-search"
                      color="primary"
                      (click)="resetSearch()">
                Reset Search
              </button>
              <div class='separator'>
                SEARCH
              </div>
              <div class='flex-row'>
                <mat-button-toggle-group [value]="searchType.toString()" name="fontStyle" aria-label="Font Style"
                  #group="matButtonToggleGroup" class="mat-button-group">
                  <mat-button-toggle value="1" (change)="searchToggleChange($event.value)" #exact class="button-group">
                    <span [style.color]="searchType == searchTypeEnum.Exact ? 'white' : 'black'">Exact</span>
                  </mat-button-toggle>
                  <mat-button-toggle value="2" (change)="searchToggleChange($event.value)" #similar class="button-group" >
                    <span [style.color]="searchType == searchTypeEnum.Similar ? 'white' : 'black'">Similar</span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Paragraph text</mat-label>
                  <textarea matInput
                    placeholder=""
                    rows="4"
                    [(ngModel)]="contains"
                    (ngModelChange)="containsChange($event)"></textarea>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput
                    placeholder="Paragraph Id"
                    type="number"
                    [(ngModel)]="paragraphIdFilter"
                    (ngModelChange)="paragraphIdFilterChange($event);"/>
                </mat-form-field>
              </div>
              <div class='separator'>
                SORT BY
              </div>
              <div class='sort-by'>
                <div class='sort-by-field'>
                  <mat-form-field>
                    <mat-select [(ngModel)]="selectedSortField"
                      name="field"
                      (selectionChange)="sortFieldChange()">
                      <mat-option *ngFor="let field of sortByFields; trackBy: trackByFn"
                        [value]="field.value">
                        {{field.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class='sort-by-buttons'>
                  <button mat-mini-fab [class.sort-by-active]='selectedSortOder !== sortOrderEnum.Ascending' color="primary" (click)="sortOrderChange(sortOrderEnum.Ascending)" aria-label="Sort ascending">
                    <mat-icon>arrow_upward</mat-icon>
                  </button>
                  <button mat-mini-fab [class.sort-by-active]='selectedSortOder !== sortOrderEnum.Descending' color='primary' (click)="sortOrderChange(sortOrderEnum.Descending)" aria-label="Sort descending">
                    <mat-icon>arrow_downward</mat-icon>
                  </button>
                </div>
              </div>
              <div *ngIf="activeLabelSelection">
                <mat-select-autocomplete
                  name="label"
                  placeholder="Select a Machine Label"
                  (selectionChange)="sortLabelChange($event)"
                  [options]="labelsSorted" [display]="'name'"
                  [value]="'id'"
                  [disabled]="false">
                </mat-select-autocomplete>
              </div>
              <div class='separator'>
                FILTER BY
              </div>
              <div class='flex-row'>
                <mat-button-toggle-group
                  class='mat-button-group'
                  [value]="confirmedType.toString()"
                  name="fontStyle"
                  aria-label="Font Style"
                  #group="matButtonToggleGroup">
                  <mat-button-toggle
                    class='button-group'
                    value="1"
                    (change)="toggleChange($event.value, group)"
                    #confirmed>
                    <span [style.color]="confirmedType == confirmedTypeEnum.Confirmed ? 'white' : 'black'">Confirmed</span>
                  </mat-button-toggle>
                  <mat-button-toggle
                    class='button-group'
                    value="2"
                    (change)="toggleChange($event.value, group)"
                    #unconfirmed>
                    <span
                      [style.color]="confirmedType == confirmedTypeEnum.UnConfirmed ? 'white' : 'black'">Unconfirmed</span>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </ng-container>
            <mat-accordion multi>
              <ng-container *ngIf='reviewView !== ReviewViewEnum.ClusterView'>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="expandable-panel">
                      <span>Document Id</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <mat-form-field>
                      <input matInput
                        placeholder="Document Id"
                        type="number"
                        [(ngModel)]="documentIdFilter"
                        (ngModelChange)="documentIdFilterChange($event);"/>
                    </mat-form-field>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
              <mat-expansion-panel *ngIf="(featureFlags | async)?.TopicDiscovery" (opened)="loadChartData()"
                                   [expanded]='expandScatterPlot' (closed)='onClose()' >
                <mat-expansion-panel-header>
                  <mat-panel-title class="expandable-panel">
                    <span>Scatterplot</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <size-selection-view (change)="onChartSizeChange($event)">
                    <scatter-plot
                      [options]="options"
                      (selectionChange)="onChartSelectionChange($event)">
                    </scatter-plot>
                  </size-selection-view>
                  <chart-toolbar [reviewView]='reviewView'
                                 (action)="onChartActionChange($event)"
                                 (filters)="onChartFiltersChange($event)"
                                 (searchText)="onSearchText($event)">
                  </chart-toolbar>
                </ng-template>
              </mat-expansion-panel>
              <ng-container *ngIf='reviewView !== ReviewViewEnum.ClusterView'>
                <mat-expansion-panel
                  (opened)="showTagPanel = true"
                  (closed)="showTagPanel = false"
                  [expanded]="showTagPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title *ngIf="!showTagPanel" class="expandable-panel">
                      <span>Tag</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <ali-tag-input class="expandable-panel custom-tag"
                      [enforceSelection]="false"
                      [canToggle]="true"
                      [selectedItems]="selectedTags"
                      placeholder="Filter By Tag"
                      [dataSource]="tags"
                      (itemAdded)="tagsUpdated($event)"
                      (itemRemoved)="tagsUpdated($event)"
                      (itemToggle)="tagsUpdated($event)">
                    </ali-tag-input>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel
                  (opened)="showLabelPanel = true"
                  (closed)="showLabelPanel = false"
                  [expanded]="showLabelPanel">
                  <mat-expansion-panel-header>
                    <mat-panel-title *ngIf="!showLabelPanel" class="expandable-panel">
                      <span>Label</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent>
                    <div>
                      <ali-tag-input class="ml-tag expandable-panel"
                        [enforceSelection]="true"
                        [selectedItems]="selectedMachineLabels"
                        [canToggle]="true"
                        [canFilterByConfidence]="true"
                        placeholder="Filter By Machine Label"
                        [dataSource]="labels"
                        (itemAdded)="machineLabelUpdated($event)"
                        (itemRemoved)="machineLabelUpdated($event)">
                      </ali-tag-input>
                    </div>
                    <div>
                      <ali-tag-input style="width:100%" class="expandable-panel custom-tag"
                        [enforceSelection]="true"
                        [selectedItems]="selectedUserLabels"
                        [canToggle]="true"
                        placeholder="Filter By User Label"
                        [dataSource]="labels"
                        (itemAdded)="userLabelUpdated($event)"
                        (itemRemoved)="userLabelUpdated($event)">
                      </ali-tag-input>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel
                  (opened)="showDatePanel = true"
                  (closed)="showDatePanel = false"
                  [expanded]="showDatePanel">
                <mat-expansion-panel-header>
                  <mat-panel-title *ngIf="!showDatePanel" class="expandable-panel">
                    <span>Date</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent class='flex-column'>
                  <app-date-filter
                    [selected]="createdOn"
                    (dateFilterChanged)="createdOnChanged($event)"
                    placeholder='Created On'>
                  </app-date-filter>
                  <app-date-filter
                    [selected]="confirmedOn"
                    (dateFilterChanged)="confirmedOnChanged($event)"
                    placeholder='Confirmed On'>
                  </app-date-filter>
                </ng-template>
              </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
            <div class='hidden-toggle' *ngIf='reviewView !== ReviewViewEnum.ClusterView'>
              <span>Include Hidden Paragraphs</span>
              <mat-slide-toggle
                labelPosition="before"
                color="primary"
                [(ngModel)]="includeHiddenParagraphs"
                (ngModelChange)="toggleIncludeHiddenParagraphs($event)"></mat-slide-toggle>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Labels" *ngIf='reviewView !== ReviewViewEnum.ClusterView'>
          <ali-select-label *ngIf='loadSelectLabelComponent'
                            [dataSource]="labels"
                            (selectLabel)="selectLabel($event)"
                            (showDefinitionAndExamples)="showLabelDefinitionAndSamples($event)">
          </ali-select-label>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
  <div class='paragraph-search' [ngSwitch]='reviewView'>
    <div *ngSwitchCase="ReviewViewEnum.ParagraphSearch">
      <app-paragraph-search [modelDeployments]="modelDeployments" [paragraphIds]="paragraphs"
        [ontology]="selectedOntology"
        (showDefinitionAndExamplesEvent)="showLabelDefinitionAndSamples($event)"
        [tags]="tags"></app-paragraph-search>
    </div>
    <div *ngSwitchCase="ReviewViewEnum.ClusterView">
      <app-cluster-view
        [tags]='tags'
        [ontology]='selectedOntology'></app-cluster-view>
    </div>
    <div *ngSwitchCase='ReviewViewEnum.LabelDefinition'>
      <app-label-definition (backFn)="returnView($event)"
      [labelExampleInfo]="labelDefinitionAndSamples"
      [labelTitleText]="labelDefinitionAndSamples.labelText"></app-label-definition>
    </div>
    <div *ngSwitchCase='ReviewViewEnum.SideBySideConflict'>
      <app-model-review-selected-sample
        [labels]="labels"
        [ontology]="selectedOntology"
        (showDefinitionAndExamplesEvent)="showLabelDefinitionAndSamples($event)"
        [tags]="tags"></app-model-review-selected-sample>
    </div>
    <div *ngSwitchCase='ReviewViewEnum.DatasetSideBySideConflict'>
      <app-dataset-conflict-review-selected-sample
        [labels]="labels"
        [ontology]="selectedOntology"
        (showDefinitionAndExamplesEvent)="showLabelDefinitionAndSamples($event)"
        [tags]="tags"></app-dataset-conflict-review-selected-sample>
    </div>
  </div>
</div>
