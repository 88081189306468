import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartSize } from 'src/app/topic-coverage-discovery/models/TopicDiscoveryData';

@Component({
  selector: 'size-selection-view',
  templateUrl: './size-selection-view.component.html',
  styleUrls: ['./size-selection-view.component.scss']
})
export class SizeSelectionViewComponent implements OnInit, AfterViewInit {
  @Output('change') sizeChanged: EventEmitter<any> = new EventEmitter();
  @Input() fixed: boolean = true;
  size: typeof ChartSize = ChartSize;
  selectedSize: ChartSize = ChartSize.Medium;
  @ViewChild('card') card: ElementRef;
  @ViewChild('viewport', { read: ElementRef }) viewport: ElementRef;
  private minWidth: number;
  private minHeight: number;
  private maxWidth: number;
  private maxHeight: number;
  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setSizeValues();
    });
  }

  ngOnInit() {
  }

  onSizeChange(width: number, height: number) {
    this.sizeChanged.emit({ width: width, height: height });
  }

  onClick(size: ChartSize) {
    const parent = this.viewport.nativeElement.parentNode as HTMLElement;
    parent.removeChild(this.viewport.nativeElement);
    this.selectedSize = size;
    switch (this.selectedSize) {
      case ChartSize.Small:
        this.onSizeChange(this.minWidth, this.minHeight);
        break;
      case ChartSize.Medium:
        this.onSizeChange(this.maxWidth, this.maxHeight);
        break;
      default:
        break;
    }
    parent.appendChild(this.viewport.nativeElement);
  }

  setSizeValues() {
    this.minWidth = this.viewport.nativeElement.offsetWidth;
    this.minHeight = this.viewport.nativeElement.offsetHeight;
    this.maxWidth = this.card.nativeElement.offsetWidth;
    this.maxHeight = this.maxWidth;
    this.onClick(this.selectedSize);
  }


  onResize() {
    setTimeout(() => {
      this.setSizeValues();
    });
  }
}

