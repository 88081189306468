import { Dictionary, LabelAnnotations } from 'src/app/core/models/Annotations';
import { Label } from 'src/app/core/models/Label';
import { Entity } from 'src/app/core/models/models';
import { ProvisionSearchActions, ProvisionSearchActionType } from './actions';
import { ReviewView } from '../../core/models/ReviewView';
import { ClustersPageSize, ParagraphsPageSize } from '../../core/constants/li-constants';
import { ClusterMetadata } from '../../core/models/ClusterMetadata';
import { SortDirection } from '@angular/material/sort';
import { ChartActions } from '../../topic-coverage-discovery/charts/ChartOptions';

export interface ParagraphSearchFilters {
  ontology: string;
  paragraphId: number;
  contains: string;
  tags: SelectedItem[];
  userLabels: SelectedItem[];
  machineLabels: SelectedItem[];
  paragraphIds: number[];
  dashboardFilter: boolean;
  confirmedType: ParagraphConfirmedTypeEnum;
  searchType: SearchTypeEnum;
  createdOn: DateFilter;
  confirmedOn: DateFilter;
  includeHiddenParagraphs: boolean;
  clusterIds: number[];
  documentId: number;
}

export interface ParagraphSearchQuery extends ParagraphSearchFilters {
  page: number;
  top: number;
  sortByField: SortByFieldEnum;
  sortOrder: SortOrderEnum;
  sortValue: SelectedItem;
}

export enum SortByFieldEnum {
  UploadedOn = 0,
  ConfirmedOn = 1,
  ParagraphId = 2,
  SimilarityScore = 3,
  CreatedOn = 4,
  UpdatedOn = 5,
  Length = 6,
  ConfoundingScore = 7,
  MachineLabel = 8,
  ParagraphIndex = 9
}

export enum SortOrderEnum {
  Ascending = 0,
  Descending = 1,
}

export enum SortByLabelEnum {
  Name = 0,
  ConfirmedSamplesCount = 1,
}

export enum ParagraphConfirmedTypeEnum {
  All = 0,
  Confirmed = 1,
  UnConfirmed = 2,
}

export enum SearchTypeEnum {
  Exact = 1,
  Similar = 2,
  Advanced = 3,
}

export interface SelectedItem {
  id?: number | string;
  name?: string;
  exist?: boolean;
  range?: Range;
}

export interface LabelFilter {
  labelId: number;
  exist: boolean;
}

export interface DateFilter {
  date: Date;
  operator: DateFilterOperatorEnum;
}

export enum DateFilterOperatorEnum {
  AnyTime = 0,
  On = 1,
  Before = 2,
  After = 3,
}

export interface Range {
  minValue?: number;
  maxValue?: number;
}


export interface ParagraphReviewState {
  search: ParagraphSearchQuery;
  result: ParagraphSearchResults;
  annotations: Dictionary<LabelAnnotations[]>;
  activeLabel: Label;
  selectedEntity: Entity;
  isCheckAllChecked: boolean;
  checkedParagraphIds: number[];
  unCheckedParagraphIds: number[];
  isEasterEggActivated: boolean;
  showMetaData: boolean;
  reviewView: ReviewView;
  clusterState: ClusterState;
  zoomToParagraphIds: number[];
  zoomToClusterIds: number[];
  expandScatterPlotPanel: boolean;
  scatterPlotAction: ChartActions[];
}

export interface ClusterState {
  clusterSearch: ClusterSearch,
  clustersMetadata: ClusterMetadata[],
  totalClusters: number,
  clusterSelection: ClusterSelection,
}

export interface ClusterSelection {
  selectedClusters: number[],
  includeAll: boolean,
  excludedClusters: number[]
}
export interface ClusterSearch {
  top: number,
  page: number,
  sort: { active: string, direction: SortDirection },
}
export interface ParagraphSearchResults {
  paragraphs: Dictionary<ParagraphSearchResult>;
  paragraphIds: number[];
  total: number;
}

export interface ParagraphSearchResult {
  id: number;
  text: string;
  description: string;
  linkUrl: string;
  labels: Dictionary<LabelAnnotations[]>;
  tags: string[];
  isConfirmed: boolean;
  createdOn: Date;
  confirmedOn: Date;
  confirmedByName: string;
  isChecked: boolean;
  isModified: boolean;
  score: number;
  confoundingScore: number;
  suggestionLabels: Label[];
  modelDeploymentId: number;
  isExemplar: boolean;
  isHidden: boolean;
  clusterId: number;
  ontologyId: number;
  documentId: number;
}

export const initialParagraphSearchState: ParagraphReviewState = {
  search: {
    page: 1,
    tags: [],
    userLabels: [],
    machineLabels: [],
    paragraphIds: [],
    dashboardFilter: false,
    confirmedType: ParagraphConfirmedTypeEnum.All,
    searchType: SearchTypeEnum.Exact,
    ontology: 'OGL',
    paragraphId: null,
    documentId: null,
    top: ParagraphsPageSize,
    contains: '',
    sortByField: SortByFieldEnum.UploadedOn,
    sortOrder: SortOrderEnum.Descending,
    sortValue: null,
    createdOn: {
      date: new Date(),
      operator: DateFilterOperatorEnum.AnyTime,
    },
    confirmedOn: {
      date: new Date(),
      operator: DateFilterOperatorEnum.AnyTime,
    },
    includeHiddenParagraphs: false,
    clusterIds: [],
  },
  isEasterEggActivated: false,
  reviewView: ReviewView.ParagraphSearch,
  annotations: {},
  activeLabel: null,
  selectedEntity: null,
  isCheckAllChecked: false,
  checkedParagraphIds: [],
  unCheckedParagraphIds: [],
  showMetaData: true,
  result: {
    paragraphs: [],
    total: 0,
    paragraphIds: [],
  },
  zoomToParagraphIds: null,
  zoomToClusterIds: null,
  clusterState: {
    clusterSearch: {
      top: ClustersPageSize,
      page: 1,
      sort: { active: 'Score', direction: 'desc' },
    },
    clustersMetadata: [],
    totalClusters: 0,
    clusterSelection: {
      selectedClusters: [],
      includeAll: false,
      excludedClusters: [],
    },
  },
  expandScatterPlotPanel: false,
  scatterPlotAction: [ChartActions.Reset]
}

export const paragraphSearchReducer = (
  state = initialParagraphSearchState,
  action: ProvisionSearchActions,
): ParagraphReviewState => {
  switch (action.type) {
    case ProvisionSearchActionType.ContainsChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          contains: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.TagsChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          tags: action.payload,
        },
      };
    }
    // case ProvisionSearchActionType.TagFilterTypeChanged: {
    //     return {
    //         ...state,
    //         search: {
    //             ...state.search,
    //             tagFilterType: action.payload
    //         }
    //     };
    // }
    case ProvisionSearchActionType.PageChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          page: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.UserLabelChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          userLabels: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.RemoveUserLabelFromParagraphs: {
      const paragraphs = { ...state.result.paragraphs };
      const userLabelId = action.payload;
      let update = false;
      Object.keys(paragraphs).forEach(key => {
        const paragraph = paragraphs[key];
        if (paragraph.labels[userLabelId]) {
          const { [userLabelId]: deletedLabel, ...remainingLabels } = paragraph.labels;
          paragraph.labels = remainingLabels;
          update = true;
        }

      });
      if (update) {
        return {
          ...state,
          result: {
            ...state.result,
            paragraphs,
          }
        }
      }
      return state;
    }
    case ProvisionSearchActionType.MachineLabelChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          machineLabels: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.ParagraphIdsFetchSucceeded: {
      return {
        ...state,
        search: {
          ...state.search,
          paragraphIds: action.payload,
          dashboardFilter: true,
        },
      };
    }
    case ProvisionSearchActionType.SearchParagraphIds: {
      return {
        ...state,
        search: {
          ...state.search,
          top: action.pagination ? ParagraphsPageSize : action.ids.length || ParagraphsPageSize,
          paragraphIds: action.ids,
        },
      };
    }
    case ProvisionSearchActionType.SearchByClusterId: {
      return {
        ...state,
        search: {
          ...state.search,
          clusterIds: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.ClearSearchByClusterId: {
      return {
        ...state,
        search: {
          ...state.search,
          clusterIds: [],
        },
      };
    }
    case ProvisionSearchActionType.RemoveDashboardFilter: {
      return {
        ...state,
        search: {
          ...state.search,
          paragraphIds: [],
          dashboardFilter: false,
        },
      };
    }
    case ProvisionSearchActionType.CreatedOnChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          createdOn: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.ConfirmedOnChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          confirmedOn: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.LoadMoreComplete: {
      const { paragraphs } = action.payload;
      const paragraphsResult: Dictionary<ParagraphSearchResult> = {};
      for (const key of Object.keys(paragraphs)) {
        const paragraph = { ...paragraphs[key] };
        paragraph.isChecked = state.isCheckAllChecked
          ? !state.unCheckedParagraphIds.includes(paragraph.id)
          : state.checkedParagraphIds.includes(paragraph.id);
        paragraph.suggestionLabels = [];
        paragraphsResult[key] = paragraph;
      }

      return {
        ...state,
        result: {
          ...state.result,
          total: action.payload.total,
          paragraphs: Object.assign({}, paragraphsResult),
          paragraphIds: action.payload.paragraphIds,
        },
      };
    }
    case ProvisionSearchActionType.SearchClusterComplete: {
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          ...action.payload,
        },
      };
    }
    case ProvisionSearchActionType.ClusterPageChanged: {
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          clusterSearch: {
            ...state.clusterState.clusterSearch,
            page: action.payload,
          },
        },
      };
    }
    case ProvisionSearchActionType.ClusterSortOrderChanged: {
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          clusterSearch: {
            ...state.clusterState.clusterSearch,
            sort: action.payload,
          },
        },
      };
    }
    case ProvisionSearchActionType.ClusterSelected: {
      const selectedClusters = state.clusterState.clusterSelection.selectedClusters;
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          clusterSelection: {
            ...state.clusterState.clusterSelection,
            selectedClusters: [...selectedClusters, action.payload],
          },
        },
      };
    }
    case ProvisionSearchActionType.ClusterDeselected: {
      const selectedClusters = state.clusterState.clusterSelection.selectedClusters;
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          clusterSelection: {
            ...state.clusterState.clusterSelection,
            selectedClusters: selectedClusters.filter(sc => sc !== action.payload),
          },
        },
      };
    }
    case ProvisionSearchActionType.ClearSelectedClusters: {
      return {
        ...state,
        clusterState: {
          ...state.clusterState,
          clusterSelection: {
            ...state.clusterState.clusterSelection,
            selectedClusters: [],
          },
        },
      };
    }
    case ProvisionSearchActionType.SortOrderChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          sortOrder: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.SetReviewView: {
      return {
        ...state,
        reviewView: action.payload,
      };
    }

    case ProvisionSearchActionType.SortFieldChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          sortByField: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.SortLabelChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          sortValue: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.ConfirmedTypeChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          confirmedType: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.IncludeHiddenParagraphsChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          includeHiddenParagraphs: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.SearchOntologyChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          ontology: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.SearchComplete: {
      const paragraphs = action.payload.paragraphs;
      const newParagraphs: Dictionary<ParagraphSearchResult> = {};
      Object.keys(paragraphs).forEach(key => {
        newParagraphs[key] = Object.assign({}, paragraphs[key]);
        newParagraphs[key].isModified = false;
        newParagraphs[key].suggestionLabels = [];
      });
      return {
        ...state,
        result: {
          ...state.result,
          total: action.payload.total,
          paragraphs: Object.assign({}, newParagraphs),
          paragraphIds: action.payload.paragraphIds,
        },
        checkedParagraphIds: [],
        unCheckedParagraphIds: [],
        isCheckAllChecked: false,
      };
    }

    case ProvisionSearchActionType.SetAnnotations: {
      const paragraph = { ...state.result.paragraphs[action.paragraphId] };
      paragraph.labels = action.payload;
      paragraph.modelDeploymentId = action.modelDeploymentId;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: Object.assign({}, paragraph),
          },
        },
      };
    }
    case ProvisionSearchActionType.SetActiveLabel: {
      return {
        ...state,
        activeLabel: action.payload,
      };
    }

    case ProvisionSearchActionType.AddParagraphAnnotation: {
      return {
        ...state,
        annotations: [],
      };
    }
    case ProvisionSearchActionType.RemoveUserLabelAnnotation: {
      return {
        ...state,
      };
    }

    case ProvisionSearchActionType.RemoveUserLabel: {
      return {
        ...state,
      };
    }

    case ProvisionSearchActionType.ConfirmParagraph: {
      const paragraph = { ...state.result.paragraphs[action.payload] };
      paragraph.isConfirmed = true;
      paragraph.confirmedOn = action.confirmedOn;
      paragraph.confirmedByName = action.confirmedByName;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.payload]: Object.assign({}, paragraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.UnconfirmParagraph: {
      const paragraph = { ...state.result.paragraphs[action.payload] };
      paragraph.isConfirmed = false;
      paragraph.confoundingScore = null;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.payload]: Object.assign({}, paragraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.AddTagsToParagraph: {
      const paragraph = { ...state.result.paragraphs[action.paragraphId] };
      const tagsToBeAdded = action.tags.filter(tag => !paragraph.tags.includes(tag));
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: {...paragraph, tags: [...paragraph.tags, ...tagsToBeAdded]},
          },
        },
      };
    }

    case ProvisionSearchActionType.RemoveTagsFromParagraph: {
      const paragraph = { ...state.result.paragraphs[action.paragraphId] };
      const newTags = paragraph.tags.filter(tag => !action.tags.includes(tag));
      paragraph.tags = newTags;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: Object.assign({}, paragraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.HideParagraph: {
      const paragraph = { ...state.result.paragraphs[action.payload] };
      paragraph.isHidden = true;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.payload]: Object.assign({}, paragraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.UnhideParagraph: {
      const paragraph = { ...state.result.paragraphs[action.payload] };
      paragraph.isHidden = false;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.payload]: Object.assign({}, paragraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.ClassifyParagraphSet: {
      return {
        ...state,
      };
    }

    case ProvisionSearchActionType.SetSelectedEntity: {
      return {
        ...state,
        selectedEntity: action.payload,
      };
    }
    case ProvisionSearchActionType.ClearSearchFilters: {
      return {
        ...state,
        search: clearSearchFilters(state),
        checkedParagraphIds: [],
        unCheckedParagraphIds: [],
        isCheckAllChecked: false,
      };
    }
    case ProvisionSearchActionType.ParagraphIdFilterChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          paragraphId: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.DocumentIdChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          documentId: action.payload,
        },
      };
    }
    case ProvisionSearchActionType.CheckAllChanged: {
      const checkUpdated = { ...state.result.paragraphs };
      Object.keys(checkUpdated).forEach(key => {
        const paragraphChanged = { ...checkUpdated[key] };
        paragraphChanged.isChecked = action.payload;
        checkUpdated[key] = paragraphChanged;
      });

      return {
        ...state,
        isCheckAllChecked: action.payload,
        result: {
          ...state.result,
          paragraphs: Object.assign({}, checkUpdated),
        },
        checkedParagraphIds: [],
        unCheckedParagraphIds: [],
      };
    }
    case ProvisionSearchActionType.CheckPageItemsChanged: {
      const { paragraphIds, status } = action;
      const paragraphs = { ...state.result.paragraphs };
      paragraphIds.forEach(id => {
        paragraphs[id] = {...paragraphs[id], isChecked: status};
      });
      if (status) {
        const checked = [...new Set([...state.checkedParagraphIds.concat(paragraphIds)])];
        const unchecked = [...state.unCheckedParagraphIds].filter(id => !paragraphIds.includes(id));
        return {
          ...state,
          result: {
            ...state.result,
            paragraphs,
          },
          checkedParagraphIds: checked,
          unCheckedParagraphIds: unchecked,
        };
      } else {
        const checked = [...state.checkedParagraphIds].filter(id => !paragraphIds.includes(id));
        const unchecked = [...new Set([...state.unCheckedParagraphIds.concat(paragraphIds)])];
        return {
          ...state,
          result: {
            ...state.result,
            paragraphs,
          },
          checkedParagraphIds: checked,
          unCheckedParagraphIds: unchecked,
        };
      }
    }
    case ProvisionSearchActionType.UpdateResult: {
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraph.id]: Object.assign({}, action.paragraph),
          },
        },
      };
    }
    case ProvisionSearchActionType.ParagraphCheckChanged: {
      const paragraph = { ...state.result.paragraphs[action.paragraphId] };
      paragraph.isChecked = action.isChecked;

      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: paragraph,
          },
        },
      };
    }

    case ProvisionSearchActionType.ResultChanged: {
      const updatedParagraph = { ...state.result.paragraphs[action.paragraphId] };
      updatedParagraph.isModified = true;

      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: Object.assign({}, updatedParagraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.CheckParagraphUpdate: {
      return {
        ...state,
        checkedParagraphIds: [...action.payload],
      };
    }

    case ProvisionSearchActionType.UnCheckParagraphUpdate: {
      return {
        ...state,
        unCheckedParagraphIds: [...action.payload],
      };
    }

    case ProvisionSearchActionType.ResetSelection: {
      return {
        ...state,
        checkedParagraphIds: [],
        unCheckedParagraphIds: [],
        isCheckAllChecked: false,
      };
    }

    case ProvisionSearchActionType.SetSearchState: {
      return {
        ...state,
        search: Object.assign({}, action.payload),
      };
    }

    case ProvisionSearchActionType.SearchTypeChanged: {
      return {
        ...state,
        search: {
          ...state.search,
          searchType: action.payload,
        },
      };
    }

    case ProvisionSearchActionType.ToggleEasterEgg: {
      return {
        ...state,
        isEasterEggActivated: !state.isEasterEggActivated,
      };
    }

    case ProvisionSearchActionType.ToggleMetaData: {
      return {
        ...state,
        showMetaData: !state.showMetaData,
      };
    }

    case ProvisionSearchActionType.AddParagraphSuggestionLabels: {
      const updatedParagraph = { ...state.result.paragraphs[action.paragraphId] };
      updatedParagraph.suggestionLabels = state.isEasterEggActivated ? action.labels : [];
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: Object.assign({}, updatedParagraph),
          },
        },
      };
    }

    case ProvisionSearchActionType.UpdateConfoundingScore: {
      const paragraph = { ...state.result.paragraphs[action.paragraphId] };
      paragraph.confoundingScore = paragraph.isConfirmed ? action.confoundingScore : null;
      return {
        ...state,
        result: {
          ...state.result,
          paragraphs: {
            ...state.result.paragraphs,
            [action.paragraphId]: Object.assign({}, paragraph),
          },
        },
      };
    }
    case ProvisionSearchActionType.ZoomToParagraphIds: {
      return {
        ...state,
        zoomToParagraphIds: Object.assign([], action.payload),
      };
    }
    case ProvisionSearchActionType.ZoomToClusterIds: {
      return {
        ...state,
        zoomToClusterIds: Object.assign([], action.payload),
      };
    }
    case ProvisionSearchActionType.ExpandFilterPanel: {
      return {
        ...state,
        expandScatterPlotPanel: action.payload,
      }
    }
    case ProvisionSearchActionType.ScatterPlotAction: {
      return {
        ...state,
        scatterPlotAction: Object.assign([], action.payload)
      }
    }
    default:
      return state;
  }

  function clearSearchFilters(state: ParagraphReviewState): ParagraphSearchQuery {
    const currentDate = new Date();
    currentDate.setHours(5, 0, 0, 0);
    const confirmedDate = new Date();
    confirmedDate.setHours(5, 0, 0, 0);
    return {
      page: 1,
      tags: [],
      userLabels: [],
      machineLabels: [],
      paragraphIds: [],
      documentId: null,
      dashboardFilter: false,
      confirmedType: ParagraphConfirmedTypeEnum.All,
      searchType: SearchTypeEnum.Exact,
      ontology: state.search.ontology,
      paragraphId: null,
      top: ParagraphsPageSize,
      contains: '',
      sortByField: SortByFieldEnum.UploadedOn,
      sortOrder: SortOrderEnum.Descending,
      sortValue: null,
      createdOn: {
        date: currentDate,
        operator: DateFilterOperatorEnum.AnyTime,
      },
      confirmedOn: {
        date: confirmedDate,
        operator: DateFilterOperatorEnum.AnyTime,
      },
      includeHiddenParagraphs: false,
      clusterIds: state.search.clusterIds
    };
  }
};

export function paragraphReviewReducer(state: ParagraphReviewState | undefined, action: ProvisionSearchActions) {
  return paragraphSearchReducer(state, action);
}
