import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackResponseTypeEnum } from 'src/app/core/models/models';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent implements OnInit {
  public commentForm: UntypedFormGroup;
  public commentInput = '';
  public responses = FeedbackResponseTypeEnum;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.commentForm = this.formBuilder.group({
      comment: this.formBuilder.control(''),
      responseType: this.formBuilder.control(1, [Validators.required]),
    });
  }

  onSubmit() {
    this.dialogRef.close({ comment: this.commentInput, responseType: this.commentForm.get('responseType').value });
  }
}
