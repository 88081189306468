import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SignalRService } from 'src/app/core/events/hub/signalR-service';
import { Label } from 'src/app/core/models/Label';
import { BulkActionBasePayload } from 'src/app/core/models/models';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProvisionService } from 'src/app/core/services/provision.service';
import { UserService } from 'src/app/core/services/user.service';
import { OntologyChanged, SetEntities } from 'src/app/root-store/actions';
import { AppState } from 'src/app/root-store/state';
import {
  AddParagraphAnnotation,
  AddParagraphSuggestionLabels,
  AddTagsToParagraph,
  BulkFindConfoundingScores,
  BulkRejectParagraphs,
  BulkTagAdd,
  BulkTagRemove,
  BulkUnconfirmParagraph,
  ClassifyParagraphSet,
  ConfirmParagraph,
  ConfirmParagraphSet,
  CountChanged,
  ExpandScatterPlotPanel,
  HideParagraph,
  HideParagraphSet,
  LoadMoreComplete,
  LoadProvision,
  PageChanged,
  ProvisionSearchActionType,
  RejectParagraph,
  RemoveTagsFromParagraph,
  ResetSelection,
  ResultChanged,
  SearchClusterComplete,
  SearchComplete,
  SetAnnotations,
  UnconfirmParagraph,
  UnhideParagraph,
  UnhideParagraphSet,
  UpdateConfoundingScore,
} from './actions';
import { ParagraphReviewState } from './reducer';
import { ReviewView } from '../../core/models/ReviewView';
import { getParagraphReviewState } from './selectors';

@Injectable()
export class ProvisionSearchEffects {
  constructor(
    private actions$: Actions,
    private provisionService: ProvisionService,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private signalRService: SignalRService,
    private userService: UserService,
  ) { }

  loadProvision$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(
      ProvisionSearchActionType.LoadProvision,
      ProvisionSearchActionType.RefreshResults,
      ProvisionSearchActionType.SearchByClusterId,
    ),
    withLatestFrom(this.store$),
    map(appstate => appstate[1]),
    switchMap(appstate =>
      this.provisionService
        .searchParagraphs({ ...appstate.paragraphReviewState.search, ontology: appstate.rootState.ontology })
        .pipe(switchMap(provisions => [new SearchComplete(provisions)])),
    ),
  ));

  loadShareSearch$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(ProvisionSearchActionType.SetSearchState),
    withLatestFrom(this.store$),
    map(appstate => appstate[1]),
    switchMap(appstate => [new OntologyChanged(appstate.paragraphReviewState.search.ontology)]),
  ));

  loadMoreParagraphs$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(ProvisionSearchActionType.PageChanged),
    withLatestFrom(this.store$),
    map(appstate => appstate[1]),
    switchMap(appstate =>
      this.provisionService
        .searchParagraphs({ ...appstate.paragraphReviewState.search, ontology: appstate.rootState.ontology })
        .pipe(map(provisions => new LoadMoreComplete(provisions))),
    ),
  ));

  expandScatterPlot$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(
      ProvisionSearchActionType.ZoomToClusterIds,
      ProvisionSearchActionType.ZoomToParagraphIds,
    ),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(([, state]) => !state.expandScatterPlotPanel),
    map(() => new ExpandScatterPlotPanel(true)),
  ));

  loadClusters$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(
      ProvisionSearchActionType.SearchClusterMetadata,
      ProvisionSearchActionType.ClusterPageChanged,
      ProvisionSearchActionType.ClusterSortOrderChanged
    ),
    withLatestFrom(this.store$),
    map(appState => appState[1]),
    switchMap(appState =>
      this.provisionService
        .searchParagraphCluster({ ...appState.paragraphReviewState.clusterState.clusterSearch, ontologyId: appState.rootState.ontology })
        .pipe(map(clusterMetadata => new SearchClusterComplete(clusterMetadata))),
    ),
  ));

  resetSkip$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(
      ProvisionSearchActionType.TagsChanged,
      ProvisionSearchActionType.UserLabelChanged,
      ProvisionSearchActionType.MachineLabelChanged,
      ProvisionSearchActionType.ContainsChanged,
      ProvisionSearchActionType.TagFilterTypeChanged,
      ProvisionSearchActionType.ConfirmedTypeChanged,
      ProvisionSearchActionType.ConfirmedOnChanged,
      ProvisionSearchActionType.CreatedOnChanged,
      ProvisionSearchActionType.SortFieldChanged,
      ProvisionSearchActionType.SortOrderChanged,
      ProvisionSearchActionType.SortLabelChanged,
      ProvisionSearchActionType.ParagraphIdFilterChanged,
      ProvisionSearchActionType.SearchTypeChanged,
      ProvisionSearchActionType.IncludeHiddenParagraphsChanged,
      ProvisionSearchActionType.DocumentIdChanged
    ),
    switchMap(() => [new PageChanged(1), new ResetSelection(), new CountChanged(true)]),
  ));

  removeUserLabel$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(ProvisionSearchActionType.RemoveUserLabel),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    filter(state => state.result.total > 0),
    switchMap(appstate =>
      this.provisionService
        .removeUserAnnotation({
          provisionId: appstate.result.paragraphs[0].id,
          labelId: appstate.activeLabel.id,
          annotations: [],
        })
        .pipe(map(() => null)),
    ),
  ), { dispatch: false });

  removeUserAnnotation = createEffect(() => this.actions$.pipe(
    ofType<AddParagraphAnnotation>(ProvisionSearchActionType.RemoveUserLabelAnnotation),
    map(action => action.payload),
    tap(() => { }),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    switchMap(([payload, state]) => {
      return this.provisionService
        .removeUserAnnotation({
          provisionId: state.result.paragraphs[0].id,
          labelId: state.activeLabel.id,
          annotations: payload,
        })
        .pipe(
          map(() => {
            return null;
          }),
        );
    }),
  ), { dispatch: false });

  hideParagraphSet = createEffect(() => this.actions$.pipe(
    ofType<HideParagraphSet>(ProvisionSearchActionType.HideParagraphSet),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    switchMap(state => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.hideParagraphSet(payload).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for hiding.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  unhideParagraphSet = createEffect(() => this.actions$.pipe(
    ofType<UnhideParagraphSet>(ProvisionSearchActionType.UnhideParagraphSet),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    switchMap(state => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.unhideParagraphSet(payload).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for unhiding.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  unconfirmParagraphSet = createEffect(() => this.actions$.pipe(
    ofType<BulkUnconfirmParagraph>(ProvisionSearchActionType.BulkUnconfirmParagraph),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    switchMap(state => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.bulkUnconfirmParagraph(payload).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for unconfirming.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  bulkFindConfoundingScores = createEffect(() => this.actions$.pipe(
    ofType<BulkFindConfoundingScores>(ProvisionSearchActionType.BulkFindConfoundingScores),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    switchMap(state => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.bulkFindConfoundingScores(payload).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for finding confounding scores.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  classifySet = createEffect(() => this.actions$.pipe(
    ofType<ClassifyParagraphSet>(ProvisionSearchActionType.ClassifyParagraphSet),
    map(action => action.modelDeploymentId),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    switchMap(([modelDeploymentId, state]) => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.classifyManyProvisionsWithModel(payload, modelDeploymentId).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for classification.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  bulkTagAdd = createEffect(() => this.actions$.pipe(
    ofType<BulkTagAdd>(ProvisionSearchActionType.BulkTagAdd),
    map(action => action.tags),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    switchMap(([tags, state]) => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.bulkTagsAdd({ tags, searchQuery: payload }).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for adding tags');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  bulkRejectParagraphs = createEffect(() => this.actions$.pipe(
    ofType<BulkRejectParagraphs>(ProvisionSearchActionType.BulkRejectParagraphs),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    switchMap(([action, state]) => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService
        .bulkRejectParagraphs({ comment: action.comment, responseType: action.responseType, searchQuery: payload })
        .pipe(
          map(() => {
            this.notificationService.showInfo('Paragraphs have been queued for rejection');
            return null;
          }),
        );
    }),
  ), { dispatch: false });

  bulkTagRemove = createEffect(() => this.actions$.pipe(
    ofType<BulkTagRemove>(ProvisionSearchActionType.BulkTagRemove),
    map(action => action.tags),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    switchMap(([tags, state]) => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.bulkTagsRemove({ tags, searchQuery: payload }).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for removing tags');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  annotationsUpdated = createEffect(() => this.signalRService.annotationsChanged$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (this.userService.isLoggedInUser(event.performedByEmail)) {
        return new SetAnnotations(event.data.paragraphAnnotations, event.data.modelDeploymentId, event.data.provisionId);
      } else {
        return new ResultChanged(event.data.provisionId);
      }
    }),
  ));

  confoundingScoreUpdated = createEffect(() => this.signalRService.confoundingScoreChanged$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (this.userService.isLoggedInUser(event.performedByEmail)) {
        return new UpdateConfoundingScore(event.data.confoundingScore, event.data.provisionId);
      } else {
        return new ResultChanged(event.data.provisionId);
      }
    }),
  ));

  paragraphConfirmed = createEffect(() => this.signalRService.paragraphConfirmed$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (!this.userService.isLoggedInUser(event.performedByEmail)) {
        return new ResultChanged(event.data.provisionId);
      } else {
        return new ConfirmParagraph(event.data.provisionId, event.data.confirmedOn, event.data.confirmedByName);
      }
    }),
  ));

  paragraphSetConfirmed = createEffect(() => this.actions$.pipe(
    ofType<ConfirmParagraphSet>(ProvisionSearchActionType.ConfirmParagraphSet),
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    map(appstate => appstate[1]),
    switchMap(state => {
      const payload = this.getBulkBasePayload(state);
      return this.provisionService.confirmParagraphSet(payload).pipe(
        map(() => {
          this.notificationService.showInfo('Paragraphs have been queued for confirmation.');
          return null;
        }),
      );
    }),
  ), { dispatch: false });

  paragraphUnconfirmed = createEffect(() => this.signalRService.paragraphUnconfirmed$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (this.userService.isLoggedInUser(event.performedByEmail)) {
        return new UnconfirmParagraph(event.data.provisionId);
      } else {
        return new ResultChanged(event.data.provisionId);
      }
    }),
  ));

  paragraphHiddenUpdated = createEffect(() => this.signalRService.paragraphHiddenUpdated$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (!this.userService.isLoggedInUser(event.performedByEmail)) {
        return new ResultChanged(event.data.provisionId);
      } else {
        if (event.data.isHidden === true) {
          return new HideParagraph(event.data.provisionId);
        } else {
          return new UnhideParagraph(event.data.provisionId);
        }
      }
    }),
  ));

  tagsAddedToParagraph = createEffect(() => this.signalRService.paragraphTagAdded$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (this.userService.isLoggedInUser(event.performedByEmail)) {
        return new AddTagsToParagraph(event.data.provisionId, event.data.tags);
      } else {
        return new ResultChanged(event.data.provisionId);
      }
    }),
  ));

  tagsRemovedFromParagraph = createEffect(() => this.signalRService.paragraphTagRemoved$.pipe(
    withLatestFrom(this.store$.select(getParagraphReviewState)),
    filter(data => this.isProvisionInSearchResults(data[1], data[0].data.provisionId)),
    map(event => event[0]),
    map(event => {
      if (this.userService.isLoggedInUser(event.performedByEmail)) {
        return new RemoveTagsFromParagraph(event.data.provisionId, event.data.tags);
      } else {
        return new ResultChanged(event.data.provisionId);
      }
    }),
  ));

  entityCreated$ = createEffect(() => this.signalRService.entityCreated$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { entity } }, { rootState: { ontology, entities } }]) =>
      entity.ontology === ontology && !entities.map(s => s.id).includes(entity.id)
    ),
    map(([event, state]) => {
      return new SetEntities([...state.rootState.entities, event.data.entity]);
    }),
  ));

  entityUpdated$ = createEffect(() => this.signalRService.entityUpdated$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { entity } }, { rootState: { ontology, entities } }]) =>
      entity.ontology === ontology && entities.map(s => s.id).includes(entity.id)
    ),
    map(([event, state]) => {
      return new SetEntities([
        ...state.rootState.entities.filter(s => s.id !== event.data.entity.id),
        event.data.entity,
      ]);
    }),
  ));

  entityRemoved$ = createEffect(() => this.signalRService.entityRemoved$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { removedEntityId } }, { rootState: { entities } }]) =>
      entities.map(s => s.id).includes(removedEntityId)
    ),
    map(([event, state]) => {
      return new SetEntities([...state.rootState.entities.filter(s => s.id !== event.data.removedEntityId)]);
    }),
  ));

  fieldCreated$ = createEffect(() => this.signalRService.fieldCreated$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { field } }, { rootState: { ontology, entities } }]) =>
      field.ontology === ontology && entities.map(s => s.id).includes(field.entityId)
    ),
    map(([{ data: { field } }, { rootState: { entities } }]) => {
      const entity = entities.find(s => s.id === field.entityId);
      entity.fields = [...entity.fields, field];
      return new SetEntities([...entities.filter(s => s.id !== entity.id), entity]);
    }),
  ));

  fieldUpdated$ = createEffect(() => this.signalRService.fieldUpdated$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { field } }, { rootState: { ontology, entities } }]) =>
      field.ontology === ontology && entities.map(s => s.id).includes(field.entityId)
    ),
    map(([{ data: { field } }, { rootState: { entities } }]) => {
      const entityIndex = entities.findIndex(s => s.id === field.entityId);
      entities[entityIndex].fields = [...entities[entityIndex].fields.filter(s => s.id !== field.id), field]
        .sort((a, b) => a.name.localeCompare(b.name));
      return new SetEntities([...entities]);
    }),
  ));

  fieldRemoved$ = createEffect(() => this.signalRService.fieldRemoved$.pipe(
    withLatestFrom(this.store$),
    filter(([{ data: { removedField } }, { rootState: { ontology, entities } }]) =>
      removedField.ontology === ontology && entities.map(s => s.id).includes(removedField.entityId)
    ),
    map(([{ data: { removedField } }, { rootState: { entities } }]) => {
      const entityIndex = entities.findIndex(s => s.id === removedField.entityId);
      entities[entityIndex].fields = entities[entityIndex].fields.filter(s => s.id !== removedField.id);
      return new SetEntities([...entities]);
    }),
  ));

  rejectParagraph = createEffect(() => this.actions$.pipe(
    ofType<RejectParagraph>(ProvisionSearchActionType.RejectParagraph),
    switchMap(payload => {
      return this.provisionService
        .rejectParagraph(payload.provisionId, payload.rejectionReason, payload.responseType)
        .pipe(
          map(response => {
            this.notificationService.showSuccess(response.message);
            return new LoadProvision();
          }),
        );
    }),
  ));

  paragraphLabelSuggestionsChanged$ = createEffect(() => this.signalRService.paragraphLabelSuggestionsChanged$.pipe(
    withLatestFrom(this.store$),
    map(([event]) => {
      return new AddParagraphSuggestionLabels(event.data.labels, event.data.provisionId);
    }),
  ));

  private isProvisionInSearchResults(appstate: ParagraphReviewState, provisionId: number) {
    return Object.keys(appstate.result.paragraphs).some(key => key === provisionId.toString());
  }

  mapLabelIdsToName(labelIdMap: any, labels: Label[]) {
    const labelNameMap = new Map<string, string>();
    Object.keys(labelIdMap).forEach(labelId => {
      const paragraphLabel = labels.find(s => s.id === parseInt(labelId, null));
      const labelReq = labelIdMap[labelId];
      const labelNames = labelReq.map(s => labels.find(q => q.id === s).name);
      labelNameMap.set(paragraphLabel.name, labelNames.join(', '));
    });
    return labelNameMap;
  }

  private getBulkBasePayload(state: ParagraphReviewState) {
    if (state.reviewView === ReviewView.ClusterView) {
      const clusterIds = state.clusterState.clusterSelection.selectedClusters;
      return {
        searchQuery: {
          ...state.search,
          clusterIds: [...clusterIds]
        },
        paragraphIds: [],
        excludedParagraphIds: []
      } as BulkActionBasePayload;
    }
    return {
      searchQuery: state.search,
      paragraphIds: state.checkedParagraphIds,
      excludedParagraphIds: state.unCheckedParagraphIds,
    } as BulkActionBasePayload;
  }
}
