import { Injectable } from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, concat, of } from "rxjs";
import {
  UploadAppendedToQueue,
  FileArraySelected,
  FileSelected,
  DocumentUploadActionType,
  ValidUploadFormSubmitted,
  FullQueueStarted,
  FileUploadStarted,
  FileUploadSucceeded,
  FileUploadFailed,
  FileUploadFinalized,
  FullQueueCompleted,
  FileRejected
} from "./actions";
import { catchError, concatMap, filter, first, map, mergeMap } from "rxjs/operators";
import { MaxQueueLength, MaxFileSize } from '../constants/constants';
import { QueueProcessingState, RejectionReason, Upload } from "../models/models";
import { AppState } from "../../root-store/state";
import { queueStatus, uploads } from "./selectors";
import { DocumentService } from "../../core/services/document.service";
import { ContentTypes } from "../../core/constants/content-types";

@Injectable()
export class DocumentUploadEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private documentService: DocumentService
  ) { }

  // For convenience we'll allow the component to dispatch an array of files and map it for them
  onFileArraySelected$ = createEffect(() => this.actions$.pipe(
    ofType<FileArraySelected>(DocumentUploadActionType.FileArraySelected),
    concatMap(action =>
      combineLatest([
        of(action),
        this.store$.select(uploads(action.ontology)).pipe(first())
      ])
    ),
    mergeMap(([action, allUploads]) => {
      const slotsRemaining = MaxQueueLength - allUploads.length;
      const filesToQueue = action.files.slice(0, slotsRemaining);
      const filesToReject = action.files.slice(slotsRemaining, action.files.length);
      return concat(
        filesToQueue.map(file => new FileSelected(action.ontology, file)),
        filesToReject.map(file => new FileRejected(action.ontology, file, RejectionReason.QueueLength))
      );
    })
  ));

  onFileSelected$ = createEffect(() => this.actions$.pipe(
    ofType<FileSelected>(DocumentUploadActionType.FileSelected),
    map((action) => {
      if (action.file.size > MaxFileSize) {
        return new FileRejected(action.ontology, action.file, RejectionReason.Size);
      }
      if (action.file.type !== ContentTypes.Pdf) {
        return new FileRejected(action.ontology, action.file, RejectionReason.FileType)
      }
      return new UploadAppendedToQueue(action.ontology, new Upload(action.file));
    })
  ));

  onValidUploadFormSubmitted$ = createEffect(() => this.actions$.pipe(
    ofType<ValidUploadFormSubmitted>(DocumentUploadActionType.ValidUploadFormSubmitted),
    mergeMap(action => this.store$.select(queueStatus(action.ontology)).pipe(first())),
    filter(status => status.uploads.queued.length > 0),
    map(status => new FullQueueStarted(status.ontology))
  ));

  onFullQueueStarted = createEffect(() => this.actions$.pipe(
    ofType<FullQueueStarted>(DocumentUploadActionType.FullQueueStarted),
    mergeMap(action => this.store$.select(queueStatus(action.ontology)).pipe(first())),
    filter(status => !status.uploadIsInProgress && status.uploads.queued.length > 0),
    map(status => new FileUploadStarted(
      status.ontology,
      status.uploads.queued[0],
      status.tags
    ))
  ));

  onFileUploadStarted$ = createEffect(() => this.actions$.pipe(
    ofType<FileUploadStarted>(DocumentUploadActionType.FileUploadStarted),
    mergeMap(action =>
      this.documentService
        .upload(
          action.upload.file,
          action.ontology,
          action.tags
        )
        .pipe(
          map(() => new FileUploadSucceeded(action.ontology, action.upload)),
          catchError(error => of(new FileUploadFailed(action.ontology, action.upload, error)))
        )
    )
  ));

  onFileUploadSucceeded$ = createEffect(() => this.actions$.pipe(
    ofType<FileUploadSucceeded>(DocumentUploadActionType.FileUploadSucceeded),
    map(action => new FileUploadFinalized(action.ontology, action.upload, null))
  ));

  onFileUploadFailed$ = createEffect(() => this.actions$.pipe(
    ofType<FileUploadFailed>(DocumentUploadActionType.FileUploadFailed),
    map(action => new FileUploadFinalized(action.ontology, action.upload, action.error))
  ));

  onFileUploadFinalized$ = createEffect(() => this.actions$.pipe(
    ofType<FileUploadFinalized>(DocumentUploadActionType.FileUploadFinalized),
    mergeMap(action => this.store$.select(queueStatus(action.ontology)).pipe(first())),
    filter(status => status.queueProcessingState === QueueProcessingState.Full),
    map(status => {
      if (status.uploads.queued.length > 0) {
        return new FileUploadStarted(
          status.ontology,
          status.uploads.queued[0],
          status.tags
        );
      }
      return new FullQueueCompleted(status.ontology);
    })
  ));
}
