import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-label-requirement-suggestion-dialog',
  templateUrl: './label-requirement-suggestion-dialog.component.html',
  styleUrls: ['./label-requirement-suggestion-dialog.component.scss'],
})
export class LabelRequirementSuggestionDialogComponent implements OnInit {
  objectKeys = Object.keys;
  suggestions: any;
  constructor(
    public dialogRef: MatDialogRef<LabelRequirementSuggestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    dialogRef.disableClose = true;
    this.suggestions = this.data.suggestion;
  }

  ngOnInit() {}
}
