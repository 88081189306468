import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class AuthCallbackGuard  {
  constructor(private authService: AuthService) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.handleAuthentication();
    return;
  }
}
