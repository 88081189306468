export interface Dictionary<T> {
  [Key: number]: T;
}

export interface StringMap<T> {
  [Key: string]: T;
}

export interface LabelAnnotations extends Annotation {
  paragraphLabelId: number;
  labelId: number;
  name: string;
  confidence: number;
  createdById: number;
  createdOn: Date;
}

export enum HighlightClass {
  Machine = 'machine-highlight',
  User = 'user-highlight',
  MachineExtractor = 'machine-extractor-highlight',
  GptExtractor = 'gpt-extractor-highlight',
}

export interface Annotation {
  start: number;
  end: number;
  isSuggestion?: boolean;
  extractorId?: number;
  highlightClass?: HighlightClass;
}

export interface ParagraphLabels {
  annotations: Dictionary<LabelAnnotations>;
}
