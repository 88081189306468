<div class='flex-row'>
  <mat-form-field class='date-picker'>
    <input
      matNativeControl
      [matDatepicker]="picker"
      [placeholder]="placeholder"
      [value]="selected.date"
      (dateChange)="onChangeDate($event)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="selector">
    <mat-label>Operator</mat-label>
    <mat-select [value]="selected.operator" (selectionChange)="onChangeOperator($event)">
      <mat-option *ngFor="let operator of dateOperators" [value]="operator.value">
        {{ operator.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
