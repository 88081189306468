import { Directive, EventEmitter, Output, HostListener } from '@angular/core';
import { MaxFileSize, MaxQueueLength } from '../constants/constants';
import { ContentTypes } from '../../core/constants/content-types';
import { NotificationService } from '../../core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../root-store/state';
import { Upload } from '../models/models';
import { queue } from '../store/selectors';
import { takeUntil } from 'rxjs/operators';
import { selectRootStateOntology } from '../../root-store/selectors';
import { Subject } from 'rxjs';

@Directive({ selector: '[liFileDrop]' })
export class FileDropDirective {

  @Output() filesDropped = new EventEmitter<Array<File>>();
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private uploads: Upload[] = [];

  constructor(
    private store: Store<AppState>,
    private notificationService: NotificationService) {
    this.store.select(selectRootStateOntology).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(ontology => {
        this.store
          .select(queue(ontology))
          .pipe(
            select(queue => queue.uploads),
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe(uploads => {
            this.uploads = uploads;
          });
      }
    )
  }

  // Dragover listener
  @HostListener('dragover', ['$event'])
  public onDragOver(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Drop listener
  @HostListener('drop', ['$event'])
  public ondrop(evt): void {
    evt.preventDefault();
    evt.stopPropagation();

    const fileList = evt.dataTransfer.files;
    const fileArray: Array<File> = Array.from(fileList);

    const validFiles: File[] = [];
    let invalidFileTypeFlag = false;
    let invalidFileSizeFlag = false;
    for (const file of fileArray) {
      if (file.type === ContentTypes.Pdf && file.size <= MaxFileSize) {
        validFiles.push(file);
      } else {
        if (file.type !== ContentTypes.Pdf) {
          invalidFileTypeFlag = true;
          break;
        }
        if (file.size > MaxFileSize) {
          invalidFileSizeFlag = true;
          break;
        }
      }
    }
    if (invalidFileTypeFlag) {
      this.notificationService.showError(`Only .pdf files are allowed.`);
      return;
    }
    if (invalidFileSizeFlag) {
      this.notificationService.showError(`Max file size allowed is 1 GB.`);
      return;
    }
    if (this.uploads.length + validFiles.length > MaxQueueLength) {
      this.notificationService.showError('You can only upload 100 files at a time.');
      return;
    }
    if (validFiles.length > 0) {
      this.filesDropped.emit(validFiles);
    }
  }
}
