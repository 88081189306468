import { NgModule } from '@angular/core';
import { UploadDocumentsDialogComponent } from './components/upload-documents-dialog/upload-documents-dialog.component';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { DocumentUploadEffects } from './store/effects';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FileDropDirective } from './directives/file-drop.directive';
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({
  declarations: [
    UploadDocumentsDialogComponent,
    FileDropDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    SharedModule,
    EffectsModule.forFeature([DocumentUploadEffects])
  ]
})
export class DocumentUploadModule { }
