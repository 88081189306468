<app-action-bar
  [tags]='tags'
  [checkedClusterCount]='checkedClusterCount'></app-action-bar>
<!-- tag table -->
<div class='table-container mat-elevation-z8' id='scroll-search' fxLayout="column" fxLayoutAlign=" stretch">
  <table mat-table [dataSource]='dataSource' (matSortChange)='onClusterSortChange()' matSort class='mat-elevation-z8'>
    <ng-container matColumnDef='selected'>
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td *matCellDef='let row' mat-cell>
        <mat-checkbox color='primary'
          [(ngModel)]="row.selected"
          (change)="onSelectionChange($event, row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef='clusterId'>
      <th class='center-align' mat-header-cell *matHeaderCellDef>Cluster Id</th>
      <td class='cluster-id link-id mat-column-10 center-align' *matCellDef='let row' mat-cell>
        <a (click)="navigateToLabelSample(row.clusterId)">{{row.clusterId}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef='samplesNumber'>
      <th class='center-align' mat-header-cell *matHeaderCellDef mat-sort-header>Cluster Size <br> (Including Hidden)</th>
      <td class='cluster-id mat-column-10 center-align' *matCellDef='let row' mat-cell>{{row.samplesNumber}}</td>
    </ng-container>

    <ng-container matColumnDef='keywords'>
      <th mat-header-cell *matHeaderCellDef>Keywords</th>
      <td *matCellDef='let row' mat-cell>{{addSpacesAfterComma(row.keywords)}}</td>
    </ng-container>

    <ng-container matColumnDef='score'>
      <th class='center-align' mat-header-cell *matHeaderCellDef mat-sort-header>Coverage Score</th>
      <td class='cluster-id mat-column-10 center-align' *matCellDef='let row' mat-cell>
        {{percentageParse(row.score)}}%
        <span class='dot' [ngClass]="getScoreColor(row.score)"></span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef='displayedColumns; sticky: true'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns;'>
    </tr>
  </table>
</div>
<div
  fxLayout="row"
  fxLayoutGap="0.5em"
  class="search-footer">
  <div fxFlex="50"
       fxLayoutAlign="start center">
    <p style="font-weight: 500;">{{totalClusters}} Results Retrieved | </p>
    <button matTooltip="Refresh"
            class="footer-button"
            color="primary"
            (click)="refreshResults()"
            mat-icon-button>
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div fxFlex="50"
       fxLayoutAlign="end center"
       fxLayoutGap="0.5em">
    <button matTooltip="First Page"
            class="footer-button"
            color="primary"
            [disabled]="page === 1"
            (click)="firstPage()"
            mat-icon-button>
      <mat-icon>first_page</mat-icon>
    </button>
    <button matTooltip="Previous"
            class="footer-button"
            color="primary"
            [disabled]="page === 1"
            (click)="loadMore(loadDirectionEnum.previous)"
            mat-icon-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <p style="font-weight: 500;">
      <input style="width: 35px;text-align: center;"
             [(ngModel)]="page"
             [disabled]="totalClusters < ClustersPageSize"
             (input)="goToPage()">
      / {{totalClusters / ClustersPageSize > 1 ? totalPages : 1}}</p>
    <button matTooltip="Next"
            class="footer-button"
            color="primary"
            [disabled]="page === totalPages"
            (click)="loadMore(loadDirectionEnum.next)"
            mat-icon-button>
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button matTooltip="Last Page"
            class="footer-button"
            color="primary"
            [disabled]="page === totalPages"
            (click)="lastPage()"
            mat-icon-button>
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</div>
