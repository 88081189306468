export const environment = {
  production: true,
  v2Hosting: `#{V2Hosting}#`,
  developmentUrl: '#{DevelopmentUrl}#',
  identityDevelopmentUrl: `#{IdentityUrl}#`,
  redirectUrl: `#{RedirectUrl}#`,
  deploymentEnvironment: `#{DeploymentEnvironment}#`,
  appInsightsString: `#{AppInsightsString}#`,
  enableClickPlugin: `#{ClickPlugin}#`,
};
