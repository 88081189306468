import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LabelExample } from 'src/app/core/models/Label';
import { LoadDirectionEnum } from 'src/app/root-store/reducer';

@Component({
  selector: 'app-example-main',
  templateUrl: './example-main.component.html',
  styleUrls: ['./example-main.component.scss'],
})
export class ExampleMainComponent implements OnInit, OnChanges {
  @Input() samples: LabelExample[];
  @Input() titleText: string;
  @Output() backFn = new EventEmitter();
  public totalPages: number;
  public total: number;
  public page: number;
  public isResultsDeleted = false;
  public currentPage: LabelExample[];
  readonly PAGE_SIZE = 10;
  public next: LoadDirectionEnum = LoadDirectionEnum.next
  public previous: LoadDirectionEnum = LoadDirectionEnum.previous
  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.total = this.samples.length;
    this.totalPages = this.total > 0 ? Math.ceil(this.total / this.PAGE_SIZE) : 0;
    if (this.totalPages > 0) {
      this.firstPage();
    } else {
      this.page = 0;
    }
  }

  scrollToTop() {
    const myDiv = document.getElementById('scroll-search');
    myDiv.scrollTop = 0;
  }

  return() {
    this.backFn.emit()
  }


  firstPage() {
    this.page = 1;
    this.currentPage = this.samples.slice(0, this.PAGE_SIZE);
  }

  loadMore(action: LoadDirectionEnum) {
    if (action === LoadDirectionEnum.next) {
      this.page++;
    } else {
      this.page--;
    }
    this.goToPage();
  }

  goToPage() {
    if (this.total < this.PAGE_SIZE || !(this.page > 0)) {
      this.firstPage();
    }
    if (this.page >= this.totalPages) {
      this.lastPage();
    }
    if (this.page < this.totalPages) {
      const firstPositionInPage = this.page * this.PAGE_SIZE - (this.PAGE_SIZE - 1)
      const lastPositionInPage = firstPositionInPage + this.PAGE_SIZE;
      this.currentPage = this.samples.slice(firstPositionInPage, lastPositionInPage);
    }
    this.scrollToTop();
  }

  lastPage() {
    this.page = this.totalPages
    const firstPositionInPage = this.total - (this.total % this.PAGE_SIZE) - 1
    const lastPositionInPage = this.total - 1;
    this.currentPage = this.samples.slice(firstPositionInPage, lastPositionInPage);
  }

}
