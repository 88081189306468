import { Conflict, ConflictFilters, ConflictGroup, ConflictReport } from 'src/app/core/models/Conflict';
import { ConflictReportStatus } from 'src/app/core/models/models';
import { AddTagsToParagraph, DatasetReviewActions, DatasetReviewActionType, RemoveTagFromParagraph } from './actions';
import { DatasetDto } from '../../core/models/Dataset';

export interface DatasetReviewState {
  conflictReport: ConflictReport;
  selectedConflictGroup: ConflictGroup;
  selectedConflictFilters: ConflictFilters;
  sortedParentIds: number[];
  currentDataset: DatasetDto;
  includeAllSearchResults: boolean;
  includeAllSearchResultsExcludedParagraphIds: Array<string>;
  explicitlySelectedParagraphIds: Array<string>;
}

export const initialSelectedConflictFilters: ConflictFilters = {
  top: 10,
  page: 1,
  paragraphId: null,
  ontologies: [],
  userLabels: [],
  conflictLabels: [],
  conflictingScore: null,
  tags: [],
  sort: {active: 'ConflictingScore', direction: 'desc'},
  datasetId: 0,
}

export const initialDatasetReviewState: DatasetReviewState = {
  conflictReport: {status: ConflictReportStatus.Processing},
  selectedConflictGroup: null,
  selectedConflictFilters: initialSelectedConflictFilters,
  sortedParentIds: [],
  currentDataset: null,
  includeAllSearchResults: false,
  includeAllSearchResultsExcludedParagraphIds: [],
  explicitlySelectedParagraphIds: [],
};

function getChildConflictsWithNewTags(conflictGroup: ConflictGroup, action: AddTagsToParagraph | RemoveTagFromParagraph) {
  return conflictGroup.childConflicts.map(childConflict => {
    if (childConflict.paragraphId === action.paragraphId) {
      if (action instanceof AddTagsToParagraph) {
        return { ...childConflict, tags: [...new Set(childConflict.tags.concat(action.tags))] };
      } else if (action instanceof RemoveTagFromParagraph) {
        return { ...childConflict, tags: childConflict.tags.filter(tag => !action.tags.includes(tag))};
      }
    } else {
      return { ...childConflict };
    }
  });
}

function getConflictGroupsWithNewTags(conflictReport: ConflictReport, action: AddTagsToParagraph | RemoveTagFromParagraph) {
  return conflictReport.conflictGroups?.map(conflictGroup => {
    let newParent: Conflict;
    if (conflictGroup.parent.paragraphId === action.paragraphId) {
      if (action instanceof AddTagsToParagraph) {
        newParent = { ...conflictGroup.parent, tags: [...new Set(conflictGroup.parent.tags.concat(action.tags))] };
      } else if (action instanceof RemoveTagFromParagraph) {
        newParent = { ...conflictGroup.parent, tags: conflictGroup.parent.tags.filter(tag => !action.tags.includes(tag)) };
      }
    } else {
      newParent = { ...conflictGroup.parent };
    }
    const newChildConflicts = getChildConflictsWithNewTags(conflictGroup, action);
    return { ...conflictGroup, parent: newParent, childConflicts: newChildConflicts };
  });
}

export const datasetReviewReducer = (
  state = initialDatasetReviewState,
  action: DatasetReviewActions
): DatasetReviewState => {
  switch (action.type) {
    case DatasetReviewActionType.GetConflictingReportResults: {
      const dataset = state.currentDataset;
      if (action.payload && dataset && action.payload.datasetId !== dataset.id) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          conflictReport: action.payload,
        };
      }
    }
    case DatasetReviewActionType.ChangeDatasetSelection: {
      return {
        ...state,
        currentDataset: action.payload,
      };
    }
    case DatasetReviewActionType.AddTagsToParagraph: {
      if (action.update) {
        const { conflictReport } = state;
        const conflictGroups = getConflictGroupsWithNewTags(conflictReport, action);
        return {
          ...state,
          conflictReport: {...conflictReport, conflictGroups}
        };
      }
      return {
        ...state,
      };
    }
    case DatasetReviewActionType.RemoveTagFromParagraph: {
      if (action.update) {
        const { conflictReport } = state;
        const conflictGroups = getConflictGroupsWithNewTags(conflictReport, action);
        return {
          ...state,
          conflictReport: {...conflictReport, conflictGroups}
        };
      }
      return {
        ...state
      };
    }
    case DatasetReviewActionType.BulkTagAdd: {
      return {
        ...state
      };
    }
    case DatasetReviewActionType.BulkTagRemove: {
      return {
        ...state
      };
    }
    case DatasetReviewActionType.SetConflictGroup: {
      return {
        ...state,
        selectedConflictGroup: action.payload,
      };
    }
    case DatasetReviewActionType.SetConflictFilters: {
      return {
        ...state,
        selectedConflictFilters: {
          ...action.payload,
        }
      };
    }
    case DatasetReviewActionType.SetSortedParentIds: {
      return {
        ...state,
        sortedParentIds: action.payload,
      };
    }
    case DatasetReviewActionType.ConflictReportPageChanged: {
      return {
        ...state,
        selectedConflictFilters: {
          ...state.selectedConflictFilters,
          page: action.payload
        }
      }
    }
    case DatasetReviewActionType.ConflictParagraphsSelected: {
      return {
        ...state,
        explicitlySelectedParagraphIds: state.includeAllSearchResults
        ? state.explicitlySelectedParagraphIds
        : [...new Set(state.explicitlySelectedParagraphIds.concat(action.conflictParagraphIds))],
      includeAllSearchResultsExcludedParagraphIds: state.includeAllSearchResults
        ? state.includeAllSearchResultsExcludedParagraphIds.filter(id => !action.conflictParagraphIds.includes(id))
        : state.includeAllSearchResultsExcludedParagraphIds,
      }
    }
    case DatasetReviewActionType.ConflictParagraphsDeselected: {
      return {
        ...state,
        explicitlySelectedParagraphIds: state.includeAllSearchResults
        ? state.explicitlySelectedParagraphIds
        : state.explicitlySelectedParagraphIds.filter(docId => !action.conflictParagraphIds.includes(docId)),
      includeAllSearchResultsExcludedParagraphIds: state.includeAllSearchResults
        ? [...new Set(state.includeAllSearchResultsExcludedParagraphIds.concat(action.conflictParagraphIds))]
        : state.includeAllSearchResultsExcludedParagraphIds,
      }
    }
    case DatasetReviewActionType.SelectAllConflictResults: {
      return {
        ...state,
        includeAllSearchResults: true,
        explicitlySelectedParagraphIds: [],
        includeAllSearchResultsExcludedParagraphIds: []
      }
    }
    case DatasetReviewActionType.ClearAllSelectedConflictResults: {
      return {
        ...state,
        includeAllSearchResults: false,
        explicitlySelectedParagraphIds: [],
        includeAllSearchResultsExcludedParagraphIds: []
      }
    }
    default:
      return state;
  }
};

export function datasetReducer(state: DatasetReviewState | undefined, action: DatasetReviewActions) {
  return datasetReviewReducer(state, action);
}
