import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  public storageChanged$ = fromEvent(window, 'session');

  public getItem(key: string): string {
    return sessionStorage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
