import { Component, EventEmitter, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, Subject } from 'rxjs';
import { debounceTime, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Dictionary, LabelAnnotations } from 'src/app/core/models/Annotations';
import { Label, LabelDefinitionAndSamples } from 'src/app/core/models/Label';
import { Entity, SortByField, SortByFields } from 'src/app/core/models/models';
import { LiAccessService } from 'src/app/core/services/li-access.service';
import { ParagraphReviewService } from 'src/app/core/services/paragraph-review.service';
import { ProvisionService } from 'src/app/core/services/provision.service';
import {
  ClearSearchFilters,
  ConfirmedOnChanged,
  ConfirmedTypeChanged,
  ContainsChanged,
  CreatedOnChanged,
  DocumentIdChanged,
  ExpandScatterPlotPanel,
  IncludeHiddenParagraphsChanged,
  LoadProvision,
  MachineLabelChanged,
  ParagraphIdFilterChanged,
  RemoveDashboardFilter,
  ScatterPlotAction,
  SearchParagraphIds,
  SearchTypeChanged,
  SetActiveLabel,
  SetReviewView,
  SortFieldChanged,
  SortLabelChanged,
  SortOrderChanged,
  TagsChanged,
  UserLabelChanged,
} from 'src/app/paragraph-review/store/actions';
import { OntologyChanged, SetEntities } from 'src/app/root-store/actions';
import { AppState } from 'src/app/root-store/state';
import {
  getParagraphReviewState,
  getReviewView,
  getScatterPlotAction,
  getSearchConfirmedOn,
  getSearchConfirmedType,
  getSearchContains,
  getSearchCreatedOn,
  getSearchDocumentId,
  getSearchIncludeHiddenParagraphs,
  getSearchMachineLabels,
  getSearchParagraphId,
  getSearchSortByField,
  getSearchSortOrder,
  getSearchSortValue,
  getSearchTags,
  getSearchType,
  getSearchUserLabels,
  getZoomToClusterIds,
  getZoomToParagraphIds,
  isDashboardFilter,
  resultParagraphIds,
} from '../store/selectors';
import { ReviewView } from '../../core/models/ReviewView';
import { ModelDeployment } from 'src/app/core/models/ModelDeployment';
import { ModelDeploymentService } from 'src/app/core/services/model-deployment.service';
import { FeatureFlags } from 'src/app/core/models/feature-flags';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { ChartActions, ChartOptions, Filter } from 'src/app/topic-coverage-discovery/charts/ChartOptions';
import { TopicDiscoveryData } from 'src/app/topic-coverage-discovery/models/TopicDiscoveryData';
import { TopicDiscoveryService } from 'src/app/core/services/topic-discovery-service';
import {
  DateFilter,
  DateFilterOperatorEnum,
  ParagraphConfirmedTypeEnum,
  SearchTypeEnum,
  SelectedItem,
  SortByFieldEnum,
  SortOrderEnum,
} from '../store/reducer';
import {
  selectRootStateEntities,
  selectRootStateLabels,
  selectRootStateOntology,
  selectRootStateTags
} from '../../root-store/selectors';
import { NotificationService } from '../../core/services/notification.service';
import { EntityService } from '../../core/services/entity.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [ParagraphReviewService],
})
export class ReviewComponent implements OnInit, OnDestroy {
  tags: string[];
  labels: Label[];
  labelsSorted: Label[];
  public selectedTags: SelectedItem[] = [];
  public selectedUserLabels: SelectedItem[] = [];
  public selectedMachineLabels: SelectedItem[] = [];
  public selectedOntology = 'OGL';
  public confirmedType = 2;
  public paragraphs: number[];
  public isDashboardFilterApplied = false;
  public provision: any;
  public contains = '';
  public containsChanged: EventEmitter<string> = new EventEmitter<string>();
  public paragraphIdFilterChanged: EventEmitter<number> = new EventEmitter<number>();
  public documentIdFilterChanged: EventEmitter<number> = new EventEmitter<number>();
  public paragraphAnnotations: Dictionary<LabelAnnotations[]>;
  public from: any;
  public isFilterVisible = true;
  public activeLabel: Label;
  public entities: Entity[] = [];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public confirmedTypeEnum = ParagraphConfirmedTypeEnum;
  public searchTypeEnum = SearchTypeEnum;
  public createdOn: DateFilter;
  public confirmedOn: DateFilter;
  public sortByFields = this.liAccessService.isThoughttraceUser()
    ? SortByFields
    : SortByFields.filter(s => s.value === SortByFieldEnum.ConfirmedOn);
  public sortOrderEnum = SortOrderEnum;
  public selectedSortField = SortByFieldEnum.UploadedOn;
  public selectedSortOder = SortOrderEnum.Ascending;
  public selectedSortValue: SelectedItem;
  public paragraphIdFilter: number = null;
  public documentIdFilter: number = null;
  public searchType = 1;
  public activeLabelSelection = false;
  public labelDefinitionAndSamples: LabelDefinitionAndSamples;
  public reviewView: ReviewView = ReviewView.ParagraphSearch;
  public ReviewViewEnum = ReviewView;
  public modelDeployments: ModelDeployment[] = [];
  featureFlags: Observable<FeatureFlags>;
  options: ChartOptions;
  public includeHiddenParagraphs: boolean;
  public expandScatterPlot: boolean;
  showLabelPanel: boolean;
  showTagPanel: boolean;
  showDatePanel: boolean;
  loadSelectLabelComponent = false;
  trackByFn: TrackByFunction<SortByField> = (_, item) => item.name;

  constructor(
    private provisionService: ProvisionService,
    private topicDiscoveryService: TopicDiscoveryService,
    private entityService: EntityService,
    private store: Store<AppState>,
    private liAccessService: LiAccessService,
    private modelDeploymentService: ModelDeploymentService,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagsService,
  ) { }

  ngOnInit() {

    this.store.dispatch(new ExpandScatterPlotPanel(false));

    this.options = {
      margin: 0,
      idField: 'provisionId',
      clusterField: 'clusterId',
      infoField: 'keywords',
      children: 'samples',
      tooltipInfo: [
        { field: 'provisionId', label: 'Provision Id' },
        { field: 'clusterId', label: 'Cluster Id' },
        { field: 'keywords', label: 'Keywords', maxItems: 5 },
      ],
      data: [],
      customData: [],
      action: ChartActions.Panning,
      filters: [],
      axis: {
        xAxisField: 'x',
        yAxisField: 'y'
      },
      showAxis: false,
      zoomToIds: [],
      zoomToClusterIds: [],
    };


    this.featureFlags = this.featureFlagService.featureFlags$;
    this.store.dispatch(new LoadProvision());

    this.store
      .select(selectRootStateLabels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(labels => {
        this.labels = labels;
        this.labelsSorted = labels;
      });

    this.store
      .select(selectRootStateTags)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(tags => {
        this.tags = tags;
      });

    this.containsChanged.pipe(debounceTime(500)).subscribe(text => {
      if (this.paragraphIdFilter === null) {
        this.store.dispatch(new ContainsChanged(text));
      }
    });

    this.paragraphIdFilterChanged.pipe(debounceTime(700)).subscribe(pid => {
      this.handleParagraphIdChange(pid);
    });

    this.documentIdFilterChanged.pipe(debounceTime(700)).subscribe(did => {
      this.store.dispatch(new DocumentIdChanged(did));
    });

    this.store
      .pipe(
        select(getSearchContains),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(contains => {
        this.contains = contains;
      });

    this.store
      .pipe(
        select(getSearchParagraphId),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(pid => {
        this.paragraphIdFilter = pid;
      });

    this.store
      .pipe(
        select(getSearchDocumentId),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(did => {
        this.documentIdFilter = did;
      });

    this.store
      .pipe(
        select(getSearchTags),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(tags => {
        this.selectedTags = tags;
        if (this.selectedTags.length) {
          this.showTagPanel = true;
        }
      });

    this.store
      .pipe(
        select(getSearchUserLabels),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(userLabels => {
        this.selectedUserLabels = userLabels;
        if (this.selectedUserLabels.length) {
          this.showLabelPanel = true;
        }
      });

    this.store
      .pipe(
        select(getSearchMachineLabels),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(machineLabels => {
        this.selectedMachineLabels = machineLabels;
        if (this.selectedMachineLabels.length) {
          this.showLabelPanel = true;
        }
      });

    this.store
      .pipe(
        select(getSearchCreatedOn),
        takeUntil(this.ngUnsubscribe),
      ).subscribe(createdOn => {
        this.createdOn = createdOn;
        this.dateFilterChanged(createdOn);
      });

    this.store
      .pipe(
        select(getSearchConfirmedOn),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(confirmedOn => {
        this.confirmedOn = confirmedOn;
        this.dateFilterChanged(confirmedOn);
      });

    this.store
      .pipe(
        select(getSearchSortOrder),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(sortOrder => {
        this.selectedSortOder = sortOrder;
      });

    this.store
      .pipe(
        select(getSearchSortByField),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(sortByField => {
        this.selectedSortField = sortByField;
      });

    this.store
      .pipe(
        select(getSearchSortValue),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(sortValue => {
        this.selectedSortValue = sortValue;
      });

    this.store
      .select(selectRootStateOntology)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(ontology => {
        this.selectedOntology = ontology;
        this.getModelDeployments(ontology);
        this.getEntities(ontology);
        this.store.dispatch(new SetActiveLabel(null));
      });

    this.store
      .pipe(
        select(getSearchConfirmedType),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(type => {
        this.confirmedType = type;
      });

    this.store
      .pipe(
        select(getSearchType),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(type => {
        this.searchType = type;
      });

    this.store
      .pipe(
        select(getSearchIncludeHiddenParagraphs),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(includeHidden => {
        this.includeHiddenParagraphs = includeHidden;
      });

    this.store
      .pipe(
        select(resultParagraphIds),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(results => {
        this.paragraphs = results;
      });

    this.store
      .select(isDashboardFilter)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isDashboardFilter => {
        this.isDashboardFilterApplied = isDashboardFilter;
      });

    this.store
      .select(getReviewView)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(reviewView => {
        this.reviewView = reviewView;
        if (this.reviewView !== (ReviewView.SideBySideConflict || ReviewView.DatasetSideBySideConflict) && !this.isDashboardFilterApplied) {
          this.store.dispatch(new SearchParagraphIds([]))
        }
      });

    this.store
      .pipe(
        select(getZoomToParagraphIds),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(zoomToParagraphIds => {
        if (zoomToParagraphIds) {
          this.options.zoomToIds = zoomToParagraphIds;
          this.options.action = ChartActions.ZoomToProvisions;
          this.options = { ...this.options };
        }
      });

    this.store.pipe(
      select(getScatterPlotAction),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(action => {
      this.options.action = action[0];
      this.options = { ...this.options };
    });

    this.store
      .pipe(
        select(getZoomToClusterIds),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(zoomToClusterIds => {
        if (zoomToClusterIds) {
          this.options.zoomToClusterIds = zoomToClusterIds;
          this.options.action = ChartActions.ZoomToClusters;
          this.options = { ...this.options };
        }
      });

    this.store
      .pipe(select(getParagraphReviewState), takeUntil(this.ngUnsubscribe))
      .subscribe(state => this.expandScatterPlot = state.expandScatterPlotPanel);
  }

  getModelDeployments(ontology: string) {
    this.modelDeploymentService
      .getDeploymentsByOntology(ontology)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(models => {
        this.modelDeployments = models;
      });
  }

  containsChange(contains: string) {
    this.containsChanged.emit(contains);
  }

  tagsUpdated(event) {
    this.store.dispatch(new TagsChanged(event.selectedItems));
  }

  userLabelUpdated(event) {
    this.store.dispatch(new UserLabelChanged(event.selectedItems));
  }

  machineLabelUpdated(event) {
    this.store.dispatch(new MachineLabelChanged(event.selectedItems));
    this.sortLabelList();
  }

  createdOnChanged(event: DateFilter) {
    this.store.dispatch(new CreatedOnChanged(event));
  }

  confirmedOnChanged(event) {
    this.store.dispatch(new ConfirmedOnChanged(event));
  }

  sortFieldChange() {
    this.activeLabelSelection = this.selectedSortField === SortByFieldEnum.MachineLabel;
    this.store.dispatch(new SortFieldChanged(this.selectedSortField));
  }

  sortLabelChange(event) {
    this.selectedSortValue = { id: event };
    this.store.dispatch(new SortLabelChanged(this.selectedSortValue));
  }

  searchToggleChange(value) {
    this.store.dispatch(new SearchTypeChanged(value));
  }

  sortOrderChange(sortOrder: SortOrderEnum) {
    this.store.dispatch(new SortOrderChanged(sortOrder));
  }

  selectLabel(label: Label) {
    this.store.dispatch(new SetActiveLabel(label));
  }

  toggleChange(value, group) {
    if (this.confirmedType === parseInt(value, null)) {
      group.value = undefined;
      this.confirmedType = 0;
    } else {
      this.confirmedType = parseInt(value, null);
    }
    this.store.dispatch(new ConfirmedTypeChanged(this.confirmedType));
  }

  paragraphIdFilterChange(id: number) {
    this.paragraphIdFilterChanged.emit(id);
  }

  documentIdFilterChange(id: number) {
    this.documentIdFilterChanged.emit(id);
  }

  toggleIncludeHiddenParagraphs(includeHidden: boolean) {
    this.store.dispatch(new IncludeHiddenParagraphsChanged(includeHidden));
  }

  resetSearch() {
    this.paragraphIdFilterChanged.emit(null);
    this.documentIdFilterChanged.emit(null);
    this.store.dispatch(new ClearSearchFilters());
    this.store.dispatch(new LoadProvision());
    this.store.dispatch(new ScatterPlotAction([ChartActions.Reset]));
    this.scrollToTop();
  }

  scrollToTop() {
    const myDiv = document.getElementById('scroll-search');
    myDiv.scrollTop = 0;
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch))
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  handleParagraphIdChange(paragraphId: number) {
    this.store.dispatch(new ParagraphIdFilterChanged(paragraphId));
    if (paragraphId === null) {
      this.store.dispatch(new ContainsChanged(this.contains));
      return;
    }

    this.provisionService.searchParagraph(paragraphId).subscribe(paragraph => {
      if (paragraph.ontologyId !== this.selectedOntology) {
        this.notificationService.showInfo(`Paragraph not found in ${this.selectedOntology}, you have been redirected to ${paragraph.ontologyId}`);
        this.store.dispatch(new ClearSearchFilters());
        this.store.dispatch(new ParagraphIdFilterChanged(paragraph.id));
        this.store.dispatch(new OntologyChanged(paragraph.ontologyId));
      }
    });
  }

  sortLabelList() {
    this.labelsSorted = this.labels;
    const ids = this.selectedMachineLabels.map(labelMachine => labelMachine.id);
    const labelsSelected = this.labels.filter(label => ids.find(id => id === label.id))
    this.labelsSorted = this.labelsSorted.filter(label => !labelsSelected.includes(label));
    this.labelsSorted = labelsSelected.concat(this.labelsSorted);
  }

  removeDashboardFilter() {
    this.store.dispatch(new RemoveDashboardFilter())
    this.store.dispatch(new LoadProvision());
    this.scrollToTop();
  }

  showLabelDefinitionAndSamples(data: LabelDefinitionAndSamples) {
    this.labelDefinitionAndSamples = data;
    this.store.dispatch(new SetReviewView(ReviewView.LabelDefinition));
  }

  returnView(view: ReviewView) {
    this.labelDefinitionAndSamples = null;
    switch (view) {
      case ReviewView.ParagraphSearch:
        this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch));
        break;
      case ReviewView.SideBySideConflict:
        this.store.dispatch(new SetReviewView(ReviewView.SideBySideConflict));
        break;
      case ReviewView.DatasetSideBySideConflict:
        this.store.dispatch(new SetReviewView(ReviewView.DatasetSideBySideConflict));
        break;
      default:
        this.store.dispatch(new SetReviewView(ReviewView.ParagraphSearch));
        break;
    }
  }

  onChartSizeChange(event: any) {
    this.options.height = event.height;
    this.options.width = event.width;
    this.options = { ...this.options };
  }

  onChartActionChange(action: ChartActions) {
    this.options.action = action;
    this.options = { ...this.options };
  }

  onChartFiltersChange(filters: Filter[]) {
    this.options.filters = filters;
    this.options = { ...this.options };
  }

  onChartSelectionChange(paragraphIds: TopicDiscoveryData[]) {
    this.store.dispatch(new SearchParagraphIds([... new Set(paragraphIds.map(data => data.provisionId))], true));
    this.store.dispatch(new LoadProvision());
  }

  onSearchText(text: string) {
    this.topicDiscoveryService.getInference({ ontologyId: this.selectedOntology, paragraph: text })
      .subscribe(response => {
        this.options.customData = [response.body];
        this.options.action = ChartActions.ZoomToCustom;
        this.options = { ...this.options };
      });
  }

  loadChartData() {
    this.store.dispatch(new ExpandScatterPlotPanel(true));
    this.store
      .select(s => s.rootState.ontology)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(ontology => {
        setTimeout(() => {
          this.topicDiscoveryService.getTopicDiscoveryData(ontology).subscribe(response => {
            if (response.status === 200) {
              this.options.data = response.body;
              this.options = { ...this.options }
            }
          });
        });
      });
  }

  onClose() {
    this.store.dispatch(new ExpandScatterPlotPanel(false));
  }

  getSimpleDate(date: Date) {
    return new Date(date).setHours(0, 0, 0, 0);
  }

  dateFilterChanged(dateFilter: DateFilter) {
    if (dateFilter && !(this.getSimpleDate(dateFilter.date) === this.getSimpleDate(new Date()) &&
      dateFilter.operator === DateFilterOperatorEnum.AnyTime)) {
      this.showDatePanel = true;
    }
  }

  tabChange(index: number) {
    if (index === 1) {
      this.loadSelectLabelComponent = true;
    }
  }

  private getEntities(ontology: string) {
    this.store.select(selectRootStateEntities).pipe(
      take(1),
      mergeMap(entities => {
        if (entities.length > 0) {
          return EMPTY;
        } else {
          return this.entityService.getEntities(ontology).pipe(
            tap(fetchedEntities => this.store.dispatch(new SetEntities(fetchedEntities))),
          );
        }
      }),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }
}
