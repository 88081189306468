import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label, LabelDefinitionAndSamples } from '../../core/models/Label';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../root-store/state';
import { Subject } from 'rxjs';
import { ConflictFilters, ConflictGroup, ConflictReport } from '../../core/models/Conflict';
import { SetConflictGroup } from '../../model-review/store/actions';
import { getConflictReport, getSelectedConflictFilters, getSelectedConflictGroup, getSortedParentIds } from '../../model-review/store/selectors';
import { LoadProvision, SearchParagraphIds } from '../store/actions';
import { ReviewView } from 'src/app/core/models/ReviewView';
import { ModelService } from 'src/app/core/services/model.service';
import { selectRootStateOntologies } from '../../root-store/selectors';
import { Ontology } from '../../core/models/Ontology';

@Component({
  selector: 'app-model-review-selected-sample',
  templateUrl: './conflict-review-selected-sample.component.html',
  styleUrls: ['./conflict-review-selected-sample.component.scss'],
})
export class ConflictReviewSelectedSampleComponent implements OnInit {
  @Input() tags: string[];
  @Input() labels: Label[];
  @Input() ontology: string;
  @Output() showDefinitionAndExamplesEvent = new EventEmitter<LabelDefinitionAndSamples>();

  sortedParentIds: number[];
  selectedConflictGroup: ConflictGroup;
  ontologiesUsed: number;
  conflictReport: ConflictReport;
  childIds: number[] = [];
  conflictFilters: ConflictFilters
  pageSize: number = 10;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private ontologies: Ontology[];

  constructor(
    private modelService: ModelService,
    private store: Store<AppState>) {}

  ngOnInit() {
    this.store
      .select(getSelectedConflictGroup)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(conflictGroup => {
        if (conflictGroup) {
          this.selectedConflictGroup = conflictGroup;
          this.ontologiesUsed = new Set(this.selectedConflictGroup.childConflicts.map(c => c.ontology)).size;
          this.childIds = conflictGroup.childConflicts.map(cc => cc.paragraphId);
          this.store.dispatch(new SearchParagraphIds(this.childIds.concat(conflictGroup.parent.paragraphId)));
          this.store.dispatch(new LoadProvision());
        }
      });

    this.store
      .select(getSortedParentIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(parentIds => {
        this.sortedParentIds = parentIds;
      });

    this.store.select(getSelectedConflictFilters)
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(filters => {
        this.conflictFilters = filters;
      });
    this.store
      .select(getConflictReport)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(conflictReport => {
        this.conflictReport = conflictReport;
      });
    this.store
      .select(selectRootStateOntologies)
      .subscribe(ontologies => {
        this.ontologies = ontologies;
      });
  }

  changeConflict(nextIndex) {
    this.scrollToTop();
    let selectedParentId = this.selectedConflictGroup.parent.paragraphId;
    let nextParentIdIndex = this.sortedParentIds.indexOf(selectedParentId);
    let indexValue = nextParentIdIndex + nextIndex;

    if (indexValue >= this.sortedParentIds.length || indexValue === -1) {
       const factor: number = indexValue === -1 ? -1 : 1
       let updatedPage = parseInt(this.conflictFilters.page.toString()) + factor;
       const updatedFilter = {...this.conflictFilters, page: updatedPage, modelId: this.conflictReport.modelId};
       this.conflictFilters = updatedFilter;
       this.modelService.generateConflictingReport(updatedFilter).pipe(take(1))
         .subscribe(res => {
           this.conflictReport = res.body;
           this.sortedParentIds = this.conflictReport.conflictGroups.map(x => x.parent.paragraphId);
           indexValue = factor === -1 ? this.sortedParentIds.length - 1 : 0;
           this.getNextConflict(indexValue);
       });
    } else {
      this.getNextConflict(indexValue);
    }
  }


  private getNextConflict(indexValue) {
    let nextParentId = this.sortedParentIds[indexValue];
    let nextParent = this.conflictReport.conflictGroups
      .find(cg => cg.parent.paragraphId === nextParentId);
    const ontologyName = this.ontologies.find(o => o.id === nextParent.parent.ontology)?.displayName;
    const childConflicts = nextParent.childConflicts.map(cc => {
      const ontologyName = this.ontologies.find(o => o.id === cc.ontology)?.displayName;
      return { ...cc, ontologyName }
    });

    const parent = { ...nextParent.parent, ontologyName};
    const newNextParent = { ...nextParent, parent, childConflicts}

    this.store.dispatch(new SetConflictGroup(newNextParent));
    this.store.dispatch(new SearchParagraphIds(this.getConflictGroupParagraphIds(nextParent)));
    this.store.dispatch(new LoadProvision());
  }

  isNextDisabled() {
    const pageLimit = Math.floor((this.conflictReport.conflictGroupCount / this.pageSize)) + 1;
    const hasReachedPageLimit = this.conflictFilters.page === pageLimit;
    return this.sortedParentIds.indexOf(this.selectedConflictGroup.parent.paragraphId) === this.sortedParentIds.length - 1 && hasReachedPageLimit
  }

  scrollToTop() {
    Array.from(document.getElementsByClassName('scroll-side-by-side-conflict'))
      .forEach(div => div.scrollTop = 0);
  }

  private getConflictGroupParagraphIds(selectedGroup: ConflictGroup): number[] {
    const paragraphIds = [selectedGroup.parent.paragraphId];
    selectedGroup.childConflicts.forEach(cc => paragraphIds.push(cc.paragraphId));
    return [...new Set(paragraphIds)];
  }
  showLabelDefinitionAndSamples(data: LabelDefinitionAndSamples) {
    data.previousView = ReviewView.SideBySideConflict;
    this.showDefinitionAndExamplesEvent.emit(data);
  }
}
