import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Annotation, HighlightClass, LabelAnnotations } from 'src/app/core/models/Annotations';
import { Label } from 'src/app/core/models/Label';
import { Highlight, RangeSelected } from 'src/app/core/models/models';
import { Offset } from 'src/app/core/models/Offset';
import { LiAccessService } from 'src/app/core/services/li-access.service';
import { ParagraphReviewService } from 'src/app/core/services/paragraph-review.service';
import { AppState } from 'src/app/root-store/state';
import { getParagraphReviewState } from '../../../paragraph-review/store/selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ali-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss'],
})
export class HighlightComponent implements OnInit, AfterViewInit, OnDestroy  {
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  @Input() provisionText: string;
  @Input() uniqueId: number;
  @Input() showHighlight: Subject<Highlight>;


  @Output() rangeSelected = new EventEmitter<RangeSelected>();
  highlight: LabelAnnotations[] = [];
  offsetsToBeRemoved: Offset[] = [];
  element: HTMLElement;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private activeLabel: Label;

  constructor(
    private store: Store<AppState>,
    private paragraphReviewService: ParagraphReviewService,
    private liAccessService: LiAccessService,
  ) { }

  ngAfterViewInit() {
    this.element = document.getElementById(`paragraph-${this.uniqueId}`);
    this.provisionText = this.escapeText(this.provisionText);
  }

  ngOnInit() {

    this.store.pipe(select(getParagraphReviewState), takeUntil(this.ngUnsubscribe)).subscribe(state => {
      this.activeLabel = state.activeLabel;
    });

    if (this.showHighlight) {
      this.showHighlight.subscribe(highlight => {
        highlight.text = this.escapeText(highlight.text);
        if (highlight.uniqueId !== this.uniqueId) {
          return;
        }
        if (highlight.annotations === undefined || highlight.annotations.length === 0) {
          this.element.textContent = highlight.text;
          return;
        } else if (this.uniqueId === highlight.uniqueId) {
          highlight.annotations = !this.liAccessService.isThoughttraceUser()
            ? highlight.annotations.filter(s => s.createdById > 1)
            : highlight.annotations;

          this.showHighlights(highlight.annotations, highlight.text);
        } else {
          return;
        }
      });
    }

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  showHighlights(annotations: LabelAnnotations[], text: string) {
    this.highlight = annotations;
    const high = this.userWins(annotations);
    const flattenedAnnotations = this.findOverlappingLabels(high);
    const final = this.fillGapsInAnnotationRanges(flattenedAnnotations, text.length);

    const highlighted = final.map(element => {
      const slice = text.slice(element.start, element.end + 1);

      if (element.isSuggestion === undefined) {
        return slice;
      }
      const highlightClass = this.getHighlightClass(element);
      return `<span class=${highlightClass} data-annotations='[${element.start},${element.end}]'>${slice}</span>`;
    }).join('');

    if (annotations.length > 0) {
      this.element.innerHTML = this.unescapeText(highlighted);
    } else {
      this.provisionText = text;
    }
  }

  private getHighlightClass(element: Annotation) {
    if (element.highlightClass) {
      return element.highlightClass;
    } else {
      if (element.isSuggestion) {
        if (element.extractorId) {
          return HighlightClass.MachineExtractor;
        } else {
          return HighlightClass.Machine;
        }
      } else {
        return HighlightClass.User;
      }
    }
  }

  onContextMenu(event: MouseEvent) {
    event.preventDefault();
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY}px`;

    const element = event.target as HTMLInputElement;
    if (!element.classList.contains('user-highlight')) {
      return;
    }

    const values = JSON.parse(element.dataset.annotations);
    const offset = this.highlight.find(s => !s.isSuggestion && this.isContained(s, values[0], values[1]));

    if (offset) {
      this.offsetsToBeRemoved = [{ start: offset.start, end: offset.end, confidence: 1 }];
    }

    this.contextMenu.openMenu();
  }

  deleteLabel() {
    this.paragraphReviewService.removeUserAnnotation(this.offsetsToBeRemoved, this.uniqueId);
  }

  isContained(offset, start, end) {
    if (offset.start <= start && offset.end >= end) {
      return true;
    }
  }

  adjustStart(start, activeLabel: Label) {
    let value;
    do {
      value = this.highlight.filter(
        s => start - s.end <= 2 && start - s.end > 0 && !s.isSuggestion && s.labelId === activeLabel.id,
      )[0];
      if (value !== undefined) {
        start = value.start;
      }
    } while (value !== undefined);
    return start;
  }

  adjustEnd(end, activeLabel: Label) {
    let value;
    do {
      value = this.highlight.filter(
        s => s.start - end <= 2 && s.start - end > 0 && !s.isSuggestion && s.labelId === activeLabel.id,
      )[0];
      if (value !== undefined) {
        end = value.end;
      }
    } while (value !== undefined);
    return end;
  }

  userWins(annotations: LabelAnnotations[]) {
    const similarMap = new Map();

    annotations.forEach((label, index) => {
      const key = `${label.start}-${label.end}`;
      if (similarMap.has(key)) {
        similarMap.get(key).push(index);
      } else {
        similarMap.set(key, [index]);
      }
    });

    const indicesToRemove = new Set();
    similarMap.forEach(indices => {
      if (indices.length >= 2) {
        indices.forEach(index => {
          if (annotations[index].isSuggestion) {
            indicesToRemove.add(index);
          }
        });
      }
    });

    return annotations.filter((_, index) => !indicesToRemove.has(index));
  }

  textHighlighted() {
    if (this.activeLabel == null) {
      return;
    }

    const selection = window.getSelection();
    if (selection.toString() !== '') {
      const element = document.querySelector(`#paragraph-${this.uniqueId}`);
      this.extend(selection);
      const range = selection.getRangeAt(0);
      const priorRange = range.cloneRange();
      priorRange.selectNodeContents(element);
      priorRange.setEnd(range.startContainer, range.startOffset);

      const rangeStart = priorRange.toString().length;
      const text = range.toString().trim();
      let rangeEnd = rangeStart + text.length;
      rangeEnd = selection.focusNode.parentElement.classList.contains('provision-text') ? rangeEnd - 1 : rangeEnd;

      const startAnnotation = this.adjustStart(rangeStart, this.activeLabel);
      const endAnnotation = this.adjustEnd(rangeEnd, this.activeLabel);

      this.rangeSelected.emit({
        offset: { start: startAnnotation, end: endAnnotation, confidence: 1 },
        uniqueId: this.uniqueId,
      } as RangeSelected);
    }
  }

  extend(selection) {
    const range = selection.getRangeAt(0).cloneRange();
    let startOffset = 0;
    let endOffset = 0;

    if (range.startContainer.data[range.startOffset] === ' ') {
      startOffset--;
    } else {
      for (let i = range.startOffset - 1; i >= 0; i--) {
        if (range.startContainer.data[i].match(/\S/)) {
          startOffset++;
        } else {
          break;
        }
      }
    }

    if (range.endContainer.data[range.endOffset - 1] === ' ') {
      endOffset--;
    } else {
      for (let j = range.endOffset; j < range.endContainer.data.length; j++) {
        if (range.endContainer.data[j].match(/\S/)) {
          endOffset++;
        } else {
          break;
        }
      }
    }

    const newStartOffset = Math.max(0, range.startOffset - startOffset);
    const newEndOffset = Math.min(range.endContainer.data.length, range.endOffset + endOffset);

    range.setStart(range.startContainer, newStartOffset);
    range.setEnd(range.endContainer, newEndOffset);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  findOverlappingLabels(annotations: Annotation[]) {
    // Sort the annotations by their start points
    annotations.sort((a, b) => a.start - b.start);

    const finalAnnotations: Annotation[] = [];

    const updateLastAnnotation = (newAnnotation: Annotation) => {
      finalAnnotations[finalAnnotations.length - 1] = newAnnotation;
    };

    const getLastAnnotation = () => finalAnnotations[finalAnnotations.length - 1];

    const addAnnotation = (newAnnotation: Annotation) => {
      finalAnnotations.push(newAnnotation);
    };

    const isOverlapping = (annotation1: Annotation, annotation2: Annotation) => {
      return annotation1.end >= annotation2.start;
    };

    for (const annotation of annotations) {
      if (!finalAnnotations.length || !isOverlapping(getLastAnnotation(), annotation)) {
        // If the array is empty or the current annotation does not overlap with the last annotation in the array
        addAnnotation(annotation);
      } else {
        // If the current annotation overlaps with the last annotation in the array
        const lastAnnotation = getLastAnnotation();
        if (lastAnnotation.isSuggestion !== annotation.isSuggestion) {
          const lastAnnotationIntersection = {...lastAnnotation}
          const annotationIntersection = {...annotation};
          if (lastAnnotation.isSuggestion) {
            // If the end of the current annotation is less than the end of the last annotation, split the last annotation
            if (annotation.end < lastAnnotation.end) {
              const leftAnnotation = {...lastAnnotation, end: annotation.start - 1};
              const rightAnnotation = {...lastAnnotation, start: annotation.end + 1};
              updateLastAnnotation(leftAnnotation);
              addAnnotation(annotationIntersection);
              addAnnotation(rightAnnotation);
              continue;
            } else {
              lastAnnotationIntersection.end = annotation.start - 1;
            }
          } else {
            // If the last annotation is not a suggestion
            if (lastAnnotation.end >= annotation.end) {
              annotationIntersection.end = annotation.start;
            } else {
              annotationIntersection.start = lastAnnotation.end + 1;
            }
          }
          updateLastAnnotation(lastAnnotationIntersection);
          if (annotationIntersection.start !== annotationIntersection.end) {
            addAnnotation(annotationIntersection);
          }
        } else {
          // If both are suggestions or both are not suggestions, merge the two annotations
          lastAnnotation.end = Math.max(lastAnnotation.end, annotation.end);
          updateLastAnnotation(lastAnnotation);
        }
      }
    }

    return finalAnnotations;
  }

  fillGapsInAnnotationRanges(annotations: Annotation[], length = 0) {
    const withMissing: Annotation[] = [];
    let lastIndex = 0;

    for (const item of annotations) {
      // If there's a gap between the last index and the start of the current range, add it to the missing array
      if (item.start > lastIndex) {
        withMissing.push({
          start: lastIndex === 0 ? 0 : lastIndex + 1,
          end: item.start - 1,
        });
      }

      // Include the current range as it is
      withMissing.push(item);

      // Update the last index to the end of the current range
      lastIndex = item.end;
    }

    // If there's a gap between the last index and the length, add it to the missing array
    if (lastIndex + 1 <= length - 1) {
      withMissing.push({
        start: lastIndex + 1,
        end: length,
      });
    }

    return withMissing;
  }

  // It only applies to un-escape "&amp" inside the innerHTML property this is required for the story:
  // https://app.shortcut.com/thoughttrace/story/122956/li-displays-amp-as-which-throws-off-offsets-when-creating-user-labels-past-that-position.
  // it will convert an & as an &amp; and will show &;
  //                    &amp; as an &amp;amp; and will show &amp;
  unescapeText(text: string): string {
    return text.replace(/&/g, '&amp;');
  }

  // It only applies to escape the text that are imported from tenant search.
  escapeText(text: string) {
    return text.replace('&amp;amp', '&amp');
  }
}
