import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TextSampleService {
  constructor(private http: HttpClient) {}

  createTextSample(obj) {
    return this.http.post<any>('api/text/create', obj);
  }

  bulkCreateSample(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>('api/text/upload', formData);
  }

  getValidSources() {
    return this.http.get<string[]>('api/text/sources');
  }
}
