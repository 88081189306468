import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ParagraphReviewModule } from '../paragraph-review/paragraph-review.module';
import { routes } from './training-routing.module';
import { TrainingComponent } from './training/training.component';

@NgModule({
  declarations: [TrainingComponent],
  imports: [RouterModule.forChild(routes), CommonModule, ParagraphReviewModule],
})
export class TrainingModule {}
