import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'mat-select-autocomplete',
  templateUrl: './mat-select-autocomplete.component.html',
  styleUrls: ['./mat-select-autocomplete.component.scss'],
})
export class MatSelectAutocompleteComponent implements OnChanges {
  @Input()
  placeholder;
  @Input()
  options: any[] = [];
  @Input()
  disabled = true;
  @Input()
  display = 'name';
  @Input()
  value = 'id';
  @Input()
  errorMsg = 'Field is required';
  @Input()
  showErrorMsg = false;
  @Input()
  selectedOptions;
  @Input()
  selectedValue: number;

  @Output()
  selectionChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('selectElem') selectElem;

  filteredOptions: Array<any> = [];
  displayString = '';
  constructor() { }

  ngOnChanges() {
    this.filteredOptions = this.options;
  }

  toggleDropdown() {
    this.selectElem.toggle();
  }

  filterItem(value) {
    this.filteredOptions = this.options.filter(
      item => item[this.display].toLowerCase().indexOf(value.toLowerCase()) > -1,
    );
  }

  hideOption(option) {
    return !(this.filteredOptions.indexOf(option) > -1);
  }

  // Returns plain strings array of filtered values
  getFilteredOptionsValues() {
    const filteredValues = [];
    this.filteredOptions.forEach(option => {
      filteredValues.push(option.value);
    });
    return filteredValues;
  }

  onDisplayString() {
    this.displayString = '';
    let displayOption = [];
    // Single select display
    displayOption = this.options.filter(option => option[this.value] === this.selectedValue);
    if (displayOption.length) {
      this.displayString = displayOption[0][this.display];
    }
    return this.displayString;
  }

  onSelectionChange(val) {
    this.selectedValue = val.value;
    this.selectionChange.emit(this.selectedValue);
  }
}
