import { Injectable, Injector } from '@angular/core';
import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { GlobalErrorHandler } from '../error-handling/global-error-handler';
import { selectRootStateCurrentUser } from '../../root-store/selectors';
import { AppState } from '../../root-store/state';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  injector = Injector.create({
    providers: [
      {
        provide: ApplicationinsightsAngularpluginErrorService,
        useClass: ApplicationinsightsAngularpluginErrorService,
      },
    ],
  });

  private angularPlugin = new AngularPlugin(this.injector);
  private clickPluginInstance = new ClickAnalyticsPlugin();
  private clickPluginConfig = {
    autoCapture: true,
    dataTags: {
      useDefaultContentNameOrId: true,
      autoCapture: true,
    }
  };
  private extensions = [this.angularPlugin, ...(environment.enableClickPlugin ? [this.clickPluginInstance] : [])];
  private extensionConfig = {
    [this.angularPlugin.identifier]: {
      router: this.router,
      useInjector: true,
      errorService: [new GlobalErrorHandler(this.injector)],
    },
    ...(environment.enableClickPlugin && { [this.clickPluginInstance.identifier]: this.clickPluginConfig })
  };
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.appInsightsString,
      extensions: this.extensions,
      extensionConfig: this.extensionConfig,
    },
  });

  constructor(private router: Router, private store: Store<AppState>) {
    this.appInsights.loadAppInsights();
    this.store
      .select(selectRootStateCurrentUser)
      .pipe(takeUntilDestroyed())
      .subscribe(user => this.appInsights.setAuthenticatedUserContext(user?.id));
    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      envelope.tags['ai.cloud.role'] = 'li-client';
    });
    this.initiateAppInsights();
  }

  private initiateAppInsights() {
    this.router.events
      .pipe(
        filter(
          (event: unknown): event is ResolveEnd => event instanceof ResolveEnd
        )
      )
      .subscribe((event) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.appInsights.trackPageView({
            name: activatedComponent.name,
            uri: event.urlAfterRedirects,
          });
        }
      });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    if (snapshot.component) {
      return snapshot.component;
    }
  }

  // expose tracking methods
  trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties) {
    this.appInsights.trackEvent(event, customProperties);
  }

  startTrackEvent(name?: string) {
    this.appInsights.startTrackEvent(name);
  }

  stopTrackEvent(
    name: string,
    properties?: { [p: string]: string },
    measurements?: { [p: string]: number }
  ): any {
    this.appInsights.stopTrackEvent(name, properties, measurements);
  }

  trackPageView(pageView?: IPageViewTelemetry) {
    this.appInsights.trackPageView(pageView);
  }

  startTrackPage(name?: string) {
    this.appInsights.startTrackPage(name);
  }

  stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    this.appInsights.stopTrackPage(name, url, properties, measurements);
  }

  trackMetric(metric: IMetricTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackMetric(metric, properties);
  }

  trackException(
    exception: IExceptionTelemetry,
    properties?: ICustomProperties
  ) {
    this.appInsights.trackException(exception, properties);
    console.error(exception.exception);
  }

  trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
    this.appInsights.trackTrace(message, properties);
    console.trace(message);
  }

  trackDependencyData(dependency: IDependencyTelemetry) {
    this.appInsights.trackDependencyData(dependency);
  }

  flush() {
    this.appInsights.flush();
  }
}
