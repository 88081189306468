import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CreateDatasetDto,
  DatasetDataResponse,
  DatasetDataSearchQuery,
  DatasetDto,
  DatasetLabelRecord
} from '../models/Dataset';
import { ConflictFilters } from '../models/Conflict';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatasetService {

  constructor(private http: HttpClient) { }

  createDataset(dataset: CreateDatasetDto) {
    return this.http.post(`api/dataset`, dataset, { observe: 'response' })
  }

  getDatasetsByOntology(ontologyId: string) {
    return this.http.get<DatasetDto[]>(`api/dataset/ontology/${ontologyId}`)
  }

  generateConflictingReport(datasetId: number) {
    return this.http.post(`api/dataset/${datasetId}/conflictingReport`, null, { observe: 'response' })
  }

  getConflictingReport(conflictFilters: ConflictFilters) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(`api/dataset/conflicting-report`, conflictFilters, { headers, observe: 'response' });
  }

  bulkTagUpdate(obj) {
    return this.http.post(`api/dataset/conflicting-report/bulk/tag`, obj);
  }

  getDatasetData(query: DatasetDataSearchQuery): Observable<DatasetDataResponse> {
    return this.http.post<DatasetDataResponse>(`api/dataset/search`, query);
  }

  getDatasetLabelData(datasetId: number): Observable<DatasetLabelRecord[]> {
    return this.http.get<DatasetLabelRecord[]>(`api/dataset/${datasetId}/label`);
  }
}
