import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SetSecurityLabelAuthorizations } from 'src/app/root-store/actions';
import { AppState } from 'src/app/root-store/state';
import { AuthenticationService } from '../authentication/authentication.service';
import { SecurityLabelAuthorizationsService } from '../services/security-label-authorizations.service';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OntologyGuard  {
  selectedOntology: string;
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private store: Store<AppState>,
    private securityLabelAuthorizationService: SecurityLabelAuthorizationsService,
    private authService: AuthenticationService
  ) { }

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return combineLatest([
      this.securityLabelAuthorizationService.getAll(),
      this.authService.isAuthenticated$,
    ]).pipe(
      map(([auth, isAuthenticated]) => {
        if (!isAuthenticated) {
          this.authService.login(state.url);
          return false;
        }
        if (auth && auth != null && auth != undefined) {
          const ontologies: Array<string> = auth.securityLabels.map(s => s.name);
          this.selectedOntology = this.sessionStorageService.getItem('ontology');
          if ((this.selectedOntology && this.selectedOntology.length !== 0) && ontologies.includes(this.selectedOntology)) {
            this.router.navigate(['main']);
            this.store.dispatch(new SetSecurityLabelAuthorizations(auth.securityLabels));
            return false;
          }
          else {
            return true;
          }
        }
        return true;
      })
    );
  }
}