import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LiAccessService } from '../services/li-access.service';

@Injectable()
export class LiAccessGuard  {
  constructor(private liAccessService: LiAccessService, private router: Router) {}

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.liAccessService.isThoughttraceUser()) {
      return true;
    } else {
      this.router.navigate(['main']);
      return false;
    }
  }
}
