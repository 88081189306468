import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Offset } from '../models/Offset';
import { LabelAnnotations } from '../models/Annotations';
import { RangeSelected, RangeRemoved } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class ParagraphReviewService {
  private rangeSelectedSubject = new Subject<RangeSelected>();
  private addMachineLabelSubject = new Subject<Offset[]>();
  private removeUserAnnotationSubject = new Subject<RangeRemoved>();
  private showHighlightSubject = new Subject<Higlight>();

  rangeSelected$ = this.rangeSelectedSubject;
  machineLabelAdded$ = this.addMachineLabelSubject.asObservable();
  userAnnotationRemoved$ = this.removeUserAnnotationSubject.asObservable();
  showHighlights$ = this.showHighlightSubject.asObservable();

  constructor() {}

  newRangeSelected(range: RangeSelected) {
    this.rangeSelectedSubject.next(range);
  }

  addMachineLabel(ranges: Offset[]) {
    this.addMachineLabelSubject.next(ranges);
  }

  removeUserAnnotation(ranges: Offset[], uniqueId: number) {
    this.removeUserAnnotationSubject.next({ offsets: ranges, uniqueId });
  }

  showHighlight(annotations: LabelAnnotations[], text: string, uniqueId: string | number) {
    this.showHighlightSubject.next({ annotations, text, uniqueId });
  }
}

export interface Higlight {
  annotations: LabelAnnotations[];
  text: string;
  uniqueId: string | number;
}
