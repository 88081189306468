import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.identityDevelopmentUrl,
  clientId: 'labelinsights-ui', // The "Auth Code + PKCE" client
  responseType: 'code',
  redirectUri: environment.redirectUrl,
  silentRefreshTimeout: 20000,
  silentRefreshRedirectUri: (environment.redirectUrl) + '/silent-refresh.html',
  scope: 'openid profile email labelinsights labelinsights_profile', // Ask offline_access to support refresh token refreshes
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by Duende ID Server's URI encoding,
  logoutUrl: (environment.identityDevelopmentUrl) + "/account/logout",
  customQueryParams: {
    audience: "labelinsights",
    useDefaultTenant: true,
  },
  timeoutFactor: 0.50
};
