<mat-card appearance="outlined">
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row">
        <mat-button-toggle-group class="actions" [value]="selectedAction">
          <mat-button-toggle [value]="chartActions.Panning" matTooltip="Pan" (change)="onActionChange($event)">
            <mat-icon>pan_tool</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle *ngIf='reviewView !== ReviewViewEnum.ClusterView'
                             [value]="chartActions.Brushing"
                             (change)="onActionChange($event)" matTooltip="Select" >
            <mat-icon svgIcon="tcd-select"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group class="actions" #filterGroup="matButtonToggleGroup" [value]="selectedFilter">
          <mat-button-toggle [value]="chartFilters.ClusterId" matTooltip="Color by cluster" (change)="onFilterChange($event, filterGroup)">
            <mat-icon svgIcon="tcd-by-cluster"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="chartFilters.Confirmed" matTooltip="Color by confirmed" (change)="onFilterChange($event, filterGroup)">
            <mat-icon svgIcon="tcd-by-confirmed"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-stroked-button class="custom-button" matTooltip="Reset"
          (click)="onActionChange({source: null, value : chartActions.Reset})">
          <mat-icon svgIcon="tcd-reset"></mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <textarea  matInput placeholder="Search Strings" fxFlex="85%"
        [(ngModel)]="text" class="search-input"></textarea>
        <button mat-stroked-button class="search-button" [matTooltip]="text? 'Zoom to selection': 'Enter text in search box'"
          (click)="sendTestData()"
          [disabled]="!text"
          [ngClass]="text?.length ? 'button-enabled':'button-disabled'"
          >
          <mat-icon svgIcon="tcd-search"></mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
