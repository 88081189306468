import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TagService } from 'src/app/core/services/tag.service';
import { TextSampleService } from 'src/app/core/services/text-sample.service';
import { SelectedItem } from 'src/app/paragraph-review/store/reducer';
import { AppState } from 'src/app/root-store/state';
import { checkForEmptyStrings } from '../../FormValidators/validators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-create-text-sample-dialog',
  templateUrl: './create-text-sample-dialog.component.html',
  styleUrls: ['./create-text-sample-dialog.component.scss'],
})
export class CreateTextSampleDialogComponent implements OnInit, OnDestroy {
  public bulkUpload: UntypedFormGroup;
  public simpleUpload: UntypedFormGroup;
  public isBulkUpload = false;
  public tags: string[];
  public selectedTags: SelectedItem[] = [];
  public selectedOntology: string;
  public validSources$: Observable<string[]>;
  public readonly nonLLMGenerated =  'Non-LLM Generated'
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  private readonly ALLOWED_FILE_TYPES = ['xlsx', 'xls', 'csv'];
  public fileName: string;
  public file: File;
  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private tagService: TagService,
    private textSampleService: TextSampleService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    setTimeout(() => {
      this.tagService.getAllWithInfo();
    }, 100);
    this.bulkUpload = this.fb.group({
      requiredFile: [this.file, [Validators.required]],
    });

    this.store
      .pipe(
        select(s => s.rootState.ontology),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(ontology => {
        this.selectedOntology = ontology;
      });

    this.simpleUpload = this.fb.group({
      text: this.fb.control('', [Validators.required, checkForEmptyStrings]),
      linkUrl: this.fb.control(''),
      description: this.fb.control(''),
      source: this.fb.control(this.nonLLMGenerated),
    });

    this.store
      .select(s => s.rootState.tags)
      .subscribe(tags => {
        this.tags = tags;
      });
    this.validSources$ = this.textSampleService.getValidSources();
  }

  upload() {
    if (this.isBulkUpload) {
      this.textSampleService.bulkCreateSample(this.file).subscribe(() => {
        this.notificationService.showSuccess('Samples have been uploaded and queued for classification');
        this.dialogRef.close();
      });
    } else {
      const control = this.simpleUpload.controls;
      const obj = {
        description: control.description.value,
        linkUrl: control.linkUrl.value,
        tags: this.selectedTags.map(t => t.name),
        text: control.text.value,
        ontology: this.selectedOntology,
        source: control.source.value === this.nonLLMGenerated ? null : control.source.value
      };
      this.textSampleService.createTextSample(obj).subscribe(() => {
        this.notificationService.showSuccess('Text sample created successfully!');
        this.dialogRef.close();
      });
    }
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  tagsUpdated(event) {
    this.selectedTags = event.selectedItems;
  }

  onFileSelected($event) {

    const file: File = $event.target.files[0];
    this.fileName = file?.name;

    if (file) {
      const extension = this.fileName.split('.').pop();
      if (!this.ALLOWED_FILE_TYPES.includes(extension)) {
        this.bulkUpload.controls['requiredFile'].setErrors({'incorrectFile': true});
      } else {
        this.file = file;
        this.bulkUpload.controls['requiredFile'].setErrors(null);
      }
    }
  }
}
