import { Pipe, PipeTransform } from '@angular/core';
import { LabelAnnotations } from 'src/app/core/models/Annotations';

@Pipe({
  name: 'machineLabel',
  pure: false,
})
export class MachineLabelPipe implements PipeTransform {
  transform(val: LabelAnnotations[]) {
    if (!val || val.length < 1) {
      return [];
    }
    return val.filter(s => s.isSuggestion);
  }
}

@Pipe({
  name: 'userLabel',
})
export class UserLabelPipe implements PipeTransform {
  transform(val: LabelAnnotations[]) {
    if (!val || val.length < 1) {
      return [];
    }
    return val.filter(s => !s.isSuggestion);
  }
}

@Pipe({
  name: 'filterLabel',
})
export class FilterLabelPipe implements PipeTransform {
  transform(val: any[], args: string) {
    if (!val || val.length < 1) {
      return [];
    }
    return val.filter(item => item.name.toLowerCase().indexOf(args.toLowerCase()) !== -1);
  }
}

@Pipe({
  name: 'labelValue',
})
export class LabelValuePipe implements PipeTransform {
  transform(label: LabelAnnotations) {
    if (!label) {
      return 'N/A';
    }

    if (!label.confidence || label.createdById > 1) {
      return label.name;
    }

    const confidence = label.confidence.toFixed(2);

    return `${label.name} - ${confidence}`;
  }
}

@Pipe({
  name: 'labelConfidence',
})
export class LabelConfidencePipe implements PipeTransform {
  transform(annotations: LabelAnnotations[]) {
    if (!annotations) {
      return 'N/A';
    }

    const minimumConfidenceAnnotation = annotations.reduce((prev, current) => {
      return prev.confidence < current.confidence ? prev : current;
    });

    return `${minimumConfidenceAnnotation.confidence.toFixed(2)}`;
  }
}
