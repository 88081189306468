import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { authConfig } from '../authentication/auth-config';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { IdentityUser } from '../models/IdentityUser';
import { User } from '../models/User';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUser: User;
  constructor(
    public http: HttpClient,
    private authService: AuthenticationService
  ) { }

  getCurrentUserFromIdentityServer() {
    const claims: any = this.authService.identityClaims;
    const resource = `${authConfig.issuer}/api/users/${claims.sub}`
    return this.currentUser
      ? of(this.currentUser)
      : this.http.get<any>(resource).pipe(
        tap(data => {
          this.currentUser = data;
        }),
      );
  }

  inviteUsers(users: IdentityUser[]): Observable<any> {
    const resource = `${environment.identityDevelopmentUrl}/api/users/`;
    return this.http.post(resource, users, { observe: 'response' });
  }

  getCurrentUserFromLIServer() {
    return this.http.get<any>('api/user/current');
  }

  getAllUserInfoSimplified() {
    return this.http.get<User[]>('api/user/allSimplified');
  }

  isLoggedInUser(email: string): boolean {
    return this.currentUser ? (this.currentUser.email.toLowerCase() === email.toLowerCase() ? true : false) : false;
  }

  getAllIdentityUsers() {
    const resource = `${environment.identityDevelopmentUrl}/api/users/`;
    return this.http.get<IdentityUser[]>(resource);
  }

  patchIdentityUser(id: string, identityUserPatches: any) {
    const resource = `${environment.identityDevelopmentUrl}/api/users/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.patch<any>(resource, identityUserPatches, { headers, observe: 'response' });
  }

  getIdentityUserById(id: string): Observable<IdentityUser> {
    const resource = `${environment.identityDevelopmentUrl}/api/users/${id}`;
    return this.http.get<IdentityUser>(resource);
  }
}
