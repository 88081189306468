export class TopicDiscoveryData {
  provisionId: number;
  clusterId: number;
  text?: string;
  keywords: string[];
  confirmed?: boolean;
}


export enum ChartSize {
  Small,
  Medium
}
