import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Label, LabelDefinitionAndSamples } from 'src/app/core/models/Label';
import { LabelService } from 'src/app/core/services/label.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Entity, SortByLabel, SortByLabels } from 'src/app/core/models/models';
import { AppState } from 'src/app/root-store/state';
import { SetSelectedEntity } from '../store/actions';
import { getParagraphReviewState } from '../store/selectors';
import { ReviewView } from '../../core/models/ReviewView';
import { EntityService } from '../../core/services/entity.service';
import { selectRootStateEntities, selectRootStateOntology } from '../../root-store/selectors';
import { SetEntities } from '../../root-store/actions';
import { SortByLabelEnum } from '../store/reducer';


@Component({
  selector: 'ali-select-label',
  templateUrl: './select-label.component.html',
  styleUrls: ['./select-label.component.scss'],
})
export class SelectLabelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataSource: Label[];
  @Output() selectLabel = new EventEmitter<Label>();
  @Output() showDefinitionAndExamples = new EventEmitter<LabelDefinitionAndSamples>();

  selectedEntity: Entity = null;
  labels: Label[] = [];
  isSwitchView = false;
  filterText = '';
  ontology = '';
  entities: Entity[];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  selectedLabel: Label = null;
  sortByLabels: SortByLabel[] = SortByLabels;
  selectedLabelSort = SortByLabelEnum.Name;
  labelSampleCount: Map<string, number>;
  showSamplesCount = false;

  constructor(
    private store: Store<AppState>,
    private labelService: LabelService,
    private notificationService: NotificationService,
    private entityService: EntityService
  ) { }

  ngOnInit() {

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.selectedEntity),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(entity => {
        if (entity?.fields) {
          const { fields } = entity;
          const provisionField = fields.find(s => s.name === 'Provision');
          const filteredSortedFields = fields
            .filter(s => s.name !== 'Provision')
            .sort((a, b) => (a.name < b.name ? -1 : 1));
          const provisionFirstFields = provisionField
            ? [provisionField, ...filteredSortedFields]
            : filteredSortedFields;
          this.selectedEntity = {...entity, fields: provisionFirstFields};
        } else {
          this.selectedEntity = null;
        }
      });

    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectRootStateOntology))
      .subscribe(ontology => {
        this.ontology = ontology
        this.labelService.getSamplesCount(this.ontology).subscribe(samplesCount => {
          this.labelSampleCount = samplesCount;
          this.sortLabels();
        });
      });

    this.store.pipe(takeUntil(this.ngUnsubscribe), select(selectRootStateEntities))
      .subscribe(entities => this.entities = entities);
  }

  ngOnChanges(changes): void {
    if (changes.dataSource) {
      this.dataSource = [...changes.dataSource.currentValue];
      this.sortLabels();
    }
  }

  getExample(label) {
    return `Name: ${label.name}  \n\n Definition: ${label.definition}`;
  }

  labelSelected(label) {
    this.selectedLabel = label;
    this.selectLabel.emit(label);
  }

  addToFavorites(label) {
    this.labelService.addFavourite(this.ontology, label.id).subscribe(() => {
      this.notificationService.showSuccess('Label added to favourite: ' + label.displayName);
    })
  }

  removeFromFavorites(label) {
    this.labelService.deleteFavourite(label.favouriteId).subscribe(() => {
      this.notificationService.showSuccess('Label removed from favourite: ' + label.displayName);
    })
  }

  entitySelected(entity: Entity) {
    if (this.selectedEntity?.id !== entity?.id){
      this.store.dispatch(new SetSelectedEntity(entity));
    }
  }

  getLabelExample(label: Label) {
    this.store.select(state => state.rootState.labels).subscribe(labels => {
      const currentLabel = labels.find(l => l.id === label.id)
      this.labelService.getLabelExemplar([label.id]).subscribe(labelExamples => {

        const labelDefinitionAndSamples: LabelDefinitionAndSamples = {
          definition: currentLabel.definition,
          samples: labelExamples || [],
          labelText: currentLabel.name,
          previousView: ReviewView.ParagraphSearch,
        }
        this.showDefinitionAndExamples.emit(labelDefinitionAndSamples);
      })
    }).unsubscribe()

  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getEntities() {
    this.store.select(selectRootStateEntities).pipe(
      take(1),
      mergeMap(entities => {
        if (entities.length > 0) {
          return EMPTY;
        } else {
          return this.entityService.getEntities(this.ontology).pipe(
            tap(fetchedEntities => this.store.dispatch(new SetEntities(fetchedEntities)))
          );
        }
      }),
      takeUntil(this.ngUnsubscribe),
    ).subscribe();
  }

  sortLabels() {
    switch (this.selectedLabelSort) {
      case SortByLabelEnum.Name:
        this.labels = this.dataSource.sort((a, b) => +b.isPinned - +a.isPinned || a.type - b.type || a.name.localeCompare(b.name));
        break;
      case SortByLabelEnum.ConfirmedSamplesCount:
        this.labels = this.dataSource.sort((a, b) => +b.isPinned - +a.isPinned || a.type - b.type || (this.labelSampleCount[b.id] || 0) - (this.labelSampleCount[a.id] || 0));
        break;
    }
  }

  sortChanged(sortBy: SortByLabelEnum) {
    this.selectedLabelSort = sortBy;
    // Used to force the ngOnChanges to be called when the sort order is changed
    this.ngOnChanges({ dataSource: { currentValue: this.dataSource, previousValue: this.dataSource } });
  }

  toggleChanged() {
    this.showSamplesCount = !this.showSamplesCount;
    if (!this.showSamplesCount) {
      this.sortChanged(SortByLabelEnum.Name);
    }
  }
}
