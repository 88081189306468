import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentUploadState, UploadQueueStatus, UploadState, initialUploadQueueState } from '../models/models';

export const documentUploadState = createFeatureSelector<DocumentUploadState>('documentUploadState');

export const queue = (ontology: string) => createSelector(
  documentUploadState,
  state => {
    if (state[ontology]) {
      return state[ontology];
    } else {
      return {
        ...initialUploadQueueState,
        ontology
      };
    }
  }
);

export const uploads = (ontology: string) => createSelector(
  queue(ontology),
  queue => queue.uploads
);

export const uploadsInProgress = (ontology: string) => createSelector(
  uploads(ontology),
  uploads => uploads.filter(u => u.state === UploadState.InProgress)
);

export const uploadsQueued = (ontology: string) => createSelector(
  uploads(ontology),
  uploads => uploads.filter(u => u.state === UploadState.Queued)
);

export const uploadIsInProgress = (ontology: string) => createSelector(
  uploadsInProgress(ontology),
  inProgress => inProgress.length > 0
);

export const queueStatus = (ontology: string) => createSelector(
  queue(ontology),
  uploadsQueued(ontology),
  uploadsInProgress(ontology),
  (queue, uploadsQueued, uploadsInProgress): UploadQueueStatus => ({
    ontology: queue.ontology,
    uploadIsInProgress: uploadsInProgress.length > 0,
    uploads: {
      queued: uploadsQueued
    },
    tags: queue.tags,
    queueProcessingState: queue.queueProcessingState
  }));
