import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HubConnectionProviderService {
  private logsSource = new Subject<{ logLevel: LogLevel; message: string }>();
  public logs$ = this.logsSource.asObservable();
  public hubConnection: HubConnection;

  constructor(private oauthService: OAuthService) {}

  public initialize(url: string): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => this.oauthService.getAccessToken(),
      })
      .configureLogging({
        log: (logLevel, message) => this.logsSource.next({ logLevel, message }),
      })
      .withAutomaticReconnect()
      .build();
  }
}
