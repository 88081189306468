import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Label } from 'src/app/core/models/Label';
import { ActiveLearningStrategyType, StrategyFields } from 'src/app/core/models/models';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProvisionService } from 'src/app/core/services/provision.service';
import { AppState } from 'src/app/root-store/state';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

const LabelFieldValidator: ValidatorFn = (fg: UntypedFormGroup) => {
  const strategy = fg.get('strategy').value;
  const selectedLabel = fg.get('label').value;
  if (strategy === ActiveLearningStrategyType.Confused && selectedLabel === '') {
    return { emptyLabel: true };
  } else {
    return null;
  }
};

@Component({
  selector: 'app-active-learning-dialog',
  templateUrl: './active-learning-dialog.component.html',
  styleUrls: ['./active-learning-dialog.component.scss'],
})
export class ActiveLearningDialogComponent implements OnInit, OnDestroy {
  public activeLearning: UntypedFormGroup;
  public selectedOntology: string;
  public labels: Label[];
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();
  public strategyFields = StrategyFields;
  public activeLearningStrategyType = ActiveLearningStrategyType;
  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private provisionService: ProvisionService,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.store
      .pipe(
        select(s => s.rootState.ontology),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(ontology => {
        this.selectedOntology = ontology;
      });

    this.store
      .select(s => s.rootState.labels)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(labels => {
        this.labels = labels;
      });

    this.activeLearning = this.formBuilder.group(
      {
        tenant: this.formBuilder.control(''),
        strategy: this.formBuilder.control('', [Validators.required]),
        count: this.formBuilder.control('', [Validators.pattern(/^0*(?:[1-9][0-9]?|100)$/), Validators.required]),
        label: this.formBuilder.control(''),
      },
      { validators: LabelFieldValidator },
    );
  }

  getSamples() {
    const control = this.activeLearning.controls;
    const obj = {
      strategy: control.strategy.value,
      count: control.count.value,
      tenant: control.tenant.value,
      ontology: this.selectedOntology,
      label: control.label.value,
    };

    this.provisionService.getActiveLearningSamples(obj).subscribe(() => {
      this.notificationService.showSuccess('New Samples will be available soon!');
      this.dialogRef.close();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
