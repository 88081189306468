import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import { LabelRequirementSuggestionDialogComponent } from './label-requirement-suggestion-dialog/label-requirement-suggestion-dialog.component';
import { routes } from './paragraph-review-routing.module';
import { ParagraphSearchComponent } from './paragraph-search/paragraph-search.component';
import { ParagraphViewComponent } from './paragraph-view/paragraph-view.component';
import { ReviewComponent } from './review/review.component';
import { SelectLabelComponent } from './select-label/select-label.component';
import { ProvisionSearchEffects } from './store/effects';
import { SelectModelDialogComponent } from './select-model-dialog/select-model-dialog.component';
import { LabelDefinitionComponent } from './label-definition/label-definition.component';
import {
  ConflictReviewSelectedSampleComponent
} from './conflict-review-selected-sample/conflict-review-selected-sample.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TopicDiscoveryModule } from '../topic-coverage-discovery/topic-discovery.module';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { ClusterViewComponent } from './cluster-view/cluster-view.component';
import { DocumentUploadModule } from '../document-upload/document-upload.module';
import { DatasetConflictReviewSelectedSampleComponent } from './dataset-conflict-review-selected-sample/dataset-conflict-review-selected-sample.component';


@NgModule({
  declarations: [
    ReviewComponent,
    SelectLabelComponent,
    LabelRequirementSuggestionDialogComponent,
    ParagraphViewComponent,
    ParagraphSearchComponent,
    SelectModelDialogComponent,
    LabelDefinitionComponent,
    ConflictReviewSelectedSampleComponent,
    ActionBarComponent,
    ClusterViewComponent,
    DatasetConflictReviewSelectedSampleComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    EffectsModule.forFeature([ProvisionSearchEffects]),
    CommonModule,
    SharedModule,
    MatExpansionModule,
    TopicDiscoveryModule,
    DocumentUploadModule
  ]
})
export class ParagraphReviewModule { }
