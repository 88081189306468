import { Injectable, EventEmitter } from '@angular/core';
import {
  IInlineEditStartEvent,
  IInlineEditCancelEvent,
  IInlineEditErrorEvent,
  IInlineEditSuccessEvent,
} from './inline-edit.events';

@Injectable()
export class InlineEditService {
  /**
   * A channel for events indicating the edit mode has started.
   */
  public inlineEditStart: EventEmitter<IInlineEditStartEvent> = new EventEmitter<IInlineEditStartEvent>();

  /**
   * A channel for events indicating the cancel action has taken place.
   */
  public inlineEditCancel: EventEmitter<IInlineEditCancelEvent> = new EventEmitter<IInlineEditCancelEvent>();

  /**
   * A channel for events indicating the save action has completed successfully.
   */
  public inlineEditSuccess: EventEmitter<IInlineEditSuccessEvent> = new EventEmitter<IInlineEditSuccessEvent>();

  /**
   * A channel for events indicating the save action has ended in error.
   */
  public inlineEditError: EventEmitter<IInlineEditErrorEvent> = new EventEmitter<IInlineEditErrorEvent>();
}
