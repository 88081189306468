import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class LoginGuard  {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      switchMap(_ => this.authService.isAuthenticated$),
      tap(isAuthenticated => {
        if (isAuthenticated) {
            this.router.navigate(['/home'], { queryParamsHandling: 'preserve' });
            return true;
          }
          else {
          this.authService.login(state.url);
        }
        return false;
      }),
    );
  }
}
