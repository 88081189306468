import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from 'src/app/root-store/state';
import { ParagraphReviewState } from './reducer';

export const paragraphSearchRootState = (appState: AppState) => appState.paragraphReviewState;

export const filterParagraphIds = createSelector(
  paragraphSearchRootState,
  state => state.search.paragraphIds,
);

export const isDashboardFilter = createSelector(
  paragraphSearchRootState,
  state => state.search.dashboardFilter,
);

export const getClusterState = createSelector(
  paragraphSearchRootState,
  state => state.clusterState,
)

export const getClusterSelection = createSelector(
  getClusterState,
  state => state.clusterSelection
)

export const getParagraphReviewState = createFeatureSelector<ParagraphReviewState>('paragraphReviewState');

export const paragraphs = createSelector(getParagraphReviewState, state => {
  if (state && state.result && state.result.paragraphs) {
    return Object.values(state.result.paragraphs);
  } else {
    return undefined;
  }
});

export const resultParagraphs = createSelector(getParagraphReviewState, state => state.result.paragraphs);
export const resultParagraphIds = createSelector(getParagraphReviewState, state => state.result.paragraphIds);

export const getParagraphSearchQuery = createSelector(getParagraphReviewState, state => state.search);
export const getReviewView = createSelector(getParagraphReviewState, state => state.reviewView);

export const getSearchText = createSelector(getParagraphReviewState, state => state.search.contains);

export const getSearchType = createSelector(getParagraphReviewState, state => state.search.searchType);
export const getSearchTags = createSelector(getParagraphReviewState, state => state.search.tags);
export const getSearchUserLabels = createSelector(getParagraphReviewState, state => state.search.userLabels);
export const getSearchMachineLabels = createSelector(getParagraphReviewState, state => state.search.machineLabels);
export const getSearchCreatedOn = createSelector(getParagraphReviewState, state => state.search.createdOn);
export const getSearchConfirmedOn = createSelector(getParagraphReviewState, state => state.search.confirmedOn);
export const getSearchSortOrder = createSelector(getParagraphReviewState, state => state.search.sortOrder);
export const getSearchSortByField = createSelector(getParagraphReviewState, state => state.search.sortByField);
export const getSearchSortValue = createSelector(getParagraphReviewState, state => state.search.sortValue);
export const getSearchConfirmedType = createSelector(getParagraphReviewState, state => state.search.confirmedType);
export const getSearchIncludeHiddenParagraphs = createSelector(getParagraphReviewState, state => state.search.includeHiddenParagraphs);
export const getSearchParagraphId = createSelector(getParagraphReviewState, state => state.search.paragraphId);
export const getSearchDocumentId = createSelector(getParagraphReviewState, state => state.search.documentId);
export const getSearchContains = createSelector(getParagraphReviewState, state => state.search.contains);
export const getZoomToParagraphIds = createSelector(getParagraphReviewState, state => state.zoomToParagraphIds);
export const getScatterPlotAction = createSelector(getParagraphReviewState, state => state.scatterPlotAction);
export const getZoomToClusterIds = createSelector(getParagraphReviewState, state => state.zoomToClusterIds);
export const getParagraphById = (paragraphId: number) =>
  createSelector(resultParagraphs, paragraphs => {
    if (paragraphs && paragraphs[paragraphId]) {
      return paragraphs[paragraphId];
    } else {
      return undefined;
    }
  });
