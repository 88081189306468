<div>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-divider></mat-divider>
  <mat-dialog-content fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="7px" style="height: 100px;">
    <p fxFlex="15"><mat-icon class="icon-display">{{data.icon}}</mat-icon></p>
    <p fxFlex="85">{{data.message}}</p>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" cdkFocusInitial (click)="onCloseClick()">Close</button>
  </mat-dialog-actions>
</div>
