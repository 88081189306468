import { DocumentUploadActionType, DocumentUploadActions } from './actions';
import {
  DocumentUploadQueue,
  DocumentUploadState,
  QueueProcessingState,
  Upload,
  UploadState,
  initialDocumentUploadState,
  initialUploadQueueState
} from '../models/models';

const docUploadReducer = (
  state = initialDocumentUploadState,
  action: DocumentUploadActions
): DocumentUploadState => {
  switch (action.type) {
    case DocumentUploadActionType.UploadAppendedToQueue: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          uploads: ontologyState.uploads.concat(action.upload)
        }
      };
    }

    case DocumentUploadActionType.FileUploadStarted: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          uploads: ontologyState.uploads.map(u => (u.file === action.upload.file ? new Upload(u.file, UploadState.InProgress) : u))
        }
      };
    }

    case DocumentUploadActionType.FileUploadSucceeded: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          uploads: ontologyState.uploads.map(u => (u.file === action.upload.file ? new Upload(u.file, UploadState.Completed) : u))
        }
      };
    }

    case DocumentUploadActionType.FileUploadFailed: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          uploads: ontologyState.uploads.map(u => (u.file === action.upload.file ? new Upload(action.upload.file, UploadState.Error, action.error) : u))
        }
      };
    }

    case DocumentUploadActionType.UploadRemoved: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          uploads: ontologyState.uploads.filter(u => u !== action.upload)
        }
      };
    }

    case DocumentUploadActionType.FullQueueStarted: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          queueProcessingState: QueueProcessingState.Full
        }
      };
    }

    case DocumentUploadActionType.FullQueueCompleted: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          queueProcessingState: QueueProcessingState.Idle
        }
      };
    }

    case DocumentUploadActionType.TagsUpdated: {
      const ontologyState = getState(action.ontology, state);
      return {
        ...state,
        [action.ontology]: {
          ...ontologyState,
          tags: action.tags
        }
      };
    }

    default:
      return state;
  }
}

function getState(ontology: string, state: Record<string, DocumentUploadQueue>): DocumentUploadQueue {
  if (state[ontology]) {
    return state[ontology];
  } else {
    return {
      ...initialUploadQueueState,
      ontology
    };
  }
}

export function documentUploadReducer(state: DocumentUploadState | undefined, action: DocumentUploadActions) {
  return docUploadReducer(state, action);
}
