import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LabelInsightsUserType } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class LiAccessService {
  userTypeClaim = 'https://claims.thoughttrace.com/labelInsightsUserType';

  constructor() {}

  isThoughttraceUser() {
    const token = sessionStorage.getItem('id_token');
    const helper = new JwtHelperService();
    if (token) {
      const userType = helper.decodeToken(token)[this.userTypeClaim];
      return userType === LabelInsightsUserType.Internal || userType === LabelInsightsUserType.TR_Internal;
    } else {
      return false;
    }
  }
}
