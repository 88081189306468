import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Entity, Field } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  constructor(private http: HttpClient) { }

  getEntities(ontology: string) {
    return this.http.get<Entity[]>(`api/entity/${ontology}`);
  }

  createEntity(dto) {
    return this.http.post<any>('api/entity', dto);
  }

  createField(dto) {
    return this.http.post<any>('api/entity/create/field', dto);
  }

  getFields(entityId) {
    return this.http.get<Field[]>(`api/entity/${entityId}/fields`);
  }

  updateEntity(entityId, dto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.patch<any>(`api/entity/${entityId}`, dto, { headers });
  }

  updateField(dto) {
    return this.http.post<any>('api/entity/update/field', dto);
  }

  removeEntity(Id) {
    return this.http.delete<any>(`api/entity/remove/${Id}`);
  }

  removeField(Id) {
    return this.http.delete<any>(`api/entity/field/remove/${Id}`);
  }
}
