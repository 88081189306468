import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ScatterPlotComponent } from './charts/scatter-plot/scatter-plot.component';
import { ChartToolbarComponent } from './chart-toolbar/chart-toolbar.component';

@NgModule({
  declarations: [ScatterPlotComponent, ChartToolbarComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [ChartToolbarComponent, ScatterPlotComponent]
})
export class TopicDiscoveryModule { }
