import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SecurityLabelAuthorization } from '../models/security-label-authorization';


@Injectable({ providedIn: 'root' })
export class SecurityLabelAuthorizationsService {
  private readonly resource: string = `${environment.identityDevelopmentUrl}/api/authorization`;

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<SecurityLabelAuthorization[]>(this.resource);
  }

  get(subjectId: string): Observable<any> {

    return this.http.get<SecurityLabelAuthorization[]>(`${this.resource}/${subjectId}`, { observe: 'response' });
  }

}
