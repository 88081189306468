import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { EndpointBuilderService } from '../services/endpoint-builder.service';
import { LoaderService } from '../services/loader.service';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private shouldIncludeToken: string[];

  constructor(
    public auth: AuthenticationService,
    public endpointBuilder: EndpointBuilderService,
    public loaderService: LoaderService,
    public globalErrorHandler: ErrorHandler,
  ) {
    this.shouldIncludeToken = [environment.developmentUrl, environment.identityDevelopmentUrl, 'api'];
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.isLoading.next(true);

    return from(this.auth.accessToken()).pipe(
      switchMap(token => {
        const authReq = this.addAuthenticationToken(request, token);
        return next.handle(authReq);
      }),
      tap(event => {
        if (event instanceof HttpResponse) {
          this.loaderService.isLoading.next(false);
        }
      }),
      catchError(error => {
        this.globalErrorHandler.handleError(error);
        this.loaderService.isLoading.next(false);
        return throwError(error);
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const url = this.endpointBuilder.buildUrl(request.url as string);
    if (!this.shouldIncludeToken.some(base => url.startsWith(base))) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
      url,
    });
  }
}
