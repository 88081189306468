import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tag } from 'src/app/core/models/Tag';
import { SelectedItem } from 'src/app/paragraph-review/store/reducer';

@Component({
  selector: 'app-update-tags-dialog',
  templateUrl: './update-tags-dialog.component.html',
  styleUrls: ['./update-tags-dialog.component.scss'],
})
export class UpdateTagsDialogComponent implements OnInit {
  public tags: Tag[] = this.data.tags;
  public selectedTags: SelectedItem[] = this.data.selectedTags ? this.data.selectedTags : [];
  public allowEmpty = this.data.allowEmpty ? this.data.allowEmpty : false;
  public message: string = this.data.message;
  constructor(public dialogRef: MatDialogRef<UpdateTagsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  tagsUpdated(event) {
    this.selectedTags = event.selectedItems;
  }

  onSubmit() {
    this.dialogRef.close({ tags: this.selectedTags.map(s => s.id), selectedItem: this.selectedTags });
  }
}
