import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { PendoService } from './core/services/pendo.service';
import { MaterialModule } from './material/material.module';
import { AccessTokenComponent } from './pages/access-token/access-token.component';
import { LoginComponent } from './pages/login/login.component';
import { RootEffects } from './root-store/effects';
import { reducers } from './root-store/state';
import { TrainingModule } from './training/training.module';
import { LayoutModule } from '@angular/cdk/layout';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 700,
  hideDelay: 700,
  touchendHideDelay: 700,
};

@NgModule({
  declarations: [AppComponent, LoginComponent, AccessTokenComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      name: 'Label Insights',
      maxAge: 25,
    }),
    TrainingModule,
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
        _forceAnimations: true,
      },
    },
    { provide: APP_BASE_HREF,
      useValue: environment.production && (environment.v2Hosting !== 'true') ? '/li_base_href/' : '/'
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    // @ts-ignore
    private pendoService: PendoService,
  ) { }
}
