import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TrackByFunction,
} from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Label, LabelDefinitionAndSamples } from 'src/app/core/models/Label';
import { TrainingType } from 'src/app/core/models/models';
import { ProvisionService } from 'src/app/core/services/provision.service';
import { AppState } from 'src/app/root-store/state';
import { PageChanged, ProvisionSearchActionType, RefreshResults, SetReviewView } from '../store/actions';
import { ParagraphSearchQuery } from '../store/reducer';
import { LoadDirectionEnum } from 'src/app/root-store/reducer';
import { selectRootStateOntology } from '../../root-store/selectors';
import { ModelDeployment } from 'src/app/core/models/ModelDeployment';
import { LabelService } from 'src/app/core/services/label.service';
import { ReviewView } from '../../core/models/ReviewView';
import { ParagraphsPageSize } from '../../core/constants/li-constants';
import { getParagraphReviewState, getParagraphSearchQuery, getReviewView } from '../store/selectors';

@Component({
  selector: 'app-paragraph-search',
  templateUrl: './paragraph-search.component.html',
  styleUrls: ['./paragraph-search.component.scss'],
})
export class ParagraphSearchComponent implements OnInit, AfterViewChecked, OnChanges {
  @Input() paragraphIds: number[] = [];
  @Input() tags: string[];
  @Input() ontology: string;
  @Input() activeLabel: Label;
  @Input() conflictingScore: number;
  @Input() isParent = true;
  @Input() modelDeployments: ModelDeployment[] = [];
  @Output() showDefinitionAndExamplesEvent = new EventEmitter<LabelDefinitionAndSamples>();
  @Output() backFn = new EventEmitter();

  public checkAllState = false;
  public checkPageItemsState = false;
  public loadDirectionEnum = LoadDirectionEnum;
  public showMetaData = true;
  public totalPages: number;
  public total: number;
  public active: any;
  public checkedParagraphIds: number[] = [];
  public unCheckedParagraphIds: number[] = [];
  public page: number;
  public pageChanged: EventEmitter<number> = new EventEmitter<number>();
  public checkedParagraphCount: number;
  public searchPredicate: ParagraphSearchQuery;
  public TrainingType = TrainingType;
  public reviewView: ReviewView;
  public ReviewViewEnum = ReviewView;
  public clusterId: string;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  trackByFn: TrackByFunction<number> = (_, item) => item;

  constructor(
    private provisionService: ProvisionService,
    private store: Store<AppState>,
    private updates$: Actions,
    public router: Router,
    private labelService: LabelService,
  ) {}

  ngOnInit() {

    this.updates$.subscribe((action: Action) => {
      if (action.type === ProvisionSearchActionType.PageChanged) {
        this.scrollToTop();
      }
    });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.activeLabel),
      )
      .subscribe(activeLabel => {
        if (activeLabel == null) {
          this.active = null;
        } else {
          this.active = Object.assign({}, activeLabel);
        }
      });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.isCheckAllChecked),
      )
      .subscribe(state => {
        this.checkAllState = state;
        this.updateCheckedCount();
      });

    this.store.pipe(select(getParagraphReviewState)).subscribe(state => {
      this.showMetaData = state.showMetaData;
    });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.checkedParagraphIds),
      )
      .subscribe(ids => {
        this.checkedParagraphIds = ids;
        this.updateCheckedCount();
      });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.unCheckedParagraphIds),
      )
      .subscribe(ids => {
        this.unCheckedParagraphIds = ids;
        this.updateCheckedCount();
      });

    this.store.pipe(select(getParagraphSearchQuery)).subscribe(search => {
      this.searchPredicate = search;
    });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.search.page),
      )
      .subscribe(p => {
        this.page = p;
      });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.result.total),
      )
      .subscribe(total => {
        this.total = total;
        this.totalPages = Math.ceil(total / ParagraphsPageSize);
      });

    this.pageChanged.pipe(debounceTime(600)).subscribe(page => {
      this.store.dispatch(new PageChanged(page));
    });

    this.store
      .pipe(
        select(getParagraphReviewState),
        select(s => s.isCheckAllChecked),
      )
      .subscribe(isCheck => {
        this.checkAllState = isCheck;
      });

    this.store.select(selectRootStateOntology).subscribe(ontology => {
      this.ontology = ontology;
    });

    this.store
      .select(getReviewView)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(reviewView => {
        this.reviewView = reviewView;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modelDeployments) {
      this.modelDeployments = changes.modelDeployments.currentValue;
    }
  }
  ngAfterViewChecked() {
    this.checkPageItemsState = this.getCheckPageItemsState();
  }

  classifyParagraph([id, modelDeploymentId]: [number, number]) {
    this.provisionService.classifyProvisionWithModel(id, modelDeploymentId).subscribe(() => {});
  }

  updateCheckedCount() {
    this.checkedParagraphCount = this.checkAllState
      ? this.total - this.unCheckedParagraphIds.length
      : this.checkedParagraphIds.length;
    this.checkPageItemsState = this.getCheckPageItemsState();
  }

  loadMore(action: LoadDirectionEnum) {
    if (action === this.loadDirectionEnum.next) {
      this.page++;
      this.store.dispatch(new PageChanged(this.page));
    } else {
      this.page--;
      this.store.dispatch(new PageChanged(this.page));
    }
    this.scrollToTop();
  }

  goToPage() {
    if (this.total < ParagraphsPageSize || !(this.page > 0)) {
      this.pageChanged.emit(1);
    }
    if (this.page >= this.totalPages) {
      this.page = this.totalPages;
      this.pageChanged.emit(this.totalPages);
    }
    if (this.page < this.totalPages) {
      this.pageChanged.emit(this.page);
    }
    this.scrollToTop();
  }

  lastPage() {
    this.pageChanged.emit(this.totalPages);
  }

  firstPage() {
    this.pageChanged.emit(1);
  }

  refreshResults() {
    this.store.dispatch(new RefreshResults());
    this.scrollToTop();
  }

  scrollToTop() {
    const myDiv = document.getElementById('scroll-search');
    myDiv.scrollTop = 0;
  }

  isPageNumberInputDisabled() {
    return this.total < ParagraphsPageSize;
  }

  calculateNumberOfPages() {
    return this.total / ParagraphsPageSize > 1 ? this.totalPages : 1;
  }

  getCheckPageItemsState(): boolean {
    return (
      (this.paragraphIds.filter(p => this.checkedParagraphIds.includes(p)).length === this.paragraphIds.length &&
        this.paragraphIds.length > 0) ||
      (this.checkAllState &&
        (this.unCheckedParagraphIds.length === 0 ||
          (this.unCheckedParagraphIds.length > 0 &&
            this.paragraphIds.filter(p => this.unCheckedParagraphIds.includes(p)).length === 0)))
    );
  }

  showDefinitionAndExamples(active: any) {
    this.labelService.getLabelExemplar([active.id]).subscribe(labelExamples => {
      const labelDefinitionAndSamples: LabelDefinitionAndSamples = {
        definition: active.definition,
        samples: labelExamples || [],
        labelText: active.name,
        previousView: ReviewView.ParagraphSearch,
      };
      this.showDefinitionAndExamplesEvent.emit(labelDefinitionAndSamples);
    });
  }

  navigateToClusterView() {
    this.store.dispatch(new SetReviewView(ReviewView.ClusterView));
  }
}
