<mat-form-field>
  <mat-select #selectElem [disabled]="disabled" [(ngModel)]="selectedValue"
    (selectionChange)="onSelectionChange($event)" placeholder="{{placeholder}}">
    <div class="box-search">
      <input #searchInput type="text" [ngClass]="'pl-1'" (input)="filterItem(searchInput.value)"
        placeholder="Search...">
      <div matTooltip="Clear search" class="box-search-icon" (click)="filterItem(''); searchInput.value = ''">
        <button mat-icon-button class="search-button">
          <mat-icon class="mat-24" aria-label="Search icon">clear</mat-icon>
        </button>
      </div>
    </div>
    <mat-select-trigger>
      {{onDisplayString()}}
    </mat-select-trigger>
    <mat-option [value]="0" disabled hidden>Select</mat-option>
    <mat-option *ngFor="let option of options" [disabled]="option.disabled" [value]="option.id"
      [style.display]="hideOption(option) ? 'none': 'flex'">{{option.name}}
    </mat-option>
  </mat-select>
  <mat-hint style="color:red" *ngIf="showErrorMsg">{{errorMsg}}</mat-hint>
</mat-form-field>
