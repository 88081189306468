import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelDeployment } from '../models/ModelDeployment';
import { DeploymentDto } from '../models/DeploymentDto';

@Injectable({ providedIn: 'root' })
export class ModelDeploymentService {
  constructor(private http: HttpClient) { }

  getAllDeployments(): Observable<ModelDeployment[]> {
    return this.http.get<ModelDeployment[]>(`api/deployments`);
  }

  getDeploymentsByOntology(ontologyId: string): Observable<ModelDeployment[]> {
    return this.http.get<ModelDeployment[]>(`api/deployments/${ontologyId}`);
  }

  createDeployment(requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(`api/deployments`, requestBody, { headers, observe: 'response' });
  }

  retryDeployment(deploymentId: Number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(`api/deployments/${deploymentId}/retry`, {}, { headers, observe: 'response' });
  }

  updateDeployment(deploymentId: Number, requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/merge-patch+json',
    });
    return this.http.patch(`api/deployments/${deploymentId}`, requestBody, { headers, observe: 'response' });
  }

  cancelDeployment(deploymentId: Number): Observable<any> {
    return this.http.delete(`api/deployments/${deploymentId}`);
  }

  getCurrentLiveModel(ontologyId: string): Observable<DeploymentDto> {
    return this.http.get<DeploymentDto>(`api/deployments/current/${ontologyId}`);
  }

  bulkDecommission(requestBody: any) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
    });
    return this.http.post(`api/deployments/bulk/decommission`, requestBody, { headers, observe: 'response' });
  }
}
