import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EndpointBuilderService {
  constructor() {}

  public buildUrl(uri?: string) {
    if (environment.production && (environment.v2Hosting !== 'true')) {
      return uri ? uri : '';
    }

    if (uri && uri.startsWith('http')) {
      return uri;
    }

    return environment.developmentUrl + (uri ? (uri[0] === '/' ? uri.substr(1) : uri) : '');
  }
}
