import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlags } from '../models/feature-flags';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  public featureFlags$: Observable<FeatureFlags> = this.fetch().pipe(shareReplay(1));

  constructor(
    private httpClient: HttpClient,
  ) {}

  private fetch(): Observable<FeatureFlags> {
    return this.httpClient.get<FeatureFlags>(`api/featureflags`);
  }
}

