import { Action } from '@ngrx/store';
import { SecurityGroup } from 'src/app/core/models/SecurityGroup';
import { IdentityUser } from 'src/app/core/models/IdentityUser';
import { SecurityLabel } from 'src/app/core/models/SecurityLabel';

export enum AdminStateActionType {
  Init = '[Admin State] Init',
  SetIdentityUsers = '[Admin State] Set Identity Users',
  SetSecurityGroups = '[Admin State] Set Security Groups',
  SetSecurityLabels = '[Admin State] Set Security Labels',
  AddSecurityGroup = '[Admin State] Add Security Group',
  UpdateSecurityGroup = '[Admin State] Modify Security Group',
  DeleteSecurityGroup = '[Admin State] Delete Security Group',
}

export class Init implements Action {
  public readonly type = AdminStateActionType.Init;
}

export class SetSecurityGroups implements Action {
  public readonly type = AdminStateActionType.SetSecurityGroups;
  constructor(public payload: SecurityGroup[]) { }
}

export class SetIdentityUsers implements Action {
  public readonly type = AdminStateActionType.SetIdentityUsers;
  constructor(public payload: IdentityUser[]) { }
}

export class SetSecurityLabels implements Action {
  public readonly type = AdminStateActionType.SetSecurityLabels;
  constructor(public payload: SecurityLabel[]) { }
}

export class AddSecurityGroup implements Action {
  public readonly type = AdminStateActionType.AddSecurityGroup;
  constructor(public payload: SecurityGroup) { }
}

export class UpdateSecurityGroup implements Action {
  public readonly type = AdminStateActionType.UpdateSecurityGroup;
  constructor(public payload: SecurityGroup) { }
}

export class DeleteSecurityGroup implements Action {
  public readonly type = AdminStateActionType.DeleteSecurityGroup;
  constructor(public payload: SecurityGroup) { }
}

export type AdminStateActions =
  | Init
  | SetSecurityGroups
  | SetIdentityUsers
  | SetSecurityLabels
  | AddSecurityGroup
  | UpdateSecurityGroup
  | DeleteSecurityGroup;
