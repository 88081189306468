<mat-form-field style="width:100%;">
  <input matInput class="form-control" (input)="filterChanged($event.target.value)" placeholder="Search">
</mat-form-field>
<mat-tree class="fix" [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="cursor: pointer;">
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    <div (click)="nodeChanged(node)" [innerHtml]="getName(node.name)"></div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
    style="cursor: pointer;">
    <button mat-icon-button>
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <div [innerHtml]="getName(node.name)"></div>
  </mat-tree-node>
</mat-tree>
