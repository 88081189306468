import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SignalRService } from '../core/events/hub/signalR-service';
import { Event, NewNotificationCreatedEventV1 } from '../core/events/models';
import { Notification } from '../core/models/models';
import { EntityService } from '../core/services/entity.service';
import { LabelService } from '../core/services/label.service';
import { SessionStorageService } from '../core/services/session-storage.service';
import {
  ClearSearchByClusterIds,
  LoadProvision,
  RemoveUserLabelFromParagraphs,
  SearchOntologyChanged,
  SetSelectedEntity,
} from '../paragraph-review/store/actions';
import {
  AddFavouriteLabels,
  AddLabel,
  AddOntology,
  AddTags,
  DeleteTag,
  GetLabels,
  RemoveFavouriteLabel,
  RemoveLabel,
  RemoveSharedLabels,
  RootStateActionType,
  SetEntities,
  SetLabels,
  SetNotificationCount,
  SetNotifications,
  SetSignalRInit,
  SetTags,
  SharedLabelsUpdated,
  UpdateLabel,
  UpdateOntology,
} from './actions';
import { AppState } from './state';
import { ChangeDatasetSelection, GetConflictingReportResults } from '../dataset/store/actions';

@Injectable()
export class RootEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private labelService: LabelService,
    public signalRService: SignalRService,
    public entityService: EntityService,
    public sessionStorageService: SessionStorageService,
  ) { }

  ontologyChanged$ = createEffect(() => this.actions$.pipe(
    ofType(RootStateActionType.OntologyChanged),
    withLatestFrom(this.store$),
    filter(([_, appState]) => appState.rootState.ontology !== ''),
    switchMap(([_, appState]) => [
      new GetLabels(),
      new SetSelectedEntity(null),
      new ChangeDatasetSelection(null),
      new GetConflictingReportResults(null),
      new ClearSearchByClusterIds(),
      new SearchOntologyChanged(appState.rootState.ontology),
      new LoadProvision(),
      new SetEntities([]),
    ]),
  ));

  updateLocalStorageOntology = createEffect(() =>this.actions$.pipe(
    ofType(RootStateActionType.OntologyChanged),
    withLatestFrom(this.store$),
    map(res => res[1]),
    tap(appState => this.sessionStorageService.setItem('ontology', appState.rootState.ontology)),
  ), { dispatch: false });


  getLabels$ = createEffect(() => this.actions$.pipe(
    ofType<Action>(RootStateActionType.GetLabels),
    withLatestFrom(this.store$),
    filter(([_, appState]) => appState.rootState.ontology !== ''),
    switchMap(([_, appState]) =>
      combineLatest([
        this.labelService.getLabels(appState.rootState.ontology),
        this.labelService.getFavourite(appState.rootState.ontology),
      ]).pipe(
        map(([labels, favourites]) => {
          labels = labels.map(l => {
            const fav = favourites.find(x => x.labelId === l.id);
            if (fav) {
              l.isPinned = true;
              l.favouriteId = fav.id;
            } else {
              l.isPinned = false;
            }
            return l;
          });
          return new SetLabels(labels);
        }),
      ),
    ),
  ));

  getEntities$ = createEffect(() => this.actions$.pipe(
    ofType(RootStateActionType.GetEntities),
    withLatestFrom(this.store$),
    map(([_, appState]) => appState),
    filter(appState => appState.rootState.ontology !== ''),
    switchMap(appState =>
      this.entityService.getEntities(appState.rootState.ontology).pipe(map(entities => new SetEntities(entities))),
    ),
  ));

  setSignalRInit$ = createEffect(() => this.actions$.pipe(
    ofType(RootStateActionType.SignalRInitStart),
    withLatestFrom(this.store$),
    map(appstate => appstate[1]),
    filter(appState => !appState.rootState.signalRInitialized),
    map(_ => {
      this.signalRService.initialize(`${environment.production && (environment.v2Hosting !== 'true') ? '/li_base_href/notification' : environment.developmentUrl + 'notification'}`)
      return new SetSignalRInit(true);
    }),
  ));

  labelUpdated = createEffect(() => this.signalRService.labelUpdated$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    filter(data => data[0].data.updatedLabel.ontology === data[1].ontology),
    map(data => {
      return new UpdateLabel(data[0].data.updatedLabel);
    }),
  ));

  labelNameUpdated = createEffect(() => this.signalRService.labelNameUpdated$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    filter(data => data[0].data.updatedLabel.ontology === data[1].ontology),
    map(data => {
      return new UpdateLabel(data[0].data.updatedLabel);
    }),
  ));

  public labelCreated = createEffect(() => this.signalRService.labelCreated$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    filter(data => data[0].data.createdLabel.ontology === data[1].ontology),
    map(data => {
      return new AddLabel(data[0].data.createdLabel);
    }),
  ));

  labelDeleted = createEffect(() => this.signalRService.labelDeleted$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    switchMap(([event, state]) => {
      const actions: Action[] = [
        new RemoveLabel(event.data.deletedLabelId),
        new RemoveSharedLabels([event.data.deletedLabelId])
      ];
      if (state.labels.find(label => event.data.deletedLabelId === label.id)) {
        actions.push(new RemoveUserLabelFromParagraphs(event.data.deletedLabelId));
      }
      return actions;
    }),
  ));

  labelsMerged = createEffect(() => this.signalRService.labelsMerged$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    switchMap(([event, state]) => [
      new SetLabels([...state.labels.filter(s => !event.data.mergedLabelIds.includes(s.id))]),
      new AddLabel(event.data.newLabel),
    ]),
  ));

  labelsSaved = createEffect(() => this.signalRService.labelsSaved$.pipe(
    map(event => {
      return new AddLabel(event.data.newLabel);
    }),
  ));

  ontologyCreated = createEffect(() => this.signalRService.ontologyCreated$.pipe(
    map(event => {
      return new AddOntology(event.data.createdOntology);
    }),
  ));

  ontologyUpdated = createEffect(() => this.signalRService.ontologyUpdated$.pipe(
    map(event => {
      return new UpdateOntology(event.data.updatedOntology);
    }),
  ));

  sharedLabelsUpdated = createEffect(() => this.signalRService.sharedLabelsUpdated$.pipe(
    map(event => {
      return new SharedLabelsUpdated(event.data.destinationLabelId, event.data.sharedLabels);
    }),
  ));

  labelsCopied = createEffect(() => this.signalRService.labelsCopied$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    switchMap(([event, state]) => [
      new SetLabels([...state.labels.concat(event.data.newLabels)]),
      new SetTags([...state.tags.concat(event.data.newTags)]),
    ]),
  ));

  tagsCreated = createEffect(() => this.signalRService.tagsCreated$.pipe(
    map(event => {
      return new AddTags(event.data.newTags)
    })
  ));

  tagRemoved = createEffect(() => this.signalRService.deletedTagID$.pipe(
    map(event => {
      return new DeleteTag(event.data.deletedTagId[0]);
    }),
  ));

  favouriteLabelsAdded = createEffect(() => this.signalRService.favouriteLabelCreated$.pipe(
    map(event => {
      return new AddFavouriteLabels(event.data);
    })
  ));

  favouriteLabelsDeleted = createEffect(() => this.signalRService.favouriteLabelRemoved$.pipe(
    map(event => {
      return new RemoveFavouriteLabel(event.data);
    })
  ));

  notificationReceived$ = createEffect(() => this.signalRService.newNotificationCreated$.pipe(
    withLatestFrom(this.store$.select(s => s.rootState)),
    switchMap(([event, state]) => {
      const newNotification = this.mapToNotification(event);
      const updatedNotifications = this.addOrUpdateField(newNotification, state.notifications);
      const existingNotification = state.notifications.map(s => s.notificationId).includes(newNotification.notificationId);

      const actions = []
      actions.push(new SetNotifications(updatedNotifications))
      if (!existingNotification) {
        actions.push(new SetNotificationCount(state.notificationCount !== null ? state.notificationCount + 1 : 1))
      }
      return actions;
    })
  ));

  mapToNotification(event: Event<NewNotificationCreatedEventV1>) {
    const obj: Notification = {
      actionType: event.data.actionType,
      createdOn: event.eventTime,
      description: event.data.description,
      userId: event.data.userId,
      notificationType: event.data.notificationType,
      notificationId: event.data.notificationId,
      progress: event.data.progress,
    };
    return obj;
  }

  addOrUpdateField = (
    field: Notification,
    fields: Array<Notification>
  ): Array<Notification> => {
    const index = fields.findIndex((f: Notification) => field.notificationId === f.notificationId);
    // Not found, add on end.
    if (-1 === index) {
      return [...fields, field];
    }
    // found, so return:
    // Clone of items before item being updated.
    // updated item
    // Clone of items after item being updated.
    return [...fields.slice(0, index), field, ...fields.slice(index + 1)];
  }
}
