<p class="mat-h2" (contextmenu)="onContextMenu($event)" id="paragraph-{{uniqueId}}" class="provision-text"
    (mouseup)="textHighlighted()">
  {{ provisionText }}
</p>

<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="label">
        <button mat-menu-item (click)="deleteLabel()">
            <mat-icon>delete_outline</mat-icon>
            <span>Delete Highlight</span>
        </button>
    </ng-template>
</mat-menu>
