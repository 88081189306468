import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { AuthCallbackGuard } from './core/guards/callback.guard';
import { LoginGuard } from './core/guards/login.guard';
import { OntologyGuard } from './core/guards/ontology.guard';
import { AccessTokenComponent } from './pages/access-token/access-token.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginComponent,
  },
  {
    path: 'main',
    loadChildren: () => import('../app/navbar/navbar.module').then(mod => mod.NavbarModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'home',
    data: { title: 'Home' },
    loadChildren: () => import('../app/home/home.module').then(mod => mod.HomeModule),
    canActivate: [OntologyGuard],
  },
  {
    path: 'callback',
    component: AccessTokenComponent,
    canActivate: [AuthCallbackGuard],
  },
  { path: '**', canActivate: [LoginGuard], redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
