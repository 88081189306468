<div fxLayout="column"  fxLayoutGap="2em" class="label-definition-container">
  <div class="label-definition-padding">
    <button mat-button (click)="return()" class="no-padding" color="primary">
      <mat-icon>arrow_back</mat-icon>
      <span *ngIf='labelExampleInfo.previousView === ReviewView.ParagraphSearch'>Back to Label Samples</span>
      <span *ngIf='labelExampleInfo.previousView === ReviewView.SideBySideConflict'>Back to Side By Side</span>
      <span *ngIf='labelExampleInfo.previousView === ReviewView.DatasetSideBySideConflict'>Back to Side By Side</span>
    </button>
    <div class="label-title" fxLayout="row">
      <span class="label-title-text">{{labelTitleText}}</span>
      <span class="label-small-title">Label</span>
    </div>
    <div class="label-definition" fxLayout="column" fxLayoutAlign="start">
      <span class="definition-text">Definition</span>
      <p> {{labelExampleInfo.definition}}</p>
      <app-example-main [samples]="labelExampleInfo.samples"></app-example-main>
    </div>
  </div>
</div>
