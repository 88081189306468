import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModelReviewState } from './reducer';

export const getModelReviewState = createFeatureSelector<ModelReviewState>('modelReviewState');
export const getSelectedConflictGroup = createSelector(getModelReviewState, state => state.selectedConflictGroup);
export const getSelectedConflictFilters = createSelector(getModelReviewState, state => state.selectedConflictFilters);
export const getSortedParentIds = createSelector(getModelReviewState, state => state.sortedParentIds);
export const getConflictReport = createSelector(getModelReviewState, state => state.conflictReport);

export const areAllConflictGroupsSelected = createSelector(getModelReviewState, state => state.includeAllSearchResults);

export const getConflictIds = createSelector(getModelReviewState, state => {
  if (state.conflictReport && state.conflictReport.conflictGroups && state.conflictReport.conflictGroups.length > 0) {
    const conflictIds = [];
    state.conflictReport.conflictGroups.forEach(conflict => {
      conflictIds.push(`${conflict.parent.paragraphId}-${conflict.parent.paragraphId}`);
      conflict.childConflicts.forEach(child => {
        conflictIds.push(`${conflict.parent.paragraphId}-${child.paragraphId}`);
      });
    });
    return conflictIds;
  }
  return [];
});

export const bulkSelectedConflictCount = createSelector(getModelReviewState, state =>
  state.includeAllSearchResults
    ? state.conflictReport.filteredTotalConflicts - state.includeAllSearchResultsExcludedParagraphIds.length
    : state.explicitlySelectedParagraphIds.length,
);

export const implicitlySelectedCurrentPageDocumentIds = createSelector(
  getModelReviewState,
  getConflictIds,
  (state, conflictIds) => {
    return (
      conflictIds &&
      conflictIds.filter(
        conflictId =>
          (state.includeAllSearchResults && !state.includeAllSearchResultsExcludedParagraphIds.includes(conflictId)) ||
          state.explicitlySelectedParagraphIds.includes(conflictId),
      )
    );
  },
);

export const areAllCurrentPageDocumentsSelected = createSelector(
  getModelReviewState,
  getConflictIds,
  implicitlySelectedCurrentPageDocumentIds,
  (state, conflictIds, implicitlySelected) => {
    return state.conflictReport.conflictGroups
      ? state.conflictReport.conflictGroups.length > 0 && conflictIds.every(c => implicitlySelected.includes(c))
      : null;
  },
);
