import { NgModule, ErrorHandler, APP_INITIALIZER, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './services/user.service';
import { AuthService } from './authentication/auth.service';
import { AuthenticationGuard } from './guards/authentication.guard';
import { AuthCallbackGuard } from './guards/callback.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { EndpointBuilderService } from './services/endpoint-builder.service';
import { ProvisionService } from './services/provision.service';
import { OntologyService } from './services/ontology.service';
import { TagService } from './services/tag.service';
import { LoaderService } from './services/loader.service';
import { NotificationService } from './services/notification.service';
import { GlobalErrorHandler } from './error-handling/global-error-handler';
import { LiAccessGuard } from './guards/li-access.guard';
import { LiAccessService } from './services/li-access.service';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from './authentication/auth-config';
import { authAppInitializerFactory } from './authentication/auth-app-initializer.factory';
import { AuthenticationService } from './authentication/authentication.service';
import { OntologyGuard } from './guards/ontology.guard';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LayoutModule } from '@angular/cdk/layout';
import { LoginGuard } from './guards/login.guard';
import { LoggingService } from './services/logging.service';
import { DocumentService } from './services/document.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return sessionStorage;
}


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
  ],
  providers: [
    AuthenticationService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    UserService,
    AuthService,
    AuthCallbackGuard,
    LoginGuard,
    AuthenticationGuard,
    LiAccessGuard,
    OntologyGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    EndpointBuilderService,
    ProvisionService,
    OntologyService,
    TagService,
    LoaderService,
    LoggingService,
    NotificationService,
    LiAccessService,
    DocumentService,
  ],
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthenticationService], multi: true },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
