<div fxLayout="column" fxLayoutGap="15px" class="dialog-margin">
  <div>
    <h2 mat-dialog-title>{{ message }}</h2>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-evenly strech" fxLayoutGap="7px">
    <ali-tag-input
      [enforceSelection]="false"
      [selectedItems]="selectedTags"
      placeholder="Add New..."
      [dataSource]="tags"
      (itemAdded)="tagsUpdated($event)"
      (itemRemoved)="tagsUpdated($event)"
    >
    </ali-tag-input>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button
      mat-raised-button
      color="primary"
      [disabled]="selectedTags && selectedTags.length == 0 && !allowEmpty"
      (click)="onSubmit()"
    >
      Submit
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
  </div>
</div>
