import { Injectable, NgZone } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import {
  EntityCreatedEventV1,
  EntityRemovedEventV1,
  EntityUpdatedEventV1,
  Event,
  FieldCreatedEventV1,
  FieldRemovedEventV1,
  FieldUpdatedEventV1,
  LabelCreatedEventV1,
  LabelDeletedEventV1,
  LabelNameUpdatedEventV1,
  LabelProcedureIntializedEventV1,
  LabelsCopiedToNewOntologyEventV1,
  LabelsCopiedWithinOntologyEventV1,
  LabelsMergedEventV1,
  LabelUpdatedEventV1,
  NewNotificationCreatedEventV1,
  OntologyCreatedEventV1,
  ProvisionAnnotationsUpdatedEventV1,
  ProvisionConfirmedEventV1,
  ProvisionLabelSuggestionEventV1,
  ProvisionTagAddedEventV1,
  ProvisionTagRemovedEventV1,
  ProvisionUnconfirmedEventV1,
  ProvisionUpdateConfoundingScoreEventV1,
  SharedLabelsUpdatedEventV1,
  TagsCreatedEventV1,
  TextAnalyzedEventV1,
  TagDeletedEventV1,
  OntologyUpdatedEventV1,
  FavouriteLabelCreatedEventV1,
  FavouriteLabelRemovedEventV1,
  ModelConflictingReportGeneratedV1,
  ProvisionHiddenUpdatedEventV1,
  ExtractorCreatedEventV1,
  ExtractorDeletedEventV1,
  ExtractorUpdatedEventV1
} from '../models';
import { HubConnectionProviderService } from './hub-connection-provider.service';
@Injectable({
  providedIn: 'root',
})
export class SignalRService {

  annotationsChanged$ = new Subject<Event<ProvisionAnnotationsUpdatedEventV1>>();
  newNotificationCreated$ = new Subject<Event<NewNotificationCreatedEventV1>>();
  labelsMerged$ = new Subject<Event<LabelsMergedEventV1>>();
  labelsSaved$ = new Subject<Event<LabelsCopiedWithinOntologyEventV1>>();
  labelsCopied$ = new Subject<Event<LabelsCopiedToNewOntologyEventV1>>();
  labelProcedureIntialized$ = new Subject<Event<LabelProcedureIntializedEventV1>>();
  labelCreated$ = new Subject<Event<LabelCreatedEventV1>>();
  labelUpdated$ = new Subject<Event<LabelUpdatedEventV1>>();
  labelDeleted$ = new Subject<Event<LabelDeletedEventV1>>();
  entityUpdated$ = new Subject<Event<EntityUpdatedEventV1>>();
  fieldCreated$ = new Subject<Event<FieldCreatedEventV1>>();
  entityCreated$ = new Subject<Event<EntityCreatedEventV1>>();
  fieldUpdated$ = new Subject<Event<FieldUpdatedEventV1>>();
  entityRemoved$ = new Subject<Event<EntityRemovedEventV1>>();
  fieldRemoved$ = new Subject<Event<FieldRemovedEventV1>>();
  tagsCreated$ = new Subject<Event<TagsCreatedEventV1>>();
  textAnalyzed$ = new Subject<Event<TextAnalyzedEventV1>>();
  paragraphConfirmed$ = new Subject<Event<ProvisionConfirmedEventV1>>();
  paragraphUnconfirmed$ = new Subject<Event<ProvisionUnconfirmedEventV1>>();
  paragraphHiddenUpdated$ = new Subject<Event<ProvisionHiddenUpdatedEventV1>>();
  paragraphTagAdded$ = new Subject<Event<ProvisionTagAddedEventV1>>();
  paragraphTagRemoved$ = new Subject<Event<ProvisionTagRemovedEventV1>>();
  ontologyCreated$ = new Subject<Event<OntologyCreatedEventV1>>();
  ontologyUpdated$ = new Subject<Event<OntologyUpdatedEventV1>>();
  labelNameUpdated$ = new Subject<Event<LabelNameUpdatedEventV1>>();
  sharedLabelsUpdated$ = new Subject<Event<SharedLabelsUpdatedEventV1>>();
  confoundingScoreChanged$ = new Subject<Event<ProvisionUpdateConfoundingScoreEventV1>>();
  paragraphLabelSuggestionsChanged$ = new Subject<Event<ProvisionLabelSuggestionEventV1>>();
  deletedTagID$ = new Subject<Event<TagDeletedEventV1>>();
  favouriteLabelCreated$ = new Subject<Event<FavouriteLabelCreatedEventV1>>();
  favouriteLabelRemoved$ = new Subject<Event<FavouriteLabelRemovedEventV1>>();
  modelConflictingReportGenerated$ = new Subject<Event<ModelConflictingReportGeneratedV1>>();
  extractorCreated$ = new Subject<Event<ExtractorCreatedEventV1>>();
  extractorDeleted$ = new Subject<Event<ExtractorDeletedEventV1>>();
  extractorUpdated$ = new Subject<Event<ExtractorUpdatedEventV1>>();
  constructor(
    private hubConnectionProvider: HubConnectionProviderService,
    private zone: NgZone,
  ) { }

  private get connection(): HubConnection {
    return this.hubConnectionProvider.hubConnection;
  }

  public initialize(url: string): void {
    /* Running connection outside of NgZone so change detection cycles don't
    occur each time the client receives a message from the server. */
    this.zone.runOutsideAngular(() => {
      this.hubConnectionProvider.initialize(url);
      this.registerOnServerEvents();
      this.connection.start();
      console.log('SignalR Init Success');
    });
  }

  public stop(): void {
    if (this.connection)
      this.connection.stop();
  }

  private registerOnServerEvents(): void {
    this.connection.on('BatchEvent', events => {
      this.handleBatchEvent(events);
    });

    this.connection.on('LabelsMergedEventV1', (data: Event<LabelsMergedEventV1>) => {
      this.labelsMerged$.next(data);
    });

    this.connection.on('ProvisionAnnotationsUpdatedEventV1', (data: Event<ProvisionAnnotationsUpdatedEventV1>) => {
      this.annotationsChanged$.next(data);
    });

    this.connection.on('LabelsCopiedWithinOntologyEventV1', (data: Event<LabelsCopiedWithinOntologyEventV1>) => {
      this.labelsSaved$.next(data);
    });

    this.connection.on('LabelsCopiedToNewOntologyEventV1', (data: Event<LabelsCopiedToNewOntologyEventV1>) => {
      this.labelsCopied$.next(data);
    });

    this.connection.on('NewNotificationCreatedEventV1', (data: Event<NewNotificationCreatedEventV1>) => {
      this.newNotificationCreated$.next(data);
    });

    this.connection.on('LabelProcedureIntializedEventV1', (data: Event<LabelProcedureIntializedEventV1>) => {
      this.labelProcedureIntialized$.next(data);
    });

    this.connection.on('LabelUpdatedEventV1', (data: Event<LabelUpdatedEventV1>) => {
      this.labelUpdated$.next(data);
    });

    this.connection.on('LabelNameUpdatedEventV1', (data: Event<LabelNameUpdatedEventV1>) => {
      this.labelNameUpdated$.next(data);
    });

    this.connection.on('LabelCreatedEventV1', (data: Event<LabelCreatedEventV1>) => {
      this.labelCreated$.next(data);
    });

    this.connection.on('LabelDeletedEventV1', (data: Event<LabelDeletedEventV1>) => {
      this.labelDeleted$.next(data);
    });

    this.connection.on('EntityUpdatedEventV1', (data: Event<EntityUpdatedEventV1>) => {
      this.entityUpdated$.next(data);
    });

    this.connection.on('FieldCreatedEventV1', (data: Event<FieldCreatedEventV1>) => {
      this.fieldCreated$.next(data);
    });

    this.connection.on('EntityCreatedEventV1', (data: Event<EntityCreatedEventV1>) => {
      this.entityCreated$.next(data);
    });

    this.connection.on('FieldUpdatedEventV1', (data: Event<FieldUpdatedEventV1>) => {
      this.fieldUpdated$.next(data);
    });

    this.connection.on('EntityRemovedEventV1', (data: Event<EntityRemovedEventV1>) => {
      this.entityRemoved$.next(data);
    });

    this.connection.on('FieldRemovedEventV1', (data: Event<FieldRemovedEventV1>) => {
      this.fieldRemoved$.next(data);
    });

    this.connection.on('TagsCreatedEventV1', (data: Event<TagsCreatedEventV1>) => {
      this.tagsCreated$.next(data);
    });

    this.connection.on('TextAnalyzedEventV1', (data: Event<TextAnalyzedEventV1>) => {
      this.textAnalyzed$.next(data);
    });

    this.connection.on('ProvisionConfirmedEventV1', (data: Event<ProvisionConfirmedEventV1>) => {
      this.paragraphConfirmed$.next(data);
    });

    this.connection.on('ProvisionUnconfirmedEventV1', (data: Event<ProvisionUnconfirmedEventV1>) => {
      this.paragraphUnconfirmed$.next(data);
    });

    this.connection.on('ProvisionTagAddedEventV1', (data: Event<ProvisionTagAddedEventV1>) => {
      this.paragraphTagAdded$.next(data);
    });

    this.connection.on('ProvisionTagRemovedEventV1', (data: Event<ProvisionTagRemovedEventV1>) => {
      this.paragraphTagRemoved$.next(data);
    });

    this.connection.on('ProvisionHiddenUpdatedEventV1', (data: Event<ProvisionHiddenUpdatedEventV1>) => {
      this.paragraphHiddenUpdated$.next(data);
    });

    this.connection.on('OntologyCreatedEventV1', (data: Event<OntologyCreatedEventV1>) => {
      this.ontologyCreated$.next(data);
    });

    this.connection.on('OntologyUpdatedEventV1', (data: Event<OntologyUpdatedEventV1>) => {
      this.ontologyUpdated$.next(data);
    });

    this.connection.on('SharedLabelsUpdatedEventV1', (data: Event<SharedLabelsUpdatedEventV1>) => {
      this.sharedLabelsUpdated$.next(data);
    });

    this.connection.on(
      'ProvisionUpdateConfoundingScoreEventV1',
      (data: Event<ProvisionUpdateConfoundingScoreEventV1>) => {
        this.confoundingScoreChanged$.next(data);
      },
    );

    this.connection.on('ProvisionLabelSuggestionEventV1', (data: Event<ProvisionLabelSuggestionEventV1>) => {
      this.paragraphLabelSuggestionsChanged$.next(data);
    });

    this.connection.on('TagDeletedEventV1', (data: Event<TagDeletedEventV1>) => {
      this.deletedTagID$.next(data);
    })

    this.connection.on('FavouriteLabelCreatedEventV1', (data: Event<FavouriteLabelCreatedEventV1>) => {
      this.favouriteLabelCreated$.next(data);
    })

    this.connection.on('FavouriteLabelDeletedEventV1', (data: Event<FavouriteLabelRemovedEventV1>) => {
      this.favouriteLabelRemoved$.next(data);
    })

    this.connection.on('ModelConflictingReportGeneratedV1', (data: Event<ModelConflictingReportGeneratedV1>) => {
      this.modelConflictingReportGenerated$.next(data);
    })

    this.connection.on('ExtractorCreatedEventV1', (data: Event<ExtractorCreatedEventV1>) => {
      this.extractorCreated$.next(data);
    })

    this.connection.on('ExtractorDeletedEventV1', (data: Event<ExtractorDeletedEventV1>) => {
      this.extractorDeleted$.next(data);
    })

    this.connection.on('ExtractorUpdatedEventV1', (data: Event<ExtractorUpdatedEventV1>) => {
      this.extractorUpdated$.next(data);
    })
  }

  public handleBatchEvent(events: any[]) {
    events.forEach(event => {
      switch (event.eventType) {
        case 'LabelUpdatedEventV1':
          this.labelUpdated$.next(event);
          break;
        case 'NewNotificationCreatedEventV1':
          this.newNotificationCreated$.next(event);
          break;
        case 'ProvisionAnnotationsUpdatedEventV1':
          this.annotationsChanged$.next(event);
          break;
        case 'LabelCreatedEventV1':
          this.labelCreated$.next(event);
          break;
        case 'LabelDeletedEventV1':
          this.labelDeleted$.next(event);
          break;
        case 'ProvisionHiddenUpdatedEventV1':
          this.paragraphHiddenUpdated$.next(event);
          break;
        case 'ProvisionTagAddedEventV1':
          this.paragraphTagAdded$.next(event);
          break;
        case 'ProvisionTagRemovedEventV1':
          this.paragraphTagRemoved$.next(event);
          break;
        case 'LabelNameUpdatedEventV1':
          this.labelNameUpdated$.next(event);
          break;
        case 'TagDeletedEventV1':
          this.deletedTagID$.next(event);
          break;
        case 'ProvisionConfirmedEventV1':
          this.paragraphConfirmed$.next(event);
          break;
        case 'ProvisionUnconfirmedEventV1':
          this.paragraphUnconfirmed$.next(event);
          break;
        case 'ProvisionUpdateConfoundingScoreEventV1':
          this.confoundingScoreChanged$.next(event);
          break;
        default:
          break;
      }
    });
  }
}
